import { Box, Grid, IconButton, Paper, Slide, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface ImageChooserEvaluationProps {
	show: boolean,
	onClickSuccess(): void,
	onClickFail(): void,
}

function ImageChooserEvaluation(props: ImageChooserEvaluationProps) {
	const theme = useTheme();


	return <>
		<Slide direction="down" in={props.show}>
			<Box bottom={theme.spacing(4)} position="absolute" width="100%" zIndex={999}>
					<Stack direction="row" width={200} marginLeft="auto" marginRight="auto" justifyContent="space-between" >
						<IconButton color="success" sx={{backgroundColor: theme.palette.background.paper}} onClick={props.onClickSuccess}><SentimentVerySatisfiedIcon sx={{fontSize: "3.75rem"}} /></IconButton>
						<IconButton color="info" sx={{backgroundColor: theme.palette.background.paper}} onClick={props.onClickFail}><RestartAltIcon sx={{fontSize: "3.75rem"}} /></IconButton>
					</Stack>
			</Box>
		</Slide>
	</>;
}

export default ImageChooserEvaluation;
