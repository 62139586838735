import React, { useRef } from 'react';
import RecorderCard from '../components/RecorderCard';
import logger from '../services/logger';


interface RecorderCardControllerProps {
	variant: "question" | "rightAnswer" | "wrongAnswer",
	transcript?: string,
	deleteable?: boolean,
	onNewRecording(data: Blob): void,
	recordingUrl: string | null,
	moreMenu?: [string, () => void][],
}

export default function RecorderCardController(props: RecorderCardControllerProps) {
	const mediaRecorder = useRef<MediaRecorder | null>(null);
	const mediaStream = useRef<MediaStream|null>(null);
	const mediaChunks = useRef<Blob[]>([]);

	const stopStreamTracks = (stream: MediaStream) => {
		logger.debug("Stopping streams");
		const tracks = stream.getTracks();
		tracks.forEach((track, idx) => {
			logger.debug("Stopping track "+idx);
			track.stop();
		});
	}

	const handleRecordStart = () => {
		logger.debug("Record start");
		if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
			mediaRecorder.current.stop();
			logger.error("Recording was already in progress.", mediaRecorder.current.state);
		}
		if (mediaStream.current) {
			stopStreamTracks(mediaStream.current);
		}
		// let mediaRecorder: MediaRecorder | null;
		// let chunks: Blob[] = [];
		mediaChunks.current = [];
		navigator.mediaDevices.getUserMedia({ audio: true })
			.then(stream => {
				mediaStream.current = stream;
				mediaRecorder.current = new MediaRecorder(stream, {
					mimeType: "audio/webm",
				});

				// Datenchunks während der Aufnahme speichern
				mediaRecorder.current.ondataavailable = event => {
					if (event.data.size > 0) {
						mediaChunks.current.push(event.data);
					}
				};

				// Aufnahme beenden und Audio-Datei erstellen, wenn der Nutzer aufhört zu sprechen
				mediaRecorder.current.onstop = () => {
					const blob = new Blob(mediaChunks.current, { type: 'audio/webm' });
					mediaChunks.current = [];
					props.onNewRecording(blob);
				};

				mediaRecorder.current.start();
			})
			.catch(error => {
				logger.error('Error accessing microphone', error);
			});
	}
	const handleRecordFinish = () => {
		logger.debug("Record finish");
		if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
			mediaRecorder.current.stop();
		}
		if (mediaStream.current) {
			stopStreamTracks(mediaStream.current);
		}
	}
	const handleRecordCancel = () => {
		logger.debug("Record cancel");
		if (mediaRecorder.current) {
			mediaRecorder.current.onstop = () => {
				mediaChunks.current = [];
			}
			mediaRecorder.current.stop();
		}
		if (mediaStream.current) {
			stopStreamTracks(mediaStream.current);
		}
	}

	const handlePlay = () => {
		if (props.recordingUrl) {
			logger.debug("Playing "+props.recordingUrl);
			const audio = new Audio(props.recordingUrl);
			audio.play();
			return;
		}
	}

	return <>
		{/* <a href={props.recordingUrl || undefined} download="test.webm">Download</a> */}
		<RecorderCard {...props} onRecordStart={handleRecordStart} onRecordCancel={handleRecordCancel} onRecordFinish={handleRecordFinish} onPlay={handlePlay} playEnabled={props.recordingUrl !== null}/>
	</>;
}