import React, { useState, useContext, useEffect } from 'react';
import FlashCardView from '../components/FlashCardView';
import { Kitem, KitemType } from '../contexts/LearningContext';
import { SMCKitem } from './SMCController';
import { AudioUrl, SpeechSynth, playSound } from '../services/sound-player';
import { DisplayImage, DisplayText } from '../App';
import WrittenAnswerView from '../components/WrittenAnswerView';
import logger from '../services/logger';
import assert from 'assert';
const soundJa = require('../assets/jaaa.m4a');

export type FlashCardState = "question" | "right" | "wrong";

export type WrittenAnswerKitem = Kitem & {
	type: KitemType.WrittenAnswer,
	question: SpeechSynth | AudioUrl | DisplayText | DisplayImage,
	inputType: WrittenAnswerInputType,
	answers: string[],
};

export function isWrittenAnswerKitem(item: Kitem): item is WrittenAnswerKitem {
	return (
		(item as WrittenAnswerKitem).type !== undefined
		&& (item as WrittenAnswerKitem).type === KitemType.WrittenAnswer
		&& (item as WrittenAnswerKitem).question !== undefined
		&& (item as WrittenAnswerKitem).answers !== undefined
	);
}

export enum WrittenAnswerInputType {
	number,
}

interface WrittenAnswerControllerProps {
	kitem: WrittenAnswerKitem,
	onSuccess(): void,
	onFail(): void,
	onNext(): void,
}

export default function WrittenAnswerController(props: WrittenAnswerControllerProps) {
	const [cardState, setCardState] = useState<FlashCardState>("question");
	const [enterable, setEnterable] = useState(false);
	const [editable, setEditable] = useState(true);
	const [showWrong, setShowWrong] = useState(false);
	const [showRight, setShowRight] = useState(false);
	const [answer, setAnswer] = useState("");

	const handleAnswerChanged = (value: string) => {
		const cleanedValue = value.trim();
		setAnswer(cleanedValue);
		if (!enterable && cleanedValue !== "")
			setEnterable(true);
	}

	const handleEnter = () => {
		logger.info("WrittenAnswerController, kitem "+props.kitem.id+", answer entered: "+answer);
		if (props.kitem.answers.includes(answer)) {
			logger.debug("Answer is correct.");
			setShowRight(true);
			props.onSuccess();
			playSound({audioUrl: require('../assets/jaaa.m4a')});
		} else {
			logger.debug("Answer is incorrect.");
			setShowWrong(true);
			props.onFail();
			playSound({audioUrl: require('../assets/hoppala-das-ueben-wir-bald-nochmal.m4a')});
		}
		setEnterable(false);
		setEditable(false);
	}

	const solution = props.kitem.answers[0];
	assert(solution, "Kitem seems to have no answer");

	return <>
		<WrittenAnswerView
			question={props.kitem.question}
			answer={answer}
			solution={solution}
			onChangeAnswer={handleAnswerChanged}
			enterable={enterable}
			onEnter={handleEnter}
			onNext={props.onNext}
			showRight={showRight}
			showWrong={showWrong}
			editable={editable}
		/>
	</>;
}
