import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";



export const WochentageKitems: SMCKitem[] = [
	{
		id: "wochentage_erster",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/erster-tag-der-woche.m4a'),
			transcript: "Was ist der erste Tag der Woche?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/montag.m4a'),
				transcript: "Montag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/sonntag.m4a'),
				transcript: "Sonntag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/dienstag.m4a'),
				transcript: "Dienstag",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_montag_x_mittwoch",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/welcher-tag-liegt-zwischen-montag-und-mittwoch.m4a'),
			transcript: "Welcher Tag liegt zwischen Montag und Mittwoch?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/dienstag.m4a'),
				transcript: "Dienstag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/sonntag.m4a'),
				transcript: "Sonntag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/mittwoch.m4a'),
				transcript: "Mittwoch",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/donnerstag.m4a'),
				transcript: "Donnerstag",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_dienstag_x_donnerstag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/welcher-tag-liegt-zwischen-dienstag-und-donnerstag.m4a'),
			transcript: "Welcher Tag liegt zwischen Dienstag und Donnerstag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/donnerstag.m4a'),
				transcript: "Donnerstag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/montag.m4a'),
				transcript: "Montag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/mittwoch.m4a'),
				transcript: "Mittwoch",
				correct: true,
			},
		],
	},
	{
		id: "wochentage_mittwoch_x_freitag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/welcher-tag-liegt-zwischen-mittwoch-und-freitag.m4a'),
			transcript: "Welcher Tag liegt zwischen Mittwoch und Donnerstag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/donnerstag.m4a'),
				transcript: "Donnerstag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/freitag.m4a'),
				transcript: "Freitag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/dienstag.m4a'),
				transcript: "Dienstag",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_nach_mittwoch",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/tag-nach-mittwoch.m4a'),
			transcript: "Welcher Tag kommt nach Mittwoch?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/donnerstag.m4a'),
				transcript: "Donnerstag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/freitag.m4a'),
				transcript: "Freitag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/dienstag.m4a'),
				transcript: "Dienstag",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_nach_donnerstag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/tag-nach-donnerstag.m4a'),
			transcript: "Welcher Tag kommt nach Donnerstag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/mittwoch.m4a'),
				transcript: "Mittwoch",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/freitag.m4a'),
				transcript: "Freitag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/samstag.m4a'),
				transcript: "Samstag",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_nach_freitag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/tag-nach-freitag.m4a'),
			transcript: "Welcher Tag kommt nach Freitag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/donnerstag.m4a'),
				transcript: "Donnerstag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/sonntag.m4a'),
				transcript: "Sonntag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/samstag.m4a'),
				transcript: "Samstag",
				correct: true,
			},
		],
	},
	{
		id: "wochentage_nach_samstag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/tag-nach-samstag.m4a'),
			transcript: "Welcher Tag kommt nach Samstag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/freitag.m4a'),
				transcript: "Freitag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/sonntag.m4a'),
				transcript: "Sonntag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/mittwoch.m4a'),
				transcript: "Mittwoch",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_nach_sonntag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/tag-nach-sonntag.m4a'),
			transcript: "Welcher Tag kommt nach Sonntag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/freitag.m4a'),
				transcript: "Freitag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/montag.m4a'),
				transcript: "Montag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/mittwoch.m4a'),
				transcript: "Mittwoch",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_nach_montag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/tag-nach-montag.m4a'),
			transcript: "Welcher Tag kommt nach Montag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/dienstag.m4a'),
				transcript: "Dienstag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/sonntag.m4a'),
				transcript: "Sonntag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/mittwoch.m4a'),
				transcript: "Mittwoch",
				correct: false,
			},
		],
	},
	{
		id: "wochentage_nach_dienstag",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/tag-nach-dienstag.m4a'),
			transcript: "Welcher Tag kommt nach Dienstag?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/donnerstag.m4a'),
				transcript: "Donnerstag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/montag.m4a'),
				transcript: "Montag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/mittwoch.m4a'),
				transcript: "Mittwoch",
				correct: true,
			},
		],
	},
	{
		id: "wochentage_wochenende",
		type: KitemType.SMC,
		topic: "wochentage",
		question: {
			audioUrl: require('../assets/wochentage/welche-tage-gehoeren-zum-wochenende.m4a'),
			transcript: "Welche Tage gehören zum Wochenende?",
		},
		answers: [
			{
				audioUrl: require('../assets/wochentage/samstag-sonntag.m4a'),
				transcript: "Samstag und Sonntag",
				correct: true,
			},
			{
				audioUrl: require('../assets/wochentage/freitag-samstag.m4a'),
				transcript: "Freitag und Samstag",
				correct: false,
			},
			{
				audioUrl: require('../assets/wochentage/freitag-samstag-sonntag.m4a'),
				transcript: "Freitag, Samstag, Sonntag",
				correct: false,
			},
		],
	},
];