import React, { cloneElement, ReactElement, ReactNode } from 'react';
// import makeStyles from '@mui/styles';
import Typography from '@mui/material/Typography';
// import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TimelapseIcon from '@mui/icons-material/Timelapse';
// import LText from '../tools/LText';
import "./LoadingView.css";
import { Badge, Box, Card, CardContent, Container, LinearProgress, SvgIconTypeMap, useTheme } from '@mui/material';
import AbcIcon from '@mui/icons-material/Abc';
import NumbersIcon from '@mui/icons-material/Numbers';
import { Topic } from '../contexts/LearningContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


export interface TopicCardProps {
	title?: string,
	onClick(): void,
	disabled: boolean,
	exposure: number,
	known: number,
	// children: ReactNode,
	icon?: boolean, 
	remainingRetaining: number,
	remainingLearning: number,
	remainingNew: number,
}

function TopicCard(props: TopicCardProps) {
	const theme = useTheme();

	
	return <>
		<Card sx={{
			position: "relative", 
			marginBottom: theme.spacing(4), 
			textAlign: "center", 
			cursor: "pointer", 
			marginLeft: props.icon ? 0 : 2,
			marginRight: props.icon ? 0 : 2,
			// backgroundColor: props.icon ? theme.palette.grey[200] : undefined,
			}} onClick={props.onClick}>
			{props.disabled && <CheckCircleIcon fontSize='large' color="disabled" sx={{fontSize: 80, position: "absolute", right: -13, top: -18}}/>}
			<CardContent sx={{padding: props.icon ? 1 : undefined}}>

				{/* <Box sx={{position: "absolute", left: 0, top: 0}}>
					<Typography variant="body2"><RefreshIcon fontSize="small"/> 7</Typography>
					<Typography variant="body2"><RestartAltIcon fontSize="small"/> 1</Typography>
					<Typography variant="body2"><ModelTrainingIcon fontSize="small"/> 2</Typography>
				</Box> */}
				{/* <Box sx={{position: "absolute", left: 0, top: 0}}>
					<Typography variant="body2">7 / 1 / 2</Typography>
				</Box> */}
				<Box sx={{textAlign: "center"}}>
					<Typography variant="caption" color={theme.palette.text.disabled}>{props.remainingRetaining} / {props.remainingLearning} / {props.remainingNew}</Typography>
				</Box>
				{/* <Badge badgeContent="✔" color="primary"> */}
				{props.title && <Typography variant='h2' 
					// color="inactive"
					sx={props.disabled ? {color: theme.palette.text.disabled} : undefined}
					>{props.title}</Typography>
				}
				{/* </Badge> */}
				{/* <Button size='large' variant="contained">{topic}</Button> */}
				{props.icon && <ModelTrainingIcon
						sx={{fontSize: 80, marginTop: 1, marginBottom: 0}}
						color={props.disabled ? "disabled" : "secondary"}
					/>}
			</CardContent>
			<LinearProgress variant="determinate" value={(props.known / props.exposure) * 100} sx={{width: props.exposure + "%"}} />
		</Card>
	</>;
}

export default TopicCard;
