/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { Box, Button, Container, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { isSpeechSynth, SpeechSynthText } from "./SpeechSynth";
import logger from "../services/logger";
import { AudioUrl, isAudioUrl } from "../services/sound-player";
import AudioButton from "./AudioButton";
import { DisplayImage, DisplayText, isDisplayImage, isDisplayText } from "../App";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { FlashCardState } from "../controller/FlashCardController";
import MarkdownQuestion from "./MarkdownQuestion";

/**
 * Custom hook which takes any function as an argument and returns a function with the original
 * function's signature that does one of two things:
 * - nothing if the component has been mounted for less than half a second
 * - calls the original function with its original parameters if the component has been mounted for at least half a second
 */
function useSleepOnMount<T extends (...args: any[]) => any>(callback: T) {
	const callbackRef = useRef(callback);
	const mountTimeRef = useRef<number>(0);
  
	// Save the callback function to a ref to avoid changes in the callback triggering the effect.
	useEffect(() => {
	  callbackRef.current = callback;
	}, [callback]);
  
	useEffect(() => {
	  mountTimeRef.current = Date.now();
	}, []);
  
	// The returned function has the same signature as the original callback function.
	const sleepyCallback = (...args: Parameters<T>) => {
	  const currentTime = Date.now();
	  if (currentTime - mountTimeRef.current >= 500) {
		// If the component has been mounted long enough, call the original callback.
		callbackRef.current(...args);
	  } else {
		// If the component hasn't been mounted long enough yet, do nothing.
		logger.debug("Component has not been mounted long enough yet.");
	  }
	};
  
	return sleepyCallback;
}



export interface FlashCardViewProps {
	question: DisplayText | SpeechSynthText | AudioUrl | DisplayImage,
	answer: DisplayText | DisplayImage | SpeechSynthText | AudioUrl,
	onClickCorrect(): void,
	onClickWrong(): void,
	onClickShow(): void,
	state: FlashCardState,
}

export default function FlashCardView(props: FlashCardViewProps) {
	const theme = useTheme();
	
	const ItemEvaluation = () => {
		const sleepyClickTrue = useSleepOnMount(props.onClickCorrect);
		const sleepyClickFalse = useSleepOnMount(props.onClickWrong);

		return <>
		<Paper elevation={8} sx={{padding: 4, width: 256,}}>
			<Grid container
				direction="column"
				justifyContent="space-around"
				alignItems="center"
				sx={{height: "100%", minHeight: "50vh"}}
					>
				<Grid item marginBottom={4}>
					{isDisplayImage(props.answer) && <>
						<Box 
							marginTop={4}
							marginBottom={0}
							sx={{
								textAlign: "center",
								}}
							// onClick={(isSpeechSynth(props.answer) || isAudioUrl(props.answer)) ? props.onClickPlay : undefined}
							>
							{(isSpeechSynth(props.answer) || isAudioUrl(props.answer)) ? <>
								<AudioButton
									audio={props.answer}
									size="xxl"
									autoPlay
									autoPlayDelay={200} // ugly hack. props.answer might refresh a few ms before props.state so the answer would beginn playing prematurely. So let's delay the autoplay a bit so that a state change can still cancel the autoplay.
									keepQueue
									content={<img 
										src={props.answer.imageUrl}
										alt={props.answer.alt}
										style={{
											maxWidth: "100%",
											maxHeight: "30vh",
											// boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
										}}
									/>}
								/>
							</> : <>
								<img 
									src={props.answer.imageUrl}
									alt={props.answer.alt}
									style={{
										maxWidth: "100%",
										maxHeight: "30vh",
										// boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
									}}
								/>
							</>}
						</Box>
					</>}
					{(isAudioUrl(props.answer) || isSpeechSynth(props.answer)) && !isDisplayImage(props.answer) &&
						<AudioButton
							audio={props.answer}
							size="xxl"
							autoPlay
							autoPlayDelay={200} // ugly hack. props.answer might refresh a few ms before props.state so the answer would beginn playing prematurely. So let's delay the autoplay a bit so that a state change can still cancel the autoplay.
							keepQueue
						/>
					}
					{(isDisplayText(props.answer)) && <>
						<MarkdownQuestion text={props.answer.displayText} sx={props.answer.sx} markdownStyle={props.answer.markdownStyle}/>
					</>}
					{/* <IconButton aria-label="delete" size="large" onClick={props.onClickPlayAnswer}>
						<VolumeUpIcon sx={{ fontSize: "60px", color: "black" }} />
					</IconButton> */}
				</Grid>
				<Grid item>
					<Box 
					// marginTop="25vh"
					>
						{/* Use onTouchEnd + onMouseUp instead of onClick because otherwise it will not trigger on sluggish touches. */}
						<IconButton color="primary" onTouchEnd={sleepyClickTrue} onMouseUp={sleepyClickTrue}><SentimentVerySatisfiedIcon sx={{fontSize: "3.75rem", marginRight: 1}} /></IconButton>
						<IconButton color="primary" onTouchEnd={sleepyClickFalse} onMouseUp={sleepyClickFalse}><RestartAltIcon sx={{fontSize: "3.75rem", marginLeft: 2}} /></IconButton>
						{/* <Button
							onClick={props.onClickTrue}
							css={css`
								margin-right: 24px;
							`}
						>
						<Typography variant="h2">✔</Typography></Button>
						<Button css={css`
							margin-left: 24px;
						`}
						onClick={props.onClickFalse}>
						<Typography variant="h2">✖</Typography></Button> */}
					</Box>
				</Grid>
			</Grid>
		</Paper>
	</>
	}

	const ItemShowAnswer = () => {
		// const sleepyClickNext = useSleepOnMount(props.onClickShow);

		return <>
			<Paper elevation={0} sx={{
				padding: 4,
				backgroundColor: theme.palette.background.default,
				// backgroundColor: "rgb(229, 246, 253)",
				// boxShadow: "0px 5px 5px -3px rgba(198,40,40,0.2), 0px 8px 10px 1px rgba(198,40,40,0.14), 0px 3px 14px 2px rgba(198,40,40,0.12)",
				// borderStyle: "solid",
				// borderColor: theme.palette.error.main,
				width: 256,
				}}
				>
				<Grid container
					direction="column"
					justifyContent="space-around"
					alignItems="center"
					sx={{height: "100%", minHeight: "50vh"}}
					>
					{/* <Grid item marginBottom={4}>
						<IconButton aria-label="delete" size="large" onClick={props.onClickPlay}>
							<VolumeUpIcon sx={{ fontSize: "80px", color: "black" }} />
						</IconButton>
					</Grid> */}
					<Grid item>
						<Box>
							{/* <IconButton onClick={props.onClickNext} size="large">
								<VisibilityIcon  sx={{ fontSize: "80px", color: "black" }}/>
							</IconButton> */}
							{/* <Button variant="contained" size="large" sx={{fontSize: 20}} color="inherit" onClick={sleepyClickNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button> */}
							<Button startIcon={<VisibilityIcon/>} variant="contained" size="large" color="inherit" onClick={props.onClickShow}>Zur Antwort</Button>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	}


	
	return <>
	<Container>
		<Grid container
			direction="column"
			justifyContent="space-around"
			alignItems="center"
  			>
			<Grid item>
				{isDisplayImage(props.question) ?
					<Box 
						marginTop={4}
						marginBottom={0}
						sx={{
							textAlign: "center",
							}}
						>
						<img 
							src={props.question.imageUrl}
							alt={props.question.alt}
							style={{
								maxWidth: "100%",
								maxHeight: "30vh",
								boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
							}}
						/>
					</Box>
				:
					<Box 
						marginTop={8}
						marginBottom={6}
						sx={{textAlign: "center"}}
						>
							{isDisplayText(props.question) && <MarkdownQuestion text={props.question.displayText} sx={props.question.sx} markdownStyle={props.question.markdownStyle}/>}
							{(isAudioUrl(props.question) || isSpeechSynth(props.question)) && <AudioButton audio={props.question} size="xxl" autoPlay/>}
							{isAudioUrl(props.question) && props.question.transcript && <>
									<Typography variant="body2" sx={{marginTop: 2}}>{props.question.transcript}</Typography>
							</>}
					</Box>
				}
			</Grid>
			<Grid item marginTop={4}>
				<Box>
					<Box>
						<Slide direction={props.state === "question" ? "up" : "down"} in={props.state === "question"} mountOnEnter unmountOnExit>
							<div>
								<ItemShowAnswer/>
							</div>
						</Slide>
						<Slide direction={props.state === "evaluation" ? "up" : "down"} in={props.state === "evaluation"} mountOnEnter unmountOnExit>
							<div>
								<ItemEvaluation/>
							</div>
						</Slide>
					</Box>
				</Box>
			</Grid>
		</Grid>
	</Container>
	</>;
}