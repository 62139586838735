import { KitemType } from "../contexts/LearningContext";
import { ImageChooserKitem } from "../controller/ImageChooserController";
import { RocketKitem } from "../controller/RocketGameController";
import { SMCKitem } from "../controller/SMCController";



export const ZahlenMengen2xKitems: (SMCKitem|RocketKitem|ImageChooserKitem)[] = [
	{
		id: "Zahlenmengen_21v30",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-21v30.svg').default,
			alt: "Eine Menge von 21 Punkten",
		},
		answers: [
			{
				synthText: "21",
				correct: true,
			},
			{
				synthText: "20",
				correct: false,
			},
			{
				synthText: "22",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_22v30",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-22v30.svg').default,
			alt: "Eine Menge von 22 Punkten",
		},
		answers: [
			{
				synthText: "22",
				correct: true,
			},
			{
				synthText: "21",
				correct: false,
			},
			{
				synthText: "23",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_23v30",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-23v30.svg').default,
			alt: "Eine Menge von 23 Punkten",
		},
		answers: [
			{
				synthText: "23",
				correct: true,
			},
			{
				synthText: "22",
				correct: false,
			},
			{
				synthText: "24",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_24v30",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-24v30.svg').default,
			alt: "Eine Menge von 24 Punkten",
		},
		answers: [
			{
				synthText: "24",
				correct: true,
			},
			{
				synthText: "23",
				correct: false,
			},
			{
				synthText: "25",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_25v30",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-25v30.svg').default,
			alt: "Eine Menge von 25 Punkten",
		},
		answers: [
			{
				synthText: "25",
				correct: true,
			},
			{
				synthText: "23",
				correct: false,
			},
			{
				synthText: "26",
				correct: false,
			},
			{
				synthText: "24",
				correct: false,
			},
		],
	},
];