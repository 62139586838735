import React from 'react';

export interface UserManagerInterface {
	currentUserId: string,
	setUserId(userId: string): void,
}


const UserManagerContext = React.createContext<UserManagerInterface>({
	currentUserId: "__uninitialized",
	setUserId: (userId: string) => {},
});

export default UserManagerContext;
