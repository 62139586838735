import React from 'react';
import { useTheme, Typography, Stack } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import QuizIcon from '@mui/icons-material/Quiz';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TopicIcon from '@mui/icons-material/Topic';
import { format } from 'date-fns';
const buildMeta = require('../build-metadata.json');

export interface parentsMenu {
	onClose(): void,
	onClickBack(): void,
	onClickMyItems(): void,
	onClickSettings(): void,
	onClickParentsTopics(): void,
	open: boolean,
}

export default function ParentsMenu(props: parentsMenu) {
	const theme = useTheme();
	const buildDate = new Date(buildMeta.time);
	const buildDateStr = format(buildDate, 'yyMMddHHmm');


	return <>
		<Drawer
		anchor="left"
		open={props.open}
		onClose={props.onClose}
		>
			<Box>
				<Box padding={2}>
					<Stack direction="column" alignItems="center">
					<ModelTrainingIcon color="secondary" fontSize="large"/>
					<Typography variant="h6" sx={{letterSpacing: "0.1em"}}>Repty</Typography>
					</Stack>
					{/* <Typography variant="subtitle1">Alles lernen</Typography> */}
				</Box>
				<Divider />
				<List>
					<ListItem disablePadding sx={{paddingRight: 3}}>
						<ListItemButton onClick={props.onClickBack}>
							<ListItemIcon>
								<ArrowBackIcon/>
							</ListItemIcon>
							<ListItemText primary="Zur Übersicht" />
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<ListItemButton onClick={props.onClickMyItems}>
							<ListItemIcon>
								<QuizIcon/>
							</ListItemIcon>
							<ListItemText primary="Meine Fragen" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={props.onClickParentsTopics}>
							<ListItemIcon>
								<TopicIcon/>
							</ListItemIcon>
							<ListItemText primary="Themen" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={props.onClickSettings}>
							<ListItemIcon>
								<SettingsIcon/>
							</ListItemIcon>
							<ListItemText primary="Einstellungen" />
						</ListItemButton>
					</ListItem>
				</List>
				<Box
					paddingLeft={2}
					paddingBottom="env(safe-area-inset-bottom)"
					position="fixed"
					bottom={0}
					>
					<Typography variant="caption">v{buildDateStr}</Typography>
				</Box>

			</Box>
		</Drawer>
	</>;
}
