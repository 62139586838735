import React, { useContext, useEffect, useState } from 'react';

import sample from 'lodash.sample';
import DialogsContext from '../contexts/DialogsContext';
import logger from './logger';

export default function useParentCheck() {
	const dialogs = useContext(DialogsContext);
  
	const parentCheck = async (): Promise<boolean> => {
		logger.info("Parent check...")
		const parentQuestions = [
			["Was ist 1+1?", "2"],
			["Was ist 3x3?", "9"],
			["Was ist 20/2?", "10"],
			["Was ist 7+7?", "14"],
		];
		const q = sample(parentQuestions) || parentQuestions[0];
		const response = await dialogs.prompt(q[0], {type: "number"});
		logger.debug("Parent check response:", response);
		if (response === q[1]) {
			return true;
		} else {
			return false;
		}
	};

	return parentCheck;
}
