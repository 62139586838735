import React, { useContext } from 'react';
import DebugView from '../components/DebugView';
import DialogsContext from '../contexts/DialogsContext';
import LearningContext, { KitemRep } from '../contexts/LearningContext';
import logger from '../services/logger';
import { schedulerV2 } from '../services/schedulerV2';
import { nextDueDate } from '../services/schedulerV1';
import { fullSync, queueServerRep } from '../services/repSyncer';
import UserManagerContext from '../contexts/UserManagerContext';


interface Props {
}

export default function DebugViewController(props: Props) {
	// const [values, setValues] = useState({});
	const learning = useContext(LearningContext);
	const dialogs = useContext(DialogsContext);
	const learningPackage = learning.getLearningPackage();
	const userManager = useContext(UserManagerContext);

	const values = {
		"Heutiges Lernpaket": JSON.stringify(learningPackage, undefined, 4),
	};

	const curriculum = learning.getCurriculum();

	const handleExport = async () => {
		const progress = await learning.getReps();
		const jsonBlob = new Blob([JSON.stringify(progress)], { type: 'application/json' });
		const downloadUrl = URL.createObjectURL(jsonBlob);
		const a = document.createElement('a');
		a.href = downloadUrl;
		a.download = 'reps.json';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	const handleDeletePlan = () => {
		learning.deleteTodaysLearningPackages();
		dialogs.alert({message: "Gelöscht."});
	}

	const repTemplate: KitemRep = {
		id: "0",
		date: new Date(),
		success: true,
		kitemId: "",
	};

	const repsStandard = [repTemplate];
	for (let i = 0; i < 20; i++) {
		const newRep = Object.assign({}, repTemplate, {
			id: (i+1).toString(),
			date: nextDueDate(repsStandard),
		});
		repsStandard.push(newRep);
	}
	logger.debug("Simulated reps", repsStandard);
	const repsStandardTimes = repsStandard.map(r => r.date);

	const repsNew = [repTemplate];
	for (let i = 0; i < 20; i++) {
		const newRep = Object.assign({}, repTemplate, {
			id: (i+1).toString(),
			date: schedulerV2(repsNew),
		});
		repsNew.push(newRep);
	}
	logger.debug("Simulated reps new", repsNew);
	const repsNewTimes = repsNew.map(r => r.date);

	const handleSyncClick = async () => {
		const mockAddLocal = (rep: KitemRep) => {
			logger.debug("Mock adding rep "+rep.id, rep);
		}
		const localReps: KitemRep[] = await learning.getReps();
		logger.info("Synching reps.", localReps);
		fullSync(userManager.currentUserId, localReps, mockAddLocal, queueServerRep)
	}

	return (
		<React.Fragment>
			<DebugView
				values={values}
				curriculum={curriculum}
				onClickExport={handleExport}
				onClickDeleteTodaysLearningPackage={handleDeletePlan}
				progressionStandard={repsStandardTimes}
				progressionNew={repsNewTimes}
				onClickSync={handleSyncClick}
				currentUserId={userManager.currentUserId}
			/>
		</React.Fragment>
	);
}
