import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import assert from 'assert';
import { TopicStatus } from '../services/useTopics';
import { TopicWeight } from '../controller/ParentsTopicsViewController';
import logger from '../services/logger';

interface TopicSettingsDialogProps {
	open: boolean,
	onClose(): void,
	title: string,
	status: TopicStatus,
	onStatusChange(status: TopicStatus): void,
	weight: TopicWeight,
	onWeightChange(weight: TopicWeight): void,
}

export default function TopicSettingsDialog(props: TopicSettingsDialogProps) {

	const handleStatusChange = (
		event: React.MouseEvent<HTMLElement>,
		newStatus: string,
	  ) => {
		if (newStatus) { // newStatus can be null if user clicks on already selected option
			assert(newStatus === "inactive" || newStatus === "learning" || newStatus === "remembering");
			props.onStatusChange(newStatus);
		}
	};

	/**
	 * Take the change event of the select element and use it
	 * for calling props.onWeightChange.
	 */
	const handleWeightChange = (event: SelectChangeEvent<TopicWeight>) => {
		const newWeight = event.target.value;
		if (newWeight === TopicWeight.low || newWeight === TopicWeight.normal || newWeight === TopicWeight.high)
			props.onWeightChange(newWeight);
		else
			logger.error("Select did not return a valid TopicWeight");
	};

	return <Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<ToggleButtonGroup
					color={props.status === "learning" ? "primary" : props.status === "remembering" ? "info" : "standard"}
					value={props.status}
					exclusive
					onChange={handleStatusChange}
				>
					<ToggleButton value="inactive">Inaktiv</ToggleButton>
					<ToggleButton value="remembering">Merken</ToggleButton>
					<ToggleButton value="learning">Lernen</ToggleButton>
				</ToggleButtonGroup><br/>
				<Typography variant="caption" sx={{
					marginLeft: "14px",
					color: "rgba(0, 0, 0, 0.6)",
				}}>
					{props.status === "learning" ? "Neue Fragmente aktiv lernen" : props.status === "remembering" ? "Nur bekannte Fragmente auffrischen" : "Thema weder lernen noch auffrischen"}
				</Typography>

				<FormControl fullWidth sx={{marginTop: 4}}>
					<InputLabel id="demo-simple-select-label">Gewichtung</InputLabel>
					<Select
						value={props.weight}
						label="Gewichtung"
						onChange={handleWeightChange}
					>
						<MenuItem value={TopicWeight.low}>Niedrig</MenuItem>
						<MenuItem value={TopicWeight.normal}>Normal</MenuItem>
						<MenuItem value={TopicWeight.high}>Hoch</MenuItem>
					</Select>
					<FormHelperText>Welche Priorität haben neue Fragmente gegenüber anderen Themen?</FormHelperText>
				</FormControl>

				{/* <FormControl fullWidth sx={{marginTop: 4}}>
					<InputLabel id="demo-simple-select-label">Status</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value="remembering"
						label="Age"
						// onChange={handleChange}
					>
						<MenuItem value="inactive">Inaktiv</MenuItem>
						<MenuItem value="remembering">Lernen</MenuItem>
						<MenuItem value="learning">Nur erinnern</MenuItem>
					</Select>
					<FormHelperText>Wissen wird aufgebaut und aufgefrischt</FormHelperText>
				</FormControl> */}
			</DialogContent>
			<DialogActions>
				<Button
					autoFocus
					onClick={props.onClose} 
					>
					Schließen
				</Button>
			</DialogActions>
		</Dialog>
}