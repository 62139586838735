import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";



export const GeschwisterKitems: SMCKitem[] = [
	{
		id: "ueber-baby-heben",
		type: KitemType.SMC,
		topic: "geschwister",
		question: {
			audioUrl: require('../assets/geschwister/ueber-baby-heben-frage.m4a'),
			transcript: "Was darf man über Babys drüberheben?",
		},
		answers: [
			{
				audioUrl: require('../assets/geschwister/ueber-baby-heben-antwort-nichts.m4a'),
				transcript: "Nichts. Immer am Baby vorbei heben.",
				correct: true,
			},
			{
				audioUrl: require('../assets/geschwister/ueber-baby-heben-antwort-1kg.m4a'),
				transcript: "Alles, was leichter als 1kg ist.",
				correct: false,
			},
			{
				audioUrl: require('../assets/geschwister/ueber-baby-heben-antwort-kopf.m4a'),
				transcript: "Alles, was kleiner als der Kopf vom Baby ist.",
				correct: false,
			},
		],
	},
	{
		id: "zu-klein-fuer-baby",
		type: KitemType.SMC,
		topic: "geschwister",
		question: {
			audioUrl: require('../assets/geschwister/zu-klein-fuer-baby-frage.m4a'),
			transcript: "Wann ist etwas groß genug, dass Babys sich nicht daran verschlucken können?",
		},
		answers: [
			{
				audioUrl: require('../assets/geschwister/zu-klein-fuer-baby-toilettenpapier.m4a'),
				transcript: "Wenn es nicht durch eine Rolle Toilettenpapier passt.",
				correct: true,
			},
			{
				audioUrl: require('../assets/geschwister/zu-klein-fuer-baby-ring.m4a'),
				transcript: "Wenn es nicht durch einen Ring passt.",
				correct: false,
			},
		],
	},

];