import { AnySpecificKitem } from "../AnySpecificKitem";
import { KitemType } from "../contexts/LearningContext";
import { WrittenAnswerInputType } from "../controller/WrittenAnswerController";



export const ZahlenMengen11_20Kitems: (AnySpecificKitem)[] = [
	{
		id: "Zahlenmengen_11v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-11v20.svg').default,
			alt: "Eine Menge von 11 Punkten",
		},
		answers: [
			{
				synthText: "11",
				correct: true,
			},
			{
				synthText: "10",
				correct: false,
			},
			{
				synthText: "12",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_12v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-12v20.svg').default,
			alt: "Eine Menge von 12 Punkten",
		},
		answers: [
			{
				synthText: "12",
				correct: true,
			},
			{
				synthText: "11",
				correct: false,
			},
			{
				synthText: "13",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_13v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-13v20.svg').default,
			alt: "Eine Menge von 13 Punkten",
		},
		answers: [
			{
				synthText: "13",
				correct: true,
			},
			{
				synthText: "12",
				correct: false,
			},
			{
				synthText: "14",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_14v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-14v20.svg').default,
			alt: "Eine Menge von 14 Punkten",
		},
		answers: [
			{
				synthText: "14",
				correct: true,
			},
			{
				synthText: "12",
				correct: false,
			},
			{
				synthText: "13",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_15v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-15v20.svg').default,
			alt: "Eine Menge von 15 Punkten",
		},
		answers: [
			{
				synthText: "15",
				correct: true,
			},
			{
				synthText: "16",
				correct: false,
			},
			{
				synthText: "14",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_16v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-16v20.svg').default,
			alt: "Eine Menge von 16 Punkten",
		},
		answers: [
			{
				synthText: "16",
				correct: true,
			},
			{
				synthText: "17",
				correct: false,
			},
			{
				synthText: "15",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_13v20_tippen",
		type: KitemType.WrittenAnswer,
		topic: "zahlen",
		question: {
			imageUrl: require('../assets/zahlen/set-13v20.svg').default,
			alt: "Eine Menge von 13 Punkten",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"13",
		],
	},
	{
		id: "Zahlenmengen_17v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-17v20.svg').default,
			alt: "Eine Menge von 17 Punkten",
		},
		answers: [
			{
				synthText: "17",
				correct: true,
			},
			{
				synthText: "18",
				correct: false,
			},
			{
				synthText: "16",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_18v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-18v20.svg').default,
			alt: "Eine Menge von 18 Punkten",
		},
		answers: [
			{
				synthText: "18",
				correct: true,
			},
			{
				synthText: "19",
				correct: false,
			},
			{
				synthText: "16",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_19v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-19v20.svg').default,
			alt: "Eine Menge von 19 Punkten",
		},
		answers: [
			{
				synthText: "19",
				correct: true,
			},
			{
				synthText: "18",
				correct: false,
			},
			{
				synthText: "17",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_20v20",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-20v20.svg').default,
			alt: "Eine Menge von 20 Punkten",
		},
		answers: [
			{
				synthText: "20",
				correct: true,
			},
			{
				synthText: "200",
				correct: false,
			},
			{
				synthText: "21",
				correct: false,
			},
		],
	},
];