import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, AlertTitle, Avatar, Card, CardActions, CardContent, CardHeader, IconButton, Stack, Typography, useTheme } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import DeleteIcon from '@mui/icons-material/Delete';
import logger from '../services/logger';
import { useLongPress } from 'use-long-press';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface RecorderCardProps {
	variant: "question" | "rightAnswer" | "wrongAnswer",
	transcript?: string,
	deleteable?: boolean,
	onRecordStart(): void,
	onRecordCancel(): void,
	onRecordFinish(): void,
	onPlay(): void,
	playEnabled: boolean,
	moreMenu?: [string, () => void][],
}

export default function RecorderCard(props: RecorderCardProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

	const recordLongPressBinding = useLongPress(() => {
		logger.debug('Long pressed!');
	}, {
		onStart: props.onRecordStart,
		onFinish: props.onRecordFinish,
		onCancel: props.onRecordCancel,
		threshold: 750,
	});

	return <>
		<Card sx={{position: "relative", backgroundColor: props.variant === "question" ? "rgb(229, 246, 253)" : props.variant === "rightAnswer" ? "rgb(237, 247, 237)" : "rgb(253, 237, 237)"}} elevation={0}>
			<CardContent>
				{props.moreMenu && <>
					<IconButton onClick={handleMoreClick} size='small' sx={{position: "absolute", right: 8, top: 12}}>
						<MoreVertIcon fontSize='medium' />
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={anchorEl !== null}
						onClose={() => setAnchorEl(null)}
					>
						{props.moreMenu?.map((item) => <MenuItem onClick={() => {item[1](); setAnchorEl(null)}}>{item[0]}</MenuItem>)}
					</Menu>
					</>
				}
				<Stack direction="row" alignItems="center">
					{props.variant === "question" && <HelpOutlineIcon color="info"/>}
					{props.variant === "rightAnswer" && <TaskAltIcon color="success"/>}
					{props.variant === "wrongAnswer" && <ErrorOutlineIcon color="error"/>}
					<Typography sx={{fontWeight: 500, marginLeft: 2, marginRight: 4}}>{props.variant === "question" ? "Frage" : props.variant === "rightAnswer" ? "Richtige Antwort" : "Falsche Antwort"}</Typography>
					{/* <IconButton size='large' disabled={true}>
						<PlayCircleFilledWhiteOutlinedIcon fontSize='large' />
					</IconButton> */}

					{/* {props.deleteable !== false && props.deleteable !== undefined && <IconButton size='small' sx={{position: "relative", right: 0, marginLeft: 2}}>
						<DeleteIcon fontSize='medium' />
					</IconButton>} */}
				</Stack>


				<IconButton size='large' sx={{
					marginLeft: "24px",
					// border: "1px solid red"
				}}
					{...recordLongPressBinding()}
					>
					<MicIcon fontSize='large' />
				</IconButton>
				<IconButton size='large' disabled={!props.playEnabled} onClick={props.onPlay}>
					<PlayCircleFilledWhiteOutlinedIcon fontSize='large' />
				</IconButton>
				{/* <IconButton size='medium'>
					<DeleteIcon fontSize='large' />
				</IconButton> */}
				<br/>
				{props.transcript && <>
					<Stack direction="row" sx={{
				marginLeft: "40px",
				// border: "1px solid red"
				}}>
						{/* <SubtitlesIcon fontSize='small' color="disabled"/> */}
						<Typography variant="caption" sx={{display: "block"}}>"{props.transcript}"</Typography>
					</Stack>
				</>}
			</CardContent>
		</Card>
	</>;
}