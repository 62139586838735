import React from 'react';
import { Container, useTheme, Typography, Stack, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ProfileInformation } from '../services/profiles';

export interface SettingsViewProps {
	learningPackageMaxTotalItems: number,
	onLearningPackageMaxTotalItemsChange(size: number): void,
	learningPackageMaxHardItems: number,
	learningPackageMaxNewItems: number,
	learningPackageMaxHardItemsError: null | string,
	learningPackageMaxTotalItemsError: null | string,
	learningPackageMaxNewItemsError: null | string,
	onLearningPackageMaxHardItemsChange(size: number): void,
	onLearningPackageMaxNewItemsChange(size: number): void,
	onClickExport(): void,
	onClickSync(): void,
	onClickDeleteTodaysLearningPackage(): void,
	currentUserId: string,
	currentProfileName: string | undefined,
	choosableProfiles: ProfileInformation[],
	onChangeProfile(profileId: string): void,
}

export default function SettingsView(props: SettingsViewProps) {
	const theme = useTheme();

	return (
		<Container sx={{paddingTop: theme.spacing(4), paddingBottom: theme.spacing(8)}}>
			<Stack spacing={2}>
				<Typography variant="h5">Lernpakete</Typography>
				<TextField
					label="Max. Einheiten pro Tag"
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					variant="standard"
					onChange={(e) => props.onLearningPackageMaxTotalItemsChange(parseInt(e.target.value))}
					defaultValue={props.learningPackageMaxTotalItems}
					error={props.learningPackageMaxTotalItemsError ? true : undefined}
					helperText={props.learningPackageMaxTotalItemsError || undefined}
				/>
				<TextField
					label="Max. schwere Einheiten pro Tag"
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					variant="standard"
					onChange={(e) => props.onLearningPackageMaxHardItemsChange(parseInt(e.target.value))}
					defaultValue={props.learningPackageMaxHardItems}
					error={props.learningPackageMaxHardItemsError ? true : undefined}
					helperText={props.learningPackageMaxHardItemsError || undefined}
				/>
				<TextField
					label="Max. neue Einheiten pro Tag"
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					variant="standard"
					onChange={(e) => props.onLearningPackageMaxNewItemsChange(parseInt(e.target.value))}
					defaultValue={props.learningPackageMaxNewItems}
					error={props.learningPackageMaxNewItemsError ? true : undefined}
					helperText={props.learningPackageMaxNewItemsError || undefined}
				/>
				<Button sx={{marginLeft: 2}} variant="contained" color="inherit" onClick={props.onClickDeleteTodaysLearningPackage}>Heutigen Plan löschen</Button>
				<Typography variant="h5" sx={{paddingTop: 4}}>Daten</Typography>
				<Button variant="contained" color="inherit" onClick={props.onClickSync}>Server Sync</Button>
				<Button variant="contained" color="inherit" onClick={props.onClickExport}>Backup/Export</Button>
				<Typography variant="h5" sx={{paddingTop: 4}}>Benutzer</Typography>
				<Typography variant="body1">
					Nutzer-ID: {props.currentUserId}
					{props.currentProfileName && <>
						<br/>Profilname: {props.currentProfileName}
					</>}
				</Typography>
				{props.choosableProfiles.length > 0 && <>
					<Typography variant="h6">Wechseln zu</Typography>
					{props.choosableProfiles.map(profile => 
						<Button key={profile.id} variant="contained" color="inherit" onClick={() => props.onChangeProfile(profile.id)}>{profile.name}</Button>
					)}
					</>
				}
			</Stack>
		</Container>
	);
}
