import React from 'react';
// import makeStyles from '@mui/styles';
import Typography from '@mui/material/Typography';
// import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

// import LText from '../tools/LText';
import "./LoadingView.css";
import { useTheme } from '@mui/material';

// const useStyles = makeStyles(theme => ({
// 	container: {
// 		backgroundColor: theme.palette.background.paper,
// 		height: "100vh",
// 	},
// 	"@keyframes spinning": {
// 		from: {
// 			transform: "rotate(0deg)",
// 		},
// 		to: {
// 			transform: "rotate(360deg)",
// 		}
// 	  },
// 	"@keyframes pulse": {
// 		"0%": {
// 			transform: "scale(0.95)",
// 			// boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
// 		},
// 		"70%": {
// 			transform: "scale(1)",
// 			// boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
// 		},
// 		"100%": {
// 			transform: "scale(0.95)",
// 			// boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
// 		},
// 	},
// 	logo: {
// 		// animationName: '$spinning',
// 		animation: "$pulse 2s infinite",
// 		marginBottom: theme.spacing(2),
// 		fontSize: "7rem",
// 	},
// 	timeout: {
// 		marginTop: theme.spacing(2),
// 		textAlign: "center",
// 	},
// }));

export interface LoadingViewProps {
	what?: string,
	showReload?: boolean,
	onReload?(): void,
}

function LoadingView(props: LoadingViewProps) {
	// const classes = useStyles();
	const theme = useTheme();
	
	return (
		//@ts-ignore
		<Grid
			container
			direction="column"
			justify="center" // Zentriert den Inhalt vertikal
			alignItems="center"
			sx={{
				backgroundColor: theme.palette.background.paper,
				height: "100vh",
			}}
		>
			<Grid item>
				<ModelTrainingIcon fontSize="large"
					sx={{
						animation: "$pulse 2s infinite",
						marginBottom: theme.spacing(2),
						fontSize: "7rem",
					}}
				//  className={classes.logo}
				 />
			</Grid>
			<Grid item>
				<Typography variant="h4" style={{fontWeight: 300}} align="center" color="textPrimary" gutterBottom>
					{/* <FormattedMessage id="LoadingView.title" defaultMessage="Loading…"/> */}
					{/* <LText en="Loading…" de="Lade…"/> */}
					Lade…
				</Typography>
				{props.what && 
					<Typography variant="caption" style={{fontWeight: 100, width: "100%", display: "inline-block"}} align="center" color="textPrimary" gutterBottom>
						… {props.what} …
					</Typography>
				}
			</Grid>
			{props.showReload &&
				<Grid item
					sx={{
						marginTop: theme.spacing(2),
						textAlign: "center",
					}}
					// className={classes.timeout}
					>
					<Typography variant="h6" style={{fontWeight: 300, marginBottom: 10}} align="center" color="textPrimary" gutterBottom>
						This is taking longer than usual. :(
					</Typography>
					<Button onClick={props.onReload} variant="contained" color="secondary" style={{color: "white", marginBottom: "20px"}}>Try again</Button>
					<Typography variant="body1" style={{fontWeight: 300}}>Issues? <Link href="mailto:info@focalityapp.com">Contact us</Link> so that we can help.</Typography>
				</Grid>
			}
		</Grid>
	);
}

export default LoadingView;
