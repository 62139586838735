import { AnySpecificKitem } from "../AnySpecificKitem";
import { ZahlenKitems2x } from "./Kitems_Zahlen_2x";
import { ZahlenMengen1_10Kitems } from "./Kitems_Zahlen_Mengen_1-10";
import { ZahlenMengen11_20Kitems } from "./Kitems_Zahlen_Mengen_11-20";
import { ZahlenMengen2xKitems } from "./Kitems_Zahlen_Mengen_2x";
import { ZahlenSonstigesKitems } from "./Kitems_Zahlen_Sonstige";
import { ZahlenZiffern1_9Kitems } from "./Kitems_Zahlen_Ziffern_1-9";
import { ZahlenZiffern10_19Kitems } from "./Kitems_Zahlen_Ziffern_1x";
import { ZahlenTippenKitems } from "./Kitems_Zahlen_tippen";
import { ZahlenKitemsX0 } from "./Kitems_Zahlen_x0";

const replaceTopic = (kitems: AnySpecificKitem[], newTopic: string): AnySpecificKitem[] => kitems.map(k => Object.assign({}, k, {topic: newTopic}));

const zkitems: AnySpecificKitem[] = [
	...ZahlenMengen1_10Kitems,
	...ZahlenZiffern1_9Kitems,
	...ZahlenMengen11_20Kitems,
	...ZahlenZiffern10_19Kitems,
	...ZahlenMengen2xKitems,
	...ZahlenKitems2x,
	...ZahlenKitemsX0,
	...ZahlenTippenKitems,
	...ZahlenSonstigesKitems,
];

export const ZahlenKitems = replaceTopic(zkitems, "zahlen");