import { useContext, useEffect, useState } from 'react';
import DashboardView, { TopicState } from '../components/DashboardView';
import LearningContext, { Topic } from '../contexts/LearningContext';
import logger from '../services/logger';
import useParentCheck from '../services/useParentCheck';
import useTopics from '../services/useTopics';

interface DashboardControllerProps {
	onTopicSelected(topicId: string | null): void,
	onNavigateParents(): void,
	selfEvalMode: boolean,
	onSelfEvalModeChange(): void,
}

export default function DashboardController(props: DashboardControllerProps) {
	const learning = useContext(LearningContext);
	const parentCheck = useParentCheck();
	const [topicsEnriched, setTopicsEnriched] = useState<(Topic & TopicState)[]>([]);
	const [allTopicsState, setAllTopicsState] = useState<TopicState|null>(null);
	const { getTopicsWithState, getTopicState, getPackageTopicIds, getLearningTopicIds } = useTopics();
	
	// const kitems = learning.getKitems(props.topic);
	// const learningPackage = learning.getLearningPackage(props.topic);

	useEffect(() => {

		const asyncGetTopicState = async (topicId: string | null): Promise<TopicState> => {
			return getTopicState(topicId);
		}

		const updateTopics = async () => {
			const topicsEnriched: (Topic & TopicState)[] = getTopicsWithState();
			// for (let i = 0; i < topics.length; i++) {
			// 	const tState = await asyncGetTopicState(topics[i].id);
			// 	const enrichedTopic = Object.assign({}, topics[i], tState);
			// 	if (enrichedTopic.size > 0)
			// 		topicsEnriched.push(enrichedTopic);
			// }
			// const topicsEnriched = topics.map(async t => Object.assign({}, t, await getTopicState(t.id)))
			// .filter(t => t.size > 0)
			setTopicsEnriched(topicsEnriched);
		}
		asyncGetTopicState(null).then(ts => setAllTopicsState(ts));
		updateTopics();
	}, [getTopicState, getTopicsWithState])
	// const allTopicsState = getTopicState(null);
	// allTopicsState.done = !topicsEnriched.some(t => !t.done);
	const allTopicsStatePending: TopicState = {
		size: 0,
		exposure:0,
		known: 0,
		done: false,
		remainingRetaining: 0,
		remainingLearning: 0,
		remainingNew: 0,
	}

	const handleParentClick = async () => {
		logger.debug("Parent icon clicked");
		if (await parentCheck())
			props.onNavigateParents();
	}

	// const packageTopicIds = getPackageTopicIds();
	const packageTopicIds = getLearningTopicIds();

	logger.debug("topicsEnriched", topicsEnriched);

	return <>
		<DashboardView 
			topics={topicsEnriched.filter(t => packageTopicIds.includes(t.id))}
			// topics={topicsEnriched}
			allTopicsState={allTopicsState ?? allTopicsStatePending}
			onTopicSelected={props.onTopicSelected}
			onParentClick={handleParentClick}
			selfEvalMode={props.selfEvalMode}
			onSelfEvalModeChange={props.onSelfEvalModeChange}
		/>
	</>;
}
