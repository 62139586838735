import { KitemType } from "../contexts/LearningContext";
import { ImageChooserKitem } from "../controller/ImageChooserController";
import { RocketKitem } from "../controller/RocketGameController";
import { SMCKitem } from "../controller/SMCController";



export const ZahlenSonstigesKitems: (SMCKitem|RocketKitem|ImageChooserKitem)[] = [
	// {
	// 	id: "Zahlenmengen_10_Raketen",
	// 	type: KitemType.Rockets,
	// 	topic: "zahlenmengen",
	// 	setId: "zahlenmengen",
	// 	targetId: "10v10",
	// },
	{
		id: "Zahlenmengen_10",
		type: KitemType.ImageChooser,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "10",
	},
	{
		id: "Zahlenmengen_100",
		type: KitemType.ImageChooser,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "100",
	},
	{
		id: "Zahlenmengen_1000",
		type: KitemType.ImageChooser,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "1000",
	},
	{
		id: "Zahlenmengen_10000",
		type: KitemType.ImageChooser,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "10000",
	},
	{
		id: "Zahlenmengen_20000",
		type: KitemType.ImageChooser,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "20000",
	},
	{
		id: "Zahlen_Zehner",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "13  \n14  \n15",
			sx: {
				fontSize: "4rem",
			},
		},
		answers: [
			{
				synthText: "13, 14, 15",
				correct: true,
			},
			{
				synthText: "30, 40, 50",
				correct: false,
			},
			{
				synthText: "300, 400, 500",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_Ziger",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "30  \n40  \n50",
			sx: {
				fontSize: "4rem",
			},
		},
		answers: [
			{
				synthText: "13, 14, 15",
				correct: false,
			},
			{
				synthText: "30, 40, 50",
				correct: true,
			},
			{
				synthText: "300, 400, 500",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_4-400",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "4  \n40  \n400",
			sx: {
				fontSize: "4rem",
			},
		},
		answers: [
			{
				synthText: "4, 40, 400",
				correct: true,
			},
			{
				synthText: "4, 14, 40",
				correct: false,
			},
			{
				synthText: "4, 14, 400",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_5-500",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "5  \n50  \n500",
			sx: {
				fontSize: "4rem",
			},
		},
		answers: [
			{
				synthText: "5, 50, 500",
				correct: true,
			},
			{
				synthText: "5, 15, 50",
				correct: false,
			},
			{
				synthText: "5, 15, 500",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
];