import React from 'react';
import { Container, Grid, useTheme, Typography, Button, Box } from '@mui/material';
import { KitemProgress } from '../contexts/LearningContext';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format, formatDistance } from 'date-fns';


export interface DebugViewProps {
	values: Record<string, string>,
	curriculum: KitemProgress[],
	onClickExport(): void,
	onClickSync(): void,
	onClickDeleteTodaysLearningPackage(): void,
	progressionStandard: Date[],
	progressionNew: Date[],
	currentUserId: string,
}

export default function DebugView(props: DebugViewProps) {
	const theme = useTheme();

	const columns: GridColDef[] = [
		{ field: 'kitemId', headerName: 'Kitem ID', width: 150 },
		{ field: 'due', headerName: 'Due', width: 150 },
		{ field: 'repCount', headerName: 'Reps', width: 10 },
		{ field: 'state', headerName: 'State', width: 10 },
		{ field: 'easiness', headerName: 'Easiness', width: 10 },
		{ field: 'id', headerName: 'ID', width: 50 },
	];

	const rows = props.curriculum.map(p => Object.assign({}, p, {
		reps: JSON.stringify(p.reps),
		due: format(p.due, "yy-MM-dd HH:mm:ss"),
		repCount: p.reps.length,
	}));

	return (
		<Container sx={{paddingTop: theme.spacing(4), paddingBottom: theme.spacing(8)}}>
			{/* <Typography variant="h4" gutterBottom paragraph>Technical information</Typography> */}
			
			<Box sx={{marginBottom: 4}}><Button variant="contained" color="inherit" onClick={props.onClickExport}>Backup/Export</Button> <Button sx={{marginLeft: 2}} variant="contained" color="inherit" onClick={props.onClickDeleteTodaysLearningPackage}>Heutigen Plan löschen</Button></Box>
			
			<Typography variant="h5" gutterBottom paragraph>User & Sync</Typography>
			<Typography paragraph>Current user: {props.currentUserId}</Typography>
			<Box sx={{marginBottom: 4}}><Button variant="contained" color="inherit" onClick={props.onClickSync}>Server Sync</Button> </Box>

			<Grid container spacing={4} sx={{marginBottom: 2}}>
				<Grid item xs={12}>
					{Object.entries(props.values).map(([label, value]) => <>
							<Typography key={label} variant="h6">{label}</Typography>
							<Typography key={label} variant="body1" sx={{whiteSpace:"pre"}}>{value}</Typography>
						</>
					)}
				</Grid>
			</Grid>
			<Typography variant="h5" gutterBottom paragraph>Kitem Progress</Typography>

			<DataGrid
				rows={rows}
				columns={columns}
				initialState={{
				pagination: {
					paginationModel: { page: 0, pageSize: 20 },
				},
				}}
				pageSizeOptions={[5, 10]}
			/>

			<Typography variant="h5" sx={{marginTop: 5}}>Progression Standard</Typography>
			{props.progressionStandard.map((d, index) => 
				<Typography>{format(d, "yy-MM-dd HH:mm:ss")} &nbsp;&nbsp;&nbsp; {index > 0 ? formatDistance(d, props.progressionStandard[index-1]) : ""}</Typography>
			)}
			<Typography variant="h5" sx={{marginTop: 2}}>Progression Neu</Typography>
			{props.progressionNew.map((d, index) => 
				<Typography>{format(d, "yy-MM-dd HH:mm:ss")} &nbsp;&nbsp;&nbsp; {index > 0 ? formatDistance(d, props.progressionNew[index-1]) : ""}</Typography>
			)}

		</Container>
	);
}
