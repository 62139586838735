import { useEffect, useRef, useState } from "react";
import ImageChooserView from "../components/ImageChooserView";
import { playSound } from "../services/sound-player";
import logger from "../services/logger";
import { Kitem, KitemType } from "../contexts/LearningContext";
import assert from "assert";
import sample from "lodash.sample";
import shuffle from 'lodash.shuffle';
import ImageChooserEvaluation from "../components/ImageChooserEvaluation";
import ImageChooserQuadView from "../components/ImageChooserQuadView";

export type ImageChooserKitem = Kitem & {
	type: KitemType.ImageChooser,
	setId: string,
	targetId: string,
	variant?: "default" | "first-words",
};

export function isImageChooserKitem(item: Kitem): item is ImageChooserKitem {
	return (
		(item as ImageChooserKitem).type !== undefined
		&& (item as ImageChooserKitem).type === KitemType.ImageChooser
		&& (item as ImageChooserKitem).setId !== undefined
		&& (item as ImageChooserKitem).targetId !== undefined
	);
}

interface ImageChooserControllerProps {
	kitem: ImageChooserKitem,
	easiness: number,
	onSuccess(): void,
	onFail(): void,
	onNext(): void,
	selfEvalMode: boolean,
}

export type ImageSetItem = {
	id: string,
	imageUrl: string,
	imageContain?: boolean,
	audioUrl: string,
};
  
type ImageSets = {
	[category: string]: ImageSetItem[];
};

const imageSets: ImageSets = {
	"personen": [
		{id: "mama", imageUrl: "https://hydrogen-assets.kreativrauschen.de/privat/helen.jpg", audioUrl: require("../assets/lesen/mama.mp3")},
		{id: "papa", imageUrl: "https://hydrogen-assets.kreativrauschen.de/privat/florian.jpg", audioUrl: require("../assets/lesen/papa.mp3")},
		{id: "janosch", imageUrl: "https://hydrogen-assets.kreativrauschen.de/privat/janosch.jpg", audioUrl: require("../assets/erste-worte/janosch.m4a")},
		{id: "gisela-sander", imageUrl: "https://hydrogen-assets.kreativrauschen.de/privat/gisela-sander.jpg", audioUrl: require("../assets/lesen/oma.mp3")},
		{id: "gisela-sommer", imageUrl: "https://hydrogen-assets.kreativrauschen.de/privat/gisela-sommer.jpg", audioUrl: require("../assets/lesen/oma.mp3")},
	],
	"tiere": [
		{id: "hund", imageUrl: require("../assets/erste-worte/hund.jpg"), audioUrl: require("../assets/erste-worte/hund.m4a")},
		{id: "katze", imageUrl: require("../assets/erste-worte/katze.jpg"), audioUrl: require("../assets/erste-worte/katze.m4a")},
		{id: "schaf", imageUrl: require("../assets/erste-worte/schaf.jpg"), audioUrl: require("../assets/erste-worte/schaf.m4a")},
		{id: "pferd", imageUrl: require("../assets/erste-worte/pferd.jpg"), audioUrl: require("../assets/erste-worte/pferd.m4a")},
		{id: "maus", imageUrl: require("../assets/erste-worte/maus.jpg"), audioUrl: require("../assets/erste-worte/maus.webm")},
	],
	"englisch": [
		{id: "dog", imageUrl: require("../assets/erste-worte/hund.jpg"), audioUrl: require("../assets/englisch/dog.m4a")},
		{id: "cat", imageUrl: require("../assets/erste-worte/katze.jpg"), audioUrl: require("../assets/englisch/cat.m4a")},
		{id: "sheep", imageUrl: require("../assets/erste-worte/schaf.jpg"), audioUrl: require("../assets/englisch/sheep.m4a")},
		{id: "car", imageUrl: require("../assets/erste-worte/auto.jpg"), audioUrl: require("../assets/englisch/car.m4a")},
		{id: "bicycle", imageUrl: require("../assets/erste-worte/fahrrad.jpg"), audioUrl: require("../assets/englisch/bicycle.m4a")},
		{id: "cow", imageUrl: require("../assets/erste-worte/kuh.jpg"), audioUrl: require("../assets/englisch/cow.m4a")},
		{id: "pig", imageUrl: require("../assets/erste-worte/schwein.jpg"), audioUrl: require("../assets/englisch/pig.m4a")},
		{id: "chair", imageUrl: require("../assets/erste-worte/stuhl.jpg"), audioUrl: require("../assets/englisch/chair.m4a")},
		{id: "dragon", imageUrl: require("../assets/lesen/drache.jpg"), audioUrl: require("../assets/englisch/dragon.m4a")},
		{id: "excavator", imageUrl: require("../assets/lesen/bagger.jpg"), audioUrl: require("../assets/englisch/excavator.m4a")},
		{id: "hedgehog", imageUrl: require("../assets/lesen/igel.jpg"), audioUrl: require("../assets/englisch/hedgehog.m4a")},
		{id: "house", imageUrl: require("../assets/lesen/haus.jpg"), audioUrl: require("../assets/englisch/house.m4a")},
		{id: "monkey", imageUrl: require("../assets/lesen/affe.jpg"), audioUrl: require("../assets/englisch/monkey.m4a")},
		{id: "mouse", imageUrl: require("../assets/lesen/maus.jpg"), audioUrl: require("../assets/englisch/mouse.m4a")},
		{id: "pirate", imageUrl: require("../assets/lesen/pirat.jpg"), audioUrl: require("../assets/englisch/pirate.m4a")},
		{id: "strawberry", imageUrl: require("../assets/lesen/erdbeere.jpg"), audioUrl: require("../assets/englisch/strawberry.m4a")},
		{id: "sun", imageUrl: require("../assets/lesen/sonne.jpg"), audioUrl: require("../assets/englisch/sun.m4a")},
	],
	"zahlenmengen": [
		{id: "10", imageUrl: require("../assets/zahlen/set-10.svg").default, imageContain: true, audioUrl: require("../assets/zahlen/10-punkte.m4a")},
		{id: "100", imageUrl: require("../assets/zahlen/set-100.svg").default, imageContain: true,  audioUrl: require("../assets/zahlen/100-punkte.m4a")},
		{id: "1000", imageUrl: require("../assets/zahlen/set-1000.svg").default, imageContain: true, audioUrl: require("../assets/zahlen/1000-punkte.m4a")},
		{id: "10000", imageUrl: require("../assets/zahlen/set-10000.svg").default, imageContain: true, audioUrl: require("../assets/zahlen/10000-punkte.m4a")},
		{id: "20000", imageUrl: require("../assets/zahlen/set-20000.png"), imageContain: true, audioUrl: require("../assets/zahlen/20000-punkte.m4a")},
	],
}

export default function ImageChooserController(props: ImageChooserControllerProps) {
	const correctUpRef = useRef(Math.random() >= 0.5 ? true : false);
	const failed = useRef(false);
	const successHandled = useRef(false);
	const [focus, setFocus] = useState<false|"first"|"second">(false);
	const [showEvaluatuion, setShowEvaluation] = useState(false);

	const imageSet = imageSets[props.kitem.setId];
	assert(imageSet !== undefined, "Failed to find image set "+props.kitem.setId);

	const mark = imageSet.find(i => i.id === props.kitem.targetId);
	assert(mark, "Failed to find target image in image set: "+props.kitem.targetId)
	const decoyRef = useRef(sample(imageSet.filter(i => i.id !== props.kitem.targetId)));
	const decoy = decoyRef.current;
	assert(decoy, "Failed to find a decoy");
	const correctUp = correctUpRef.current;
	const up = correctUp ? mark : decoy;
	const down = !correctUp ? mark : decoy;
	logger.debug("ImageChooserController", correctUp, up, down);
	const [quadFocus, setQuadFocus] = useState<null|number>(null);
	const quadImagesRef = useRef((() => {
		const quad: ImageSetItem[] = [mark];
		for (let i = 0; i < 3; i++) {
			const candidates = imageSet.filter(item => item.id !== props.kitem.targetId && !quad.some(candidateItem => candidateItem.id === item.id));
			const candidate = sample(candidates);
			if (!candidate)
				throw new Error("Failed to find decoy. Is the imageset large enough?");
			quad.push(candidate);
		}

		return shuffle(quad); 
	})());
	const easiness = useRef(props.easiness);
	

	useEffect(() => {
		logger.debug("ImageChooserController: Initializing mark audio");
		
		// Play target sound initially. But only if focus is false, because otherwise we might just be in a re-triggering of the effect due to a dependency
		if (focus === false)
			playSound({ audioUrl: mark.audioUrl });
		
		// const interval = setInterval(() => {
		// 	// if it is still unsolved, play the sound again
		// 	if (focus === false)
		// 		playSound({ audioUrl: mark.audioUrl });
		// }, 10000); // 10000 milliseconds = 10 seconds

		// // Clear the interval when the component unmounts to prevent memory leaks.
		// return () => {
		// 	clearInterval(interval);
		// };
	}, [focus, mark.audioUrl]);

	const handleFail = () => {
		logger.debug("ImageChooserController:handleFail");
		if (!failed.current) {
			failed.current = true;
			logger.info("Wrong answer tapped");
			// register fail if not in self eval mode. When in self eval mode, user will explicitly register fails via eval button click.
			if (!props.selfEvalMode) {
				props.onFail();
				if (props.kitem.variant !== "first-words")
					playSound({audioUrl: require('../assets/och-noe.m4a')}, false);
			}
		}
	}

	const handleSuccess = () => {
		logger.debug("ImageChooserController:handleSuccess");
		if (!successHandled.current) {
			successHandled.current = true;

			logger.info("Right answer found.");
			if (!props.selfEvalMode) {
				if (!failed.current) {
					props.onSuccess();
				}
				const postConfirmation =  () => {
					playSound({audioUrl: mark.audioUrl}, false, () => {
						setTimeout(() => {
							logger.debug("Right answer found, moving on...");
							props.onNext();
						}, 1500);
					});
				};
				if (props.kitem.variant !== "first-words")
					playSound({audioUrl: require('../assets/jaaa.m4a')}, false, postConfirmation);
				else
					postConfirmation();
			} else {
				setShowEvaluation(true);
				const postConfirmation = () => {
					playSound({audioUrl: mark.audioUrl}, false);
				};
				if (props.kitem.variant !== "first-words")
					playSound({audioUrl: require('../assets/jaaa.m4a')}, false, postConfirmation);
				else
					postConfirmation();
			}
		}
	}
	
	const handleFirstImageClick = () => {
		if (correctUp) {
			setFocus("first");
			handleSuccess();
		} else {
			handleFail();
		}
	};
	const handleSecondImageClick = () => {
		if (!correctUp) {
			setFocus("second");
			handleSuccess();
		} else {
			handleFail();
		}
	};

	const handleEvalFail = () => {
		logger.debug("ImageChooserController: Sending fail event");
		props.onFail();
	}

	const handleEvalSuccess = () => {
		logger.debug("ImageChooserController: Sending success event");
		props.onSuccess();
	}

	const handleQuadClick = (index: number) => {
		logger.debug("Image "+index+" clicked.");
		if (quadImagesRef.current[index].id === mark.id) {
			setQuadFocus(index);
			handleSuccess();
		} else {
			handleFail();
		}
	}
	
	return <>
		{easiness.current < 1 ?
			<ImageChooserView
				firstImage={up.imageUrl}
				firstImageContain={up.imageContain}
				onFirstImageClick={handleFirstImageClick}
				secondImage={down.imageUrl}
				secondImageContain={down.imageContain}
				onSecondImageClick={handleSecondImageClick}
				audioUrl={mark.audioUrl}
				focus={focus}
			/>
		:
			<ImageChooserQuadView
				images={quadImagesRef.current}
				onImageClick={handleQuadClick}
				audioUrl={mark.audioUrl}
				focus={quadFocus}
			/>
		}
		<ImageChooserEvaluation
			show={showEvaluatuion}
			onClickFail={handleEvalFail}
			onClickSuccess={handleEvalSuccess}
		/>
	</>;
}
