import React, { useEffect, useRef, useState } from 'react';
import UserManagerContext, { UserManagerInterface } from './UserManagerContext';
import logger from '../services/logger';
import { IDBPDatabase, openDB } from 'idb';
import assert from 'assert';
import { v4 as uuidv4 } from 'uuid';
import LoadingView from '../components/LoadingView';



interface Props {
	children: React.ReactNode,
}
export default function UserManagerProvider(props: Props) {
	const dbRef = useRef<IDBPDatabase<unknown>|null>(null);
	const [currentUserId, setCurrentUserId] = useState<string|undefined>(undefined)

	const dbLoadingInitiated = useRef(false);
	useEffect(() => {
		if (!dbLoadingInitiated.current) {
			logger.info("Loading data from indexedDB");
			dbLoadingInitiated.current = true;
			// dbRef.current = 
			openDB("hydrogen-users", 1, {
				upgrade(db, oldVer, newVer) {
	
					if (oldVer < 1) {
						db.createObjectStore('misc');
					}
	
				}
			}).then(db => {
				dbRef.current = db;
				db.get("misc", "currentUserId").then(dbCurrentUserId => {
					if (dbCurrentUserId) {
						assert(typeof dbCurrentUserId === "string");
						assert(dbCurrentUserId !== "");
						setCurrentUserId(dbCurrentUserId);
						logger.info("User id loaded: "+dbCurrentUserId);
					} else {
						// initiate by creating new random user is
						const newUserId = uuidv4();
						logger.info("No user id found, created new one: "+newUserId);
						db.put("misc", newUserId, "currentUserId");
						setCurrentUserId(newUserId);
					}
				});
			})
		} else {
			logger.error("Tried to initiate loading data from db, but was already initiated.");
		}
	}, []);

	const setUserId = (newUserId: string) => {
		assert(currentUserId !== undefined, "User manager should be initialized before a new user id is set");
		assert(dbRef.current, "User database should be initialized");
		if (newUserId === "")
			throw new Error("Tried to set empty user id. Not allowed.")
		dbRef.current.put("misc", newUserId, "currentUserId");
		setCurrentUserId(newUserId);
	}

	if (currentUserId === undefined)
		return <LoadingView what='User manager'/>;

	const contextValue: UserManagerInterface = {
		currentUserId: currentUserId,
		setUserId: setUserId,
	}

	return (
		<UserManagerContext.Provider value={contextValue}>
			{props.children}
		</UserManagerContext.Provider>
	);
}
