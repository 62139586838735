import { Container, useTheme } from '@mui/material';
import { Topic } from '../contexts/LearningContext';
import { TopicState } from './DashboardView';
import TopicCard from './TopicCard';
import { TopicStatus } from '../services/useTopics';
import TopicCardParents from './TopicCardParents';

export interface ParentsTopicsViewProps {
	topics: (Topic & TopicState & {status: TopicStatus})[],
	onTopicClicked(topicId: string): void,
}

export default function ParentsTopicsView(props: ParentsTopicsViewProps) {
	const theme = useTheme();


	return <>
		<Container maxWidth="sm" sx={{marginTop: theme.spacing(2)}}>

			{props.topics.map(topic => 
				<TopicCardParents
					key={topic.id}
					title={topic.title}
					disabled={topic.status === "inactive"}
					onClick={() => {props.onTopicClicked(topic.id)}}
					size={topic.size}
					exposure={topic.exposure}
					known={topic.known}
				/>
			)}

		</Container>
	</>;
}
