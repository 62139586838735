import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";



export const PlanetenKitems: SMCKitem[] = [
	{
		id: "planeten_erde",
		type: KitemType.SMC,
		topic: "planeten",
		question: {
			imageUrl: require('../assets/planeten/erde.jpg'),
			alt: "Ein Bild der Erde",
		},
		answers: [
			{
				audioUrl: require('../assets/planeten/erde.m4a'),
				transcript: "Die Erde",
				correct: true,
			},
			{
				audioUrl: require('../assets/planeten/venus.m4a'),
				transcript: "Die Venus",
				correct: false,
			},
			{
				audioUrl: require('../assets/planeten/mars.m4a'),
				transcript: "Der Mars",
				correct: false,
			},
		],
	},
	{
		id: "planeten_mars",
		type: KitemType.SMC,
		topic: "planeten",
		question: {
			imageUrl: require('../assets/planeten/mars.jpg'),
			alt: "Ein Bild des Mars",
		},
		answers: [
			{
				audioUrl: require('../assets/planeten/mars.m4a'),
				transcript: "Der Mars",
				correct: true,
			},
			{
				audioUrl: require('../assets/planeten/venus.m4a'),
				transcript: "Die Venus",
				correct: false,
			},
			{
				audioUrl: require('../assets/planeten/neptun.m4a'),
				transcript: "Der Neptun",
				correct: false,
			},
		],
	},
	{
		id: "planeten_saturn",
		type: KitemType.SMC,
		topic: "planeten",
		question: {
			imageUrl: require('../assets/planeten/saturn.jpg'),
			alt: "Ein Bild des Saturn",
		},
		answers: [
			{
				audioUrl: require('../assets/planeten/saturn.m4a'),
				transcript: "Der Saturn",
				correct: true,
			},
			{
				audioUrl: require('../assets/planeten/venus.m4a'),
				transcript: "Die Venus",
				correct: false,
			},
			{
				audioUrl: require('../assets/planeten/mars.m4a'),
				transcript: "Der Mars",
				correct: false,
			},
		],
	},
	{
		id: "planeten_jupiter",
		type: KitemType.SMC,
		topic: "planeten",
		question: {
			imageUrl: require('../assets/planeten/jupiter.jpg'),
			alt: "Ein Bild des Jupiter",
		},
		answers: [
			{
				audioUrl: require('../assets/planeten/jupiter.m4a'),
				transcript: "Der Jupiter",
				correct: true,
			},
			{
				audioUrl: require('../assets/planeten/saturn.m4a'),
				transcript: "Der Saturn",
				correct: false,
			},
			{
				audioUrl: require('../assets/planeten/mars.m4a'),
				transcript: "Der Mars",
				correct: false,
			},
		],
	},
	{
		id: "planeten_neptun",
		type: KitemType.SMC,
		topic: "planeten",
		question: {
			imageUrl: require('../assets/planeten/neptun.jpg'),
			alt: "Ein Bild des Neptun",
		},
		answers: [
			{
				audioUrl: require('../assets/planeten/neptun.m4a'),
				transcript: "Der Neptun",
				correct: true,
			},
			{
				audioUrl: require('../assets/planeten/merkur.m4a'),
				transcript: "Der Merkur",
				correct: false,
			},
			{
				audioUrl: require('../assets/planeten/venus.m4a'),
				transcript: "Die Venus",
				correct: false,
			},
		],
	},
	{
		id: "planeten_mond",
		type: KitemType.SMC,
		topic: "planeten",
		question: {
			imageUrl: require('../assets/planeten/mond.jpg'),
			alt: "Ein Bild vom Mond",
		},
		answers: [
			{
				audioUrl: require('../assets/planeten/mond.m4a'),
				transcript: "Der Mond",
				correct: true,
			},
			{
				audioUrl: require('../assets/planeten/merkur.m4a'),
				transcript: "Der Merkur",
				correct: false,
			},
			{
				audioUrl: require('../assets/planeten/venus.m4a'),
				transcript: "Die Venus",
				correct: false,
			},
		],
	},
	// {
	// 	id: "planeten_merkur",
	// 	type: KitemType.SMC,
	// 	topic: "planeten",
	// 	question: {
	// 		imageUrl: require('../assets/planeten/merkur.jpg'),
	// 		alt: "Ein Bild des Merkur",
	// 	},
	// 	answers: [
	// 		{
	// 			audioUrl: require('../assets/planeten/merkur.m4a'),
	// 			transcript: "Der Merkur",
	// 			correct: true,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/planeten/neptun.m4a'),
	// 			transcript: "Der Neptun",
	// 			correct: false,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/planeten/mars.m4a'),
	// 			transcript: "Die Venus",
	// 			correct: false,
	// 		},
	// 	],
	// },
	// {
	// 	id: "planeten_venus",
	// 	type: KitemType.SMC,
	// 	topic: "planeten",
	// 	question: {
	// 		imageUrl: require('../assets/planeten/venus.jpg'),
	// 		alt: "Ein Bild der Venus",
	// 	},
	// 	answers: [
	// 		{
	// 			audioUrl: require('../assets/planeten/venus.m4a'),
	// 			transcript: "Die Venus",
	// 			correct: true,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/planeten/saturn.m4a'),
	// 			transcript: "Der Saturn",
	// 			correct: false,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/planeten/mars.m4a'),
	// 			transcript: "Der Mars",
	// 			correct: false,
	// 		},
	// 	],
	// },
	// {
	// 	id: "planeten_uranus",
	// 	type: KitemType.SMC,
	// 	topic: "planeten",
	// 	question: {
	// 		imageUrl: require('../assets/planeten/uranus.jpg'),
	// 		alt: "Ein Bild des Uranus",
	// 	},
	// 	answers: [
	// 		{
	// 			audioUrl: require('../assets/planeten/uranus.m4a'),
	// 			transcript: "Der Uranus",
	// 			correct: true,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/planeten/venus.m4a'),
	// 			transcript: "Die Venus",
	// 			correct: false,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/planeten/mars.m4a'),
	// 			transcript: "Der Mars",
	// 			correct: false,
	// 		},
	// 	],
	// },

];