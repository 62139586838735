import React from 'react';
import { Container, useTheme, Fab, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import { isSMCKitem } from '../controller/SMCController';
import { DisplayText } from '../App';
import { SpeechSynth, AudioUrl, isAudioUrl } from '../services/sound-player';
import AudioButton from './AudioButton';
import { getText } from '../services/textTools';
import { isRocketKitem } from '../controller/RocketGameController';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { KitemWithPauseState } from '../controller/KitemListViewController';
import { isWrittenAnswerKitem } from '../controller/WrittenAnswerController';
import { Kitem, KitemType } from '../contexts/LearningContext';
import { isImageChooserKitem } from '../controller/ImageChooserController';

export interface KitemListViewProps {
	// values: Record<string, string>,
	// curriculum: KitemProgress[],
	onDelete(id: string): void,
	onEdit(id: string): void,
	onDownload(id: string): void,
	onPreview(id: string): void,
	onPause(id: string): void,
	onContinue(id: string): void,
	onAdd(): void,
	onBack(): void,
	kitems: KitemWithPauseState[],
}

export default function KitemListView(props: KitemListViewProps) {
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [menuItemId, setMenuItemId] = React.useState<string>("");
	const [menuItemTopic, setMenuItemTopic] = React.useState<string>("");
	const [menuItemPaused, setMenuItemPaused] = React.useState<boolean>(false);

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string, topic: string, isPaused: boolean) => {
		setMenuItemTopic(topic);
		setMenuItemPaused(isPaused);
		setMenuItemId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setMenuItemId("");
		setMenuItemTopic("");
		setMenuItemPaused(false);
	};

	const columns: GridColDef[] = [
		{ 
			field: 'question',
			headerName: 'Frage',
			flex: 1,
			renderCell: (params: GridRenderCellParams<SpeechSynth | AudioUrl | DisplayText>) => {
				// @ts-ignore
				const isPaused = params.row.isPaused;
				// @ts-ignore
				const icon = params.row.icon;
				return <>
					<span style={{marginRight: 8}}>{icon}</span>
					{/* {JSON.stringify(params.value)} */}
					{/* {isSpeechSynth(params.value) && params.value.synthText} */}
					{isAudioUrl(params.value) && <>
						<AudioButton audio={params.value}/>
					</>}
					{/* {isDisplayText(params.value) && params.value.displayText}
					{isDisplayImage(params.value) && params.value.alt} */}
					
					<Typography sx={isPaused ? {textDecoration: "line-through"} : undefined} variant='body2'>
						{getText(params.value)}
					</Typography>
				</>;
			}
		},
		{ 
			field: 'topic',
			headerName: 'Thema',
			width: 100,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: (params) => {
				const actions = [
					<GridActionsCellItem
						icon={<PreviewIcon />}
						label="Preview"
						className="textPrimary"
						onClick={() => props.onPreview(params.row.id)}
						color="inherit"
					/>,
					<GridActionsCellItem
						icon={<MoreVertIcon />}
						label="More"
						className="textPrimary"
						onClick={(event) => {
							handleMenuOpen(event, params.row.id, params.row.topic, params.row.isPaused);
						}}
						color="inherit"
					/>,
				];
				return actions;
			},
		  },
	];

	const getKitemIcon = (kitem: Kitem) => {
		switch (kitem.type) {
			case KitemType.SMC:
				return "📇";
			case KitemType.WrittenAnswer:
				return "⌨️";
			case KitemType.ImageChooser:
				return "🖼️";
			case KitemType.Rockets:
				return "🚀";
		}
		return "";
	}

	const rows = props.kitems.map(k => Object.assign({}, k, {
		kitemId: k.id,
		topic: k.topic,
		question: isSMCKitem(k) ? k.question : isRocketKitem(k) ? {displayText: k.targetId} : isWrittenAnswerKitem(k) ? k.question : isImageChooserKitem(k) ? {displayText: k.targetId} : {displayText: "?"},
		icon: getKitemIcon(k),
	}));

	return <>
			
		<Container sx={{paddingTop: theme.spacing(2), paddingBottom: theme.spacing(8)}}>
			{/* <Typography variant="h4" gutterBottom paragraph><IconButton size="large" onClick={props.onBack} sx={{marginLeft: -2, marginRight: 1, marginTop: "-4px"}}><ArrowBackIcon/></IconButton>Knowledge Items</Typography> */}


			<DataGrid
				sx={{backgroundColor: theme.palette.background.paper}}
				rows={rows}
				columns={columns}
				initialState={{
				pagination: {
					paginationModel: { page: 0, pageSize: 100 },
				},
				}}
				pageSizeOptions={[25,50,100]}
			/>

			<Fab 
				sx={{
					// position: "sticky",
					// bottom: 0,
					// right: 0,
					position: 'fixed',
					bottom: theme.spacing(2),
					right: theme.spacing(2),
				}}
				aria-label="add"
				color="primary"
				onClick={props.onAdd}
				>
				<AddIcon />
			</Fab>

			<Menu
				anchorEl={anchorEl}
				open={anchorEl !== null}
				onClose={handleMenuClose}
				>
				{menuItemPaused &&
					<MenuItem key="pause" onClick={() => { handleMenuClose(); props.onContinue(menuItemId); }}>
						<ListItemIcon><PlayArrowIcon /></ListItemIcon>
						<ListItemText>Continue</ListItemText>
					</MenuItem>
				}
				{!menuItemPaused &&
					<MenuItem key="pause" onClick={() => { handleMenuClose(); props.onPause(menuItemId); }}>
						<ListItemIcon><PauseIcon /></ListItemIcon>
						<ListItemText>Pause</ListItemText>
					</MenuItem>
				}
				{menuItemTopic === "misc" && [
					<MenuItem key="edit" onClick={() => { handleMenuClose(); props.onEdit(menuItemId); }}>
						<ListItemIcon><EditIcon /></ListItemIcon>
						<ListItemText>Edit</ListItemText>
					</MenuItem>,
					<MenuItem key="download" onClick={() => { handleMenuClose(); props.onDownload(menuItemId); }}>
						<ListItemIcon><DownloadIcon /></ListItemIcon>
						<ListItemText>Download</ListItemText>
					</MenuItem>,
					<MenuItem key="delete" onClick={() => { handleMenuClose(); props.onDelete(menuItemId); }}>
						<ListItemIcon><DeleteIcon /></ListItemIcon>
						<ListItemText>Delete</ListItemText>
					</MenuItem>
				]}
			</Menu>

		</Container>
	</>;
}
