/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { Box, Button, Container, Grid, TextField, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import { isSpeechSynth, SpeechSynthText } from "./SpeechSynth";
import { AudioUrl, isAudioUrl } from "../services/sound-player";
import AudioButton from "./AudioButton";
import { DisplayImage, DisplayText, isDisplayImage, isDisplayText } from "../App";
import MarkdownQuestion from "./MarkdownQuestion";
import Backdrop from '@mui/material/Backdrop';
import RecommendIcon from '@mui/icons-material/Recommend';

export interface WrittenAnswerViewProps {
	question: DisplayText | SpeechSynthText | AudioUrl | DisplayImage,
	hideQuestionText?: boolean,
	answer: string,
	solution: string,
	onChangeAnswer(value: string): void,
	onEnter(): void,
	onNext(): void,
	enterable: boolean,
	editable: boolean,
	showRight: boolean,
	showWrong: boolean,
}

export default function WrittenAnswerView(props: WrittenAnswerViewProps) {
	const theme = useTheme();
	
	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			props.onEnter();
			// Prevent default action to avoid any unwanted behavior (like form submission)
			event.preventDefault();
		}
	}
	
	return <>
	<Container>
		<Grid container
			direction="column"
			justifyContent="space-around"
			alignItems="center"
  			>
			<Grid item>
				{isDisplayImage(props.question) ?
					<Box 
						marginTop={4}
						marginBottom={0}
						sx={{
							textAlign: "center",
							}}
						>
						<img 
							src={props.question.imageUrl}
							alt={props.question.alt}
							style={{
								maxWidth: "100%",
								maxHeight: "30vh",
								boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
							}}
						/>
					</Box>
				:
					<Box 
						marginTop={8}
						marginBottom={6}
						sx={{textAlign: "center"}}
						>
							{isDisplayText(props.question) && <MarkdownQuestion text={props.question.displayText} sx={props.question.sx} markdownStyle={props.question.markdownStyle}/>}
							{(isAudioUrl(props.question) || isSpeechSynth(props.question)) && <AudioButton audio={props.question} size="xxl" autoPlay/>}
							{/* {isAudioUrl(props.question) && props.question.transcript <>
									<Typography variant="body2" sx={{marginTop: 2}}>{props.question.transcript}</Typography>
							</>} */}
					</Box>
				}
			</Grid>
			<Grid item marginTop={4}>
					<Box>
						<TextField
							variant="outlined"
							type="number"
							focused
							onChange={(e) => props.onChangeAnswer(e.target.value)}
							onKeyDown={handleKeyPress}
							sx={{color: "red"}}
						/>
						<Button
							variant="contained"
							sx={{height: 56, fontSize: 40, paddingTop: "3px", marginLeft: 1}}
							size="large"
							disabled={!props.enterable}
							onClick={props.onEnter}
							>
								&raquo;
						</Button>
					</Box>
			</Grid>
		</Grid>
		{/* <Drawer
			anchor={"bottom"}
			open={props.showRight}
			hideBackdrop
			PaperProps={{sx: {backgroundColor: "#94D196"}}}
			>
				<Stack direction="row" sx={{padding: 3}}>
					<Box display="inline-block" marginTop={-2} paddingTop={3} marginBottom={0} paddingBottom={0}><ThumbUpIcon color="success" fontSize="large" sx={{ fontSize: 40, marginRight: 2}}/></Box>
					<Button variant="contained" size="large" sx={{fontSize: 20, width:"100%"}} color="success" onClick={props.onNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
				</Stack>
		</Drawer>
		<Drawer
			anchor={"bottom"}
			open={props.showWrong}
			hideBackdrop
			PaperProps={{sx: {backgroundColor: "hsl(1 83% 93% / 1)"}}}
			>
				<Stack direction="row" sx={{padding: 3}}>
					<Box display="inline-block" marginTop={-2} paddingTop={3} marginBottom={0} paddingBottom={0}><LightbulbCircleIcon color="error" fontSize="large" sx={{ fontSize: 40, marginRight: 2}}/></Box>
					<Button variant="contained" size="large" sx={{fontSize: 20, width:"100%"}} color="error" onClick={props.onNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
				</Stack>
		</Drawer> */}
		<Backdrop
			open={props.showRight}
		>
			<Paper elevation={8} sx={{
				padding: 4,
				backgroundColor: "#94D196",
				boxShadow: "0px 5px 5px -3px rgba(27,94,32,0.2), 0px 8px 10px 1px rgba(27,94,32,0.14), 0px 3px 14px 2px rgba(27,94,32,0.12)",
				borderColor: theme.palette.success.main,
				width: 256,
				}}
				>
				<Grid container
					direction="column"
					justifyContent="space-around"
					alignItems="center"
					sx={{height: "100%", minHeight: "50vh"}}
					>
					<Grid item marginBottom={4}>
						<RecommendIcon fontSize="large" sx={{marginBottom: 2, fontSize: 60}}/>
						<Typography variant="h3" sx={{textDecoration: theme.palette.success.light+" solid underline"}}>{props.solution}</Typography>
					</Grid>
					<Grid item><Box 
					>
						<Button variant="contained" size="large" sx={{fontSize: 20}} color="success" onClick={props.onNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
					</Box>
					</Grid>
				</Grid>
			</Paper>
		</Backdrop>

		<Backdrop
			open={props.showWrong}
		>
			<Paper elevation={8} sx={{
				padding: 4,
				backgroundColor: "hsl(1 83% 93% / 1)",
				boxShadow: "0px 5px 5px -3px rgba(198,40,40,0.2), 0px 8px 10px 1px rgba(198,40,40,0.14), 0px 3px 14px 2px rgba(198,40,40,0.12)",
				borderColor: theme.palette.error.main,
				width: 256,
				}}
				>
				<Grid container
					direction="column"
					justifyContent="space-around"
					alignItems="center"
					sx={{height: "100%", minHeight: "50vh"}}
					>
					<Grid item marginBottom={4} textAlign="center">
						<Typography variant="h5" sx={{textDecoration: theme.palette.error.light+" dotted line-through", marginBottom: 2}}>{props.answer}</Typography>
						<Typography variant="h3" sx={{textDecoration: theme.palette.success.light+" solid underline"}}>{props.solution}</Typography>
					</Grid>
					<Grid item>
						<Button variant="contained" size="large" sx={{fontSize: 20}} color="error" onClick={props.onNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
					</Grid>
				</Grid>
			</Paper>
		</Backdrop>
	</Container>
	</>;
}