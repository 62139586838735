import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Confetti from 'react-confetti';
import { Box, Button, DialogActions, DialogTitle, IconButton } from '@mui/material';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import QueueIcon from '@mui/icons-material/Queue';
import AudioButton from './AudioButton';

export interface PlainTextQuestion {
	question: string,
	answer: string,
}

interface LearningSessionDoneDialogProps {
	onClickIndex(): void,
	normalQuestions: PlainTextQuestion[],
	hardQuestions: PlainTextQuestion[],
	onClickStory(): void,
	storyLoading: boolean,
	onClickLearnMore(): void,
	extendable: boolean,
}

export default function LearningSessionDoneDialog(props: LearningSessionDoneDialogProps) {
	return <>
							<Confetti
							// width={80}
							// height={60}
						/>
		<Dialog open={true}>
				<DialogTitle>Super! Du hast alles gelöst!</DialogTitle>
				<DialogContent sx={{textAlign: "center"}}>
					{/* <SpeechSynth synthText="Super! Du hast alles gelöst!"/><br/> */}
					<AudioButton audio={{audioUrl: require('../assets/super-alles-geschafft.m4a')}} autoPlay size="xxl"/><br/>
					<Button onClick={props.onClickIndex} variant="contained" color="secondary" sx={{marginTop: 2, width: 200}} startIcon={<ViewAgendaIcon/>}>Übersicht</Button><br/>
					<Button onClick={props.onClickLearnMore} variant="contained" color="inherit" sx={{marginTop: 2, width: 200}} startIcon={<QueueIcon/>} disabled={!props.extendable}>Mehr lernen</Button>
					<Box marginTop={2}><IconButton size='large' color="primary" onClick={props.onClickStory} disabled={props.storyLoading}><AutoStoriesIcon/></IconButton></Box>
					
					{/* <Box sx={{textAlign: "left", marginTop: 2}}><Typography variant="caption">Ich bin gerade mit meinem vierjährigen Sohn seine Lernkarten durchgegangen. Bitte schreibe eine kurze Geschichte, die die Inhalte der Lernkarten aufgreift. In der Geschichte sollte es jedoch nicht um Lernkarten gehen. Falls es Sinn macht kannst Du Charaktere aus Paw Patrol, Peppa Wutz, PJ Mask Super Wings oder Oktonauten einfließen lassen.<br/>
<br/>
Dies sind die Karten, die wir gelernt haben:<br/>
<br/>
{props.normalQuestions.map(q => <>
	Frage: {q.question}<br/>
	Antwort: {q.answer}<br/>
	<br/>
</>)}

{props.normalQuestions.length > 0 && props.hardQuestions.length > 0 && <>Die folgende Karten mussten wir wiederholen, sie sollten eine besondere Rolle in der Geschichte spielen:<br/></>}
<br/>
{props.hardQuestions.map(q => <>
	Frage: {q.question}<br/>
	Antwort: {q.answer}<br/>
	<br/>
</>)}
</Typography></Box> */}
				</DialogContent>
				<DialogActions></DialogActions>
		</Dialog>
	</>;
}

