import { KitemProgress } from "../contexts/LearningContext";
import shuffle from 'lodash.shuffle';
import assert from 'assert';
import { Kitem } from "../App";
import logger from "./logger";
import { TopicStatus } from "./useTopics";


/**
 * Return a new list which contains the items alternating by topic. Otherwise the original order is preserved.
 * @param pitemList 
 * @returns 
 */
export const mixPitemsByTopic = (pitemList: KitemProgress[], getKitem: (kitemId: string) => Kitem | undefined): KitemProgress[] => {
	const topicWeights: Record<string, number> = {
		"lesen": 0.5,
		"planeten": 1,
		"traffic": 1.5,
		"soziales": 2,
	}
	if (pitemList.length === 0) return [];
	let topics: {id: string, weight: number}[] = [];
	pitemList.forEach(item => {
		const kitem = getKitem(item.kitemId);
		assert(kitem);
		assert(kitem.topic);
		if (kitem && !topics.some(t => t.id === kitem.topic)) {
			topics.push({
				id: kitem.topic,
				weight: topicWeights[kitem.topic] ?? 1,
			});
		}
	});
	topics = shuffle(topics);
	assert(topics.length > 0);
	const pitemsToProcess = [...pitemList];
	const newPitems: KitemProgress[] = [];
	let iteration = 0;
	while (pitemsToProcess.length > 0) {
		iteration++;
		assert(iteration < 10000); // catch infinite loops
		topics.forEach(topic => {
			const addItem = () => {
				const index = pitemsToProcess.findIndex(item => {
					const kitem = getKitem(item.kitemId);
					return kitem && kitem.topic === topic.id;
				});
			
				if (index !== -1) {
					newPitems.push(pitemsToProcess.splice(index, 1)[0]);
				}
			}

			// add items according to this topic's weight/probability
			// e.g.:
			// Weight 1 => 100% chance of going in
			// Weight 1.5 => 100% chance of going in + 50% chance of going in a second time
			// Weight 2 => 100% of going in once + 100% chance of going in a second time
			// Weight 2.5 => 100% chance of going in twice + 50% chance of going in a third time
		
			let definiteInstances = Math.floor(topic.weight);
			for (let i = 0; i < definiteInstances; i++)
				addItem();
			
			const probability = topic.weight % 1;
			if (Math.random() < probability)
				addItem();

		});
	}
	return newPitems;
}

/**
 * Takes normal items, currently learning items and new items and returns them in a combined array with reasonable distribution.
 * Insert learningItems[] and newItems[] in equal spacing between the existing items of learningPackage. newItems last.
 * @param normalItems 
 * @param learningItems 
 * @param newItems 
 * @returns distributed pitems
 */
export const distributeItems = (normalItems: KitemProgress[], learningItems: KitemProgress[], newItems: KitemProgress[]): KitemProgress[] => {
	logger.debug("distributeItems", normalItems, learningItems, newItems);
	const hardItems = [...learningItems, ...newItems];
	const normalItemsCopy = [...normalItems];

	const normalItemsPerHardItem = Math.max(1, normalItems.length / hardItems.length); // at least one or a hard item will be at the front
	console.log(normalItemsPerHardItem + "normal items per hard item");

	const pkg: KitemProgress[] = [];
	let spacingCounter = 0;

	while (hardItems.length > 0 || normalItemsCopy.length > 0) {
		spacingCounter++;
		if (normalItemsCopy.length > 0 && (spacingCounter <= normalItemsPerHardItem || hardItems.length === 0)) {
			const item = normalItemsCopy.shift();
			if (!item) throw new Error("Array unexpectedly empty");
			pkg.push(item);
		} else {
			const item = hardItems.shift();
			if (!item) throw new Error("Array unexpectedly empty");
			pkg.push(item);
			spacingCounter = 0;
		}
	}
	logger.debug("distributeItems result", pkg);
	return pkg;
};

export const pitemTopicIsLearning = (pitem: KitemProgress, getKitem: (kitemId: string) => Kitem | undefined, getTopicStatus: (topicId: string) => TopicStatus) => {
	const kitem = getKitem(pitem.kitemId);
	assert(kitem);
	const status = getTopicStatus(kitem.topic);
	return status === "learning";
}

export const pitemTopicIsInactive = (pitem: KitemProgress, getKitem: (kitemId: string) => Kitem | undefined, getTopicStatus: (topicId: string) => TopicStatus) => {
	const kitem = getKitem(pitem.kitemId);
	assert(kitem);
	const status = getTopicStatus(kitem.topic);
	return status === "inactive";
}