import React, {useRef, useState} from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purpleThemeOptions } from './purpleTheme';
import LearningSessionController from './controller/LearningSessionController';
import { AudioUrl, SpeechSynth } from './services/sound-player';
import logger from './services/logger';
import { KitemType } from './contexts/LearningContext';
import { AppBar, Button, IconButton, SxProps, Toolbar, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DebugViewController from './controller/DebugViewController';
import DashboardController from './controller/DashboardController';
import KitemListViewController from './controller/KitemListViewController';
import SettingsViewController from './controller/SettingsViewController';
import useParentCheck from './services/useParentCheck';
import ParentsMenu from './components/ParentsMenu';
import MenuIcon from '@mui/icons-material/Menu';
import ParentsTopicsViewController from './controller/ParentsTopicsViewController';
import RocketGameController from './controller/RocketGameController';
import ImageChooserController from './controller/ImageChooserController';
import WrittenAnswerController from './controller/WrittenAnswerController';
import { ZahlenTippenKitems } from './kitems/Kitems_Zahlen_tippen';
import SMCController, { isSMCKitem } from './controller/SMCController';
import { BuchstabenGrossKitems } from './kitems/Kitems_Buchstaben_gross';
import { MarkdownStyle } from './components/MarkdownQuestion';
import { EnglishKitems } from './kitems/Kitems_English';

enum View {
	dashboard,
	parents,
	debug,
	learningSession,
	settings,
	parentsTopics,
	rocketGame,
	imageChooser,
	writtenAnswer,
	SMC,
}

export interface Kitem {
	id: string,
	type: KitemType,
	topic: string,
}

export interface DisplayText {
	displayText: string,
	sx?: SxProps,
	markdownStyle?: MarkdownStyle,
}

export function isDisplayText(text: SpeechSynth | AudioUrl | DisplayText | DisplayImage): text is DisplayText {
	return (text as DisplayText).displayText !== undefined;
}

export interface DisplayImage {
	imageUrl: string,
	alt?: string,
}

export function isDisplayImage(text: SpeechSynth | AudioUrl | DisplayText | DisplayImage): text is DisplayImage {
	return (text as DisplayImage).imageUrl !== undefined;
}

function App() {
	const [view, setView] = useState(View.dashboard);
	const [topicId, setTopicId] = useState<string|null|undefined>(undefined);
	const [selfEvalMode, setSelfEvalMode] = useState(false);
	const [parentsMenuOpen, setParentsMenuOpen] = useState(false);
	const debugCounter = useRef(0);
	const parentCheck = useParentCheck();

	logger.info("topicId: "+topicId);

	const checkDebug = () => {
		if (debugCounter.current >= 10) {
			setView(View.debug);
			logger.info("Debug view unlocked");
		} else {
			debugCounter.current++;
			logger.debug("Debug counter", debugCounter.current);
		}
	}

	const handleSelfEvalModeChange = async () => {
		if (selfEvalMode) {
			setSelfEvalMode(false);
		} else if (await parentCheck()) {
			setSelfEvalMode(!selfEvalMode);
		} else {
			logger.info("Self eval mode not activated, failed parent check.");
		}
	}

	return <>
	<ThemeProvider theme={createTheme(purpleThemeOptions)}>
		<CssBaseline/>

			{view === View.SMC && isSMCKitem(BuchstabenGrossKitems[0]) && <>
				<SMCController kitem={BuchstabenGrossKitems[0]} intro={false} onSuccess={() => {}} onFail={() => {}} onNext={() => {}}/>
			</>}
			{view === View.imageChooser && <>
				<ImageChooserController
					// @ts-ignore
					kitem={EnglishKitems[0]}
					easiness={1}
					onFail={() => {}}
					onSuccess={() => {}}
					onNext={() => {}}
					selfEvalMode={false}
				/>
			</>}
			{view === View.rocketGame && <>
				<RocketGameController targetId="A" setId="buchstaben_gross" maxSeconds={30} maxErrors={2} requiredSuccesses={4} speed={0.75} onFail={() => {}} onNext={() => {}} onSuccess={() => {}}/>
			</>}
			{view === View.writtenAnswer && <>
				<WrittenAnswerController
					kitem={ZahlenTippenKitems[0]}
					onSuccess={() => {}}
					onFail={() => {}}
					onNext={() => {}}
				/>
			</>}
			{view === View.dashboard && <>
				<DashboardController 
					onTopicSelected={(topicId: string) => {setTopicId(topicId); setView(View.learningSession)}}
					onNavigateParents={() => setView(View.parentsTopics)}
					selfEvalMode={selfEvalMode}
					onSelfEvalModeChange={handleSelfEvalModeChange}
				/>
				{/* <DashboardView topics={topics} onTopicSelected={(topicId: string) => setTopicId(topicId)}/> */}
				<Button onClick={checkDebug}>·</Button>
			</>}
			{view === View.learningSession && topicId !== undefined && <>
					<IconButton size="large" onClick={() => {setTopicId(undefined); setView(View.dashboard)}} sx={{position: "absolute", zIndex: 9999}}><ArrowBackIcon/></IconButton>
					{/* <MultipleChoice question={{displayText: "N"}}/> */}
					<LearningSessionController
						topic={topicId}
						onBack={() => {setTopicId(undefined); setView(View.dashboard)}}
						selfEvalMode={selfEvalMode}
					/>
				</>
			}
			{(view === View.parents || view === View.settings || view === View.debug || view === View.parentsTopics) && <>
				<ParentsMenu open={parentsMenuOpen}
					onClose={() => {setParentsMenuOpen(false)}}
					onClickBack={() => {setView(View.dashboard); setParentsMenuOpen(false)}}
					onClickMyItems={() => {setView(View.parents); setParentsMenuOpen(false)}}
					onClickSettings={() => {setView(View.settings); setParentsMenuOpen(false)}}
					onClickParentsTopics={() => {setView(View.parentsTopics); setParentsMenuOpen(false)}}
				/>
				<AppBar position="static">
					<Toolbar>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
							sx={{ mr: 2 }}
							onClick={() => setParentsMenuOpen(true)}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{view === View.settings && "Einstellungen"}
							{view === View.parents && "Meine Fragen"}
							{view === View.debug && "Technische Informationen"}
							{view === View.parentsTopics && "Themen"}
						</Typography>
					</Toolbar>
				</AppBar>
				</>
			}
			{view === View.parents && <>
				<KitemListViewController onBack={() => setView(View.dashboard)}/>
			</>}
			{view === View.parentsTopics && <>
				<ParentsTopicsViewController/>
			</>}
			{view === View.settings && <>
				<SettingsViewController/>
			</>}
			{view === View.debug &&
				<DebugViewController/>
			}
	</ThemeProvider>
	</>;
}

export default App;
