import { useContext, useMemo } from 'react';
import { isAfter, isSameDay } from 'date-fns';
import logger from './logger';
import { TopicState } from '../components/DashboardView';
import LearningContext, { KitemProgressState, Topic } from '../contexts/LearningContext';
import { isLearning } from '../contexts/LearningProvider';
import assert from 'assert';

const topics: Topic[] = [
	// {
	// 	id: "zahlenmengen",
	// 	title: "● ●● ●●●",
	// },
	// {
	// 	id: "zahlen_1-10",
	// 	title: "1 - 10",
	// },
	{
		id: "zahlen",
		title: "1, 2, 3…",
	},
	// {
	// 	id: "zahlen_1x",
	// 	title: "10, 11, 12…",
	// },
	// {
	// 	id: "zahlen_x0",
	// 	title: "10, 20, 30…",
	// },
	{
		id: "buchstaben_gross",
		title: "A, B, C…",
	},
	{
		id: "soziales",
		title: "Soziale Fähigkeiten",
	},
	{
		id: "wochentage",
		title: "WOCHENTAGE",
	},
	{
		id: "monate",
		title: "MONATE",
	},
	{
		id: "planeten",
		title: "PLANETEN",
	},
	{
		id: "janosch",
		title: "JANOSCH",
	},
	{
		id: "ersteworte",
		title: "ERSTE WORTE",
	},
	{
		id: "geschwister",
		title: "👶",
	},
	{
		id: "englisch",
		title: "ENGLISCH",
	},
	{
		id: "misc",
		title: "MEHR",
	},
];

export type TopicStatus = "inactive" | "remembering" | "learning";

export function isTopicStatus(value: any): value is TopicStatus {
	return value === "inactive" || value === "remembering" || value === "learning";
}

export default function useTopics() {
	const learning = useContext(LearningContext);

	const getTopicState = (topicId: string | null): TopicState => {
		const now = new Date();
		const progress = learning.getCurriculum(topicId || undefined);
		const exposed = progress.filter(p => p.reps.length > 0);
		const known = exposed.filter(k => k.reps[k.reps.length - 1].success && isAfter(k.due, now));
		const pack = learning.getLearningPackage();
		const remainingKitemIds = topicId ? learning.filterKitemIdsByTopic(pack.remainingKitemIds, topicId) : pack.remainingKitemIds;
		let newCount = 0, learningCount = 0, retainingCount = 0;
		remainingKitemIds.forEach(kitemId => {
			const pitem = learning.getProgress(kitemId);
			if (!pitem)
				return;
			if (pitem.state === KitemProgressState.new)
				newCount++;
			else if (isLearning(pitem))
				learningCount++;
			else
				retainingCount++;
		});
		const doneToday = progress.filter(p => p.reps.length > 0 && isSameDay(p.reps[p.reps.length - 1].date, now));
		logger.debug("pack", topicId, pack, doneToday);
		return {
			size: progress.length,
			exposure:  (exposed.length / progress.length) * 100,
			known: (known.length / progress.length) * 100,
			done: pack.remainingKitemIds.length === 0,
			remainingRetaining: retainingCount,
			remainingLearning: learningCount,
			remainingNew: newCount,
		};
	}

	const getTopicsWithState = () => {
		const topicsEnriched: (Topic & TopicState)[] = [];
		for (let i = 0; i < topics.length; i++) {
			const tState = getTopicState(topics[i].id);
			const enrichedTopic = Object.assign({}, topics[i], tState);
			if (enrichedTopic.size > 0)
				topicsEnriched.push(enrichedTopic);
		}
		return topicsEnriched;
	}

	const getPackageTopicIds = () => {
		const pkg = learning.getLearningPackage();
		const topicIds: string[] = [];
		pkg.plannedKitemIds.forEach(kid => {
			const kitem = learning.getKitem(kid);
			assert(kitem);
			if (!topicIds.includes(kitem.topic))
				topicIds.push(kitem.topic);
		});
		return topicIds;
	}

	const getTopic = (topicId: string) => {
		return topics.find(t => t.id === topicId);
	}

	const getLearningTopicIds = () => {
		return topics.filter(t => learning.getTopicStatus(t.id) === 'learning').map(t => t.id);
	}

	const memo = useMemo(() => {
		return {
			topics: topics,
			getTopic: getTopic,
			getTopicState: getTopicState,
			getTopicsWithState: getTopicsWithState,
			getPackageTopicIds: getPackageTopicIds,
			getLearningTopicIds: getLearningTopicIds,
		};
	}, []);

	return memo;
}
