// import logger from "./logger";

import { DisplayImage, DisplayText } from "../App";
import logger from "./logger";

export const speechSynth = (text: string, keepQueue = false, onEnd?: () => void) => {
	// logger.debug("Speech synthing", text, keepQueue);
	if (!keepQueue)
		window.speechSynthesis.cancel();
	const utterance = new SpeechSynthesisUtterance(text);
	if (onEnd)
		utterance.onend = onEnd;
	utterance.lang = "de-DE";
	window.speechSynthesis.speak(utterance);
	console.log("uttered: "+text);
}

// enum SoundType {
// 	speechSynth,
// 	audioFile,
// }
// interface Sound {
// 	soundType: SoundType,
// }

export interface SpeechSynth {
	synthText: string,
}

export interface AudioUrl {
	audioUrl: string,
	transcript?: string,
}

function isSpeechSynth(variable: SpeechSynth | AudioUrl): variable is SpeechSynth {
	return (variable as SpeechSynth).synthText !== undefined;
}

export function isAudioUrl(variable: SpeechSynth | AudioUrl | DisplayText | DisplayImage): variable is AudioUrl {
	return (variable as AudioUrl).audioUrl !== undefined;
}
export interface PlaySoundOptions {
	// stopHandler?: () => () => void,
}
export interface SoundPlayer {
	stop(): void,
}
export const playSound = (sound: SpeechSynth | AudioUrl, keepQueue = false, onEnd?: () => void, options?: PlaySoundOptions): SoundPlayer => {
	if (isSpeechSynth(sound)) {
		speechSynth(sound.synthText, keepQueue, onEnd);
		return {
			stop: () => {
				window.speechSynthesis.cancel();
			}
		}
	} else if (isAudioUrl(sound)) {
		const audio = new Audio(sound.audioUrl);
		// add compressor for maximum loudness. User recordings are sometimes very low volume.
		const ctx = new AudioContext();
		const src = ctx.createMediaElementSource(audio);
		const compressor = ctx.createDynamicsCompressor();
		compressor.threshold.value = -40;
		compressor.knee.value = 20;
		compressor.ratio.value = 20;
		compressor.attack.value = 0;
		compressor.release.value = 0.25;
		src.connect(compressor);
		compressor.connect(ctx.destination);
		if (onEnd) audio.addEventListener('ended', onEnd);
		audio.play().catch(e => {
			// a call to pause before sound is loaded can cause this. In that case it can be ignored.
			// "DOMException: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22"
			// TODO: Check if this is really the cause or if something else caused the exception.
			logger.error("Play exception: ", e);
		});
		return {
			stop: () => {
				audio.pause();
				audio.currentTime = 0;
			}
		}
	} else {
		throw new Error("Sound type not implemented");
	}
}