import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";



export const ZahlenKitemsX0: SMCKitem[] = [
	{
		id: "Zahlen_30",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "30"
		},
		answers: [
			{
				synthText: "3",
				correct: false,
			},
			{
				synthText: "300",
				correct: false,
			},
			{
				synthText: "10",
				correct: false,
			},
			{
				synthText: "30",
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_40",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "40"
		},
		answers: [
			{
				synthText: "40",
				correct: true,
			},
			{
				synthText: "14",
				correct: false,
			},
			{
				synthText: "300",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
];