import React, { useState, useContext, useEffect } from 'react';
import KitemListView from '../components/KitemListView';
import LearningContext, { Kitem, KitemType } from '../contexts/LearningContext';
import logger from '../services/logger';
import SMCController, { isSMCKitem, SMCKitem, SMCKitemUnsaved } from './SMCController';
import SMCEditorController from './SMCEditorController';
import { AudioUrl, isAudioUrl, SpeechSynth } from '../services/sound-player';
import DialogsContext from '../contexts/DialogsContext';
import assert from 'assert';
import { DisplayImage, DisplayText } from '../App';
import RocketGameController, { isRocketKitem } from './RocketGameController';
import KitemSettingsContext from '../contexts/KitemSettingsContext';
import ImageChooserController, { isImageChooserKitem } from './ImageChooserController';
import WrittenAnswerController, { isWrittenAnswerKitem } from './WrittenAnswerController';


interface Props {
	onBack(): void,
}

export type KitemWithPauseState = Kitem & {isPaused: boolean};

export default function KitemListViewController(props: Props) {
	const [eId, setEId] = useState<string|null>(null);
	const [eQuestion, setEQuestion] = useState<string|null>(null);
	const [eRightAnswer, setERightAnswer] = useState<string|null>(null);
	const [eWrongAnswers, setEWrongAnswers] = useState<string[]>([]);
	const [previewItem, setPreviewItem] = useState<Kitem|null>(null);
	const learning = useContext(LearningContext);
	const dialogs = useContext(DialogsContext);
	const kitemSettings = useContext(KitemSettingsContext);

	const kitems: KitemWithPauseState[] = learning.getKitems().map(k => Object.assign({}, k, {isPaused: kitemSettings.isPaused(k.id)}));

	useEffect(() => {
	}, []);

	const resetEditor = () => {
		setEQuestion(null);
		setERightAnswer(null);
		setEWrongAnswers([]);
	}
	const handleEdit = (id: string) => {
		const kitem = kitems.find(k => k.id === id);
		if (!kitem)
			throw new Error("Kitem "+id+" not found");
		if (!isSMCKitem(kitem))
			throw new Error("Unsupported kitem type");
		if (isAudioUrl(kitem.question))
			setEQuestion(kitem.question.audioUrl);
		else
			throw new Error("Unsupported question type");
		const rightAnswer = kitem.answers.find(a => a.correct);
		if (!rightAnswer)
			throw new Error("Correct answer not found in data");
		if (isAudioUrl(rightAnswer))
			setERightAnswer(rightAnswer.audioUrl);
		else
			throw new Error("Unsupported right answer type");
		const wrongAnswers: string[] = [];
		kitem.answers.forEach(a => {
			if (!a.correct) {
				if (isAudioUrl(a))
					wrongAnswers.push(a.audioUrl);
				else
					throw new Error("Unsupported answer type");
			}
		});
		setEWrongAnswers(wrongAnswers);
		setEId(kitem.id);
	}
	const handleDelete = (id: string) => {
		if (window.confirm("Dieses Element und den zugehörigen Lernfortschritt löschen? Dieser Vorgang kann nicht rückgängig gemacht werden"))
			learning.deleteKitem(id);
	}
	const handleAdd = () => {
		setEId("");
		resetEditor();
	}
	const handleSave = (question: string, rightAnswer: string, wrongAnswers: string[]) => {
		logger.debug("Save", question, rightAnswer, wrongAnswers);
		if (eId === "") {
			logger.debug("Creating new kitem");
			// Create new item
			const kitem: SMCKitemUnsaved = {
				type: KitemType.SMC,
				topic: "misc",
				question: {audioUrl: question},
				answers: [
					{audioUrl: rightAnswer, correct: true},
					...wrongAnswers.map(a => ({audioUrl: a, correct: false}))
				]
			}
			learning.addKitem(kitem);
		} else {
			logger.debug("Updating existing kitem");
			// Update existing item
			const oldKitem = kitems.find(k => k.id === eId);
			if (!oldKitem)
				throw new Error("Lost track of kitem");
			const updatedKitem = Object.assign({}, oldKitem, {
				question: {audioUrl: question},
				answers: [
					{audioUrl: rightAnswer, correct: true},
					...wrongAnswers.map(a => ({audioUrl: a, correct: false}))
				]
			});
			learning.updateKitem(updatedKitem);
		}
		resetEditor();
		setEId(null);
	}

	const handleCancel = () => {
		setEId(null);
		resetEditor();
	}

	const executeDownload = (url: string, filename: string) => {
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const handleDownload = async (id: string) => {
		const kitem = learning.getKitem(id);
		assert(kitem, "kitem not found: "+id);
		assert(isSMCKitem(kitem), "not an SMCKitem");
		const promptedId = await dialogs.prompt("ID festlegen (optional)");
		if (promptedId === null)
			return;
		const audioFiles: {fileName: string, dataUrl: string}[] = [];
		const transformDataURL = (item: SpeechSynth | AudioUrl | DisplayText | DisplayImage, id: string, name: string): AudioUrl => {
			assert(isAudioUrl(item), "not an audio url");
			const file = {
				fileName: id+"."+name+".webm",
				dataUrl: item.audioUrl,
			};
			assert(file.dataUrl.startsWith("data:audio/webm"), "not the correct data url");
			audioFiles.push(file);
			return Object.assign({}, item, {
				audioUrl: "https://hydrogen-assets.kreativrauschen.de/misc/"+file.fileName
			});
		}
		const newId = promptedId !== "" ? promptedId : kitem.id;
		const downloadableKitem: SMCKitem = Object.assign({}, kitem, {
			id: newId,
			question: transformDataURL(kitem.question, newId, "question"),
			answers: kitem.answers.map((a, idx) => transformDataURL(a, newId, "answer-"+idx)),
		});
		logger.debug("downloadableKitem", downloadableKitem);
		logger.debug("audioFiles", audioFiles);

		const jsonBlob = new Blob([JSON.stringify(downloadableKitem, undefined, 4)], { type: 'application/json' });
		const downloadUrl = URL.createObjectURL(jsonBlob);
		executeDownload(downloadUrl, newId+".json");
		audioFiles.forEach(file => {
			executeDownload(file.dataUrl, file.fileName);
		});
	}

	const handlePreview = (id: string) => {
		const kitem = learning.getKitem(id);
		assert(kitem, "kitem not found: "+id);
		assert(isSMCKitem(kitem) || isRocketKitem(kitem) || isImageChooserKitem(kitem) || isWrittenAnswerKitem(kitem), "Can't preview this kitem type");
		// setPreviewItem(kitem);
		if (isSMCKitem(kitem)) {
			dialogs.alert({
				message: <SMCController kitem={kitem} intro={false} correctLast={true} onFail={() => {}} onNext={() => setPreviewItem(null)} onSuccess={() => {}}/>,
				fullScreen: true,
			})
		} else {
			setPreviewItem(kitem);
			// dialogs.alert({
			// 	message: <RocketGameController targetId={kitem.targetId} maxErrors={2} maxSeconds={120} requiredSuccesses={5} onFail={() => {}} onNext={() => setPreviewItem(null)} onSuccess={() => {}}/>,
			// 	fullScreen: true,
			// })
		}
	}

	const handlePause = (id: string) => {
		logger.info("Pausing item "+id);
		kitemSettings.setPaused(id, true);
	}
	const handleContinue = (id: string) => {
		logger.info("Continuing item "+id);
		kitemSettings.setPaused(id, false);
	}

	// id: string,
	// type: KitemType.SMC,
	// topic: string,
	// question: SpeechSynth | SoundFile | DisplayText,
	// answers: ((SpeechSynth | SoundFile) & {correct: boolean})[]

	if (previewItem && isSMCKitem(previewItem)) {
		return <SMCController kitem={previewItem} intro={false} onFail={() => {}} onNext={() => setPreviewItem(null)} onSuccess={() => {}}/>
	}

	if (previewItem && isRocketKitem(previewItem)) {
		return <RocketGameController targetId={previewItem.targetId} setId={previewItem.setId} speed={1} maxErrors={2} maxSeconds={120} requiredSuccesses={5} onFail={() => {}} onNext={() => setPreviewItem(null)} onSuccess={() => {}}/>;
	}

	if (previewItem && isImageChooserKitem(previewItem)) {
		return <ImageChooserController key={previewItem.id} kitem={previewItem} easiness={1} onNext={() => setPreviewItem(null)} onFail={() => {}} onSuccess={() => {}} selfEvalMode={false}/>
	}

	if (previewItem && isWrittenAnswerKitem(previewItem)) {
		return <WrittenAnswerController kitem={previewItem} onNext={() => setPreviewItem(null)} onFail={() => {}} onSuccess={() => {}}/>
	}

	return (
		<React.Fragment>
			{eId !== null && <SMCEditorController question={eQuestion} rightAnswer={eRightAnswer} wrongAnswers={eWrongAnswers} onCancel={handleCancel} onSave={handleSave}/>}
			<KitemListView
				kitems={kitems}
				onDelete={handleDelete}
				onEdit={handleEdit}
				onAdd={handleAdd}
				onBack={props.onBack}
				onDownload={handleDownload}
				onPreview={handlePreview}
				onPause={handlePause}
				onContinue={handleContinue}
			/>
		</React.Fragment>
	);
}
