import { KitemType } from "../contexts/LearningContext";
import { ImageChooserKitem } from "../controller/ImageChooserController";
import { RocketKitem } from "../controller/RocketGameController";
import { SMCKitem } from "../controller/SMCController";



export const ZahlenMengen1_10Kitems: (SMCKitem|RocketKitem|ImageChooserKitem)[] = [
	{
		id: "Zahlenmengen_1",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-1.svg').default,
			alt: "Eine Menge von 1 Punkten",
		},
		answers: [
			{
				synthText: "1",
				correct: true,
			},
			{
				synthText: "2",
				correct: false,
			},
			{
				synthText: "3",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_2",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-2.svg').default,
			alt: "Eine Menge von 2 Punkten",
		},
		answers: [
			{
				synthText: "1",
				correct: false,
			},
			{
				synthText: "2",
				correct: true,
			},
			{
				synthText: "3",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_3",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-3.svg').default,
			alt: "Eine Menge von 3 Punkten",
		},
		answers: [
			{
				synthText: "1",
				correct: false,
			},
			{
				synthText: "2",
				correct: false,
			},
			{
				synthText: "3",
				correct: true,
			},
		],
	},
	{
		id: "Zahlenmengen_4",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-4.svg').default,
			alt: "Eine Menge von 4 Punkten",
		},
		answers: [
			{
				synthText: "4",
				correct: true,
			},
			{
				synthText: "5",
				correct: false,
			},
			{
				synthText: "3",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_5",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-5.svg').default,
			alt: "Eine Menge von 5 Punkten",
		},
		answers: [
			{
				synthText: "5",
				correct: true,
			},
			{
				synthText: "4",
				correct: false,
			},
			{
				synthText: "6",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_1_Raketen",
		type: KitemType.Rockets,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "1v5",
	},
	{
		id: "Zahlenmengen_2_Raketen",
		type: KitemType.Rockets,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "2v5",
	},
	{
		id: "Zahlenmengen_3_Raketen",
		type: KitemType.Rockets,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "3v5",
	},
	{
		id: "Zahlenmengen_4_Raketen",
		type: KitemType.Rockets,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "4v5",
	},
	{
		id: "Zahlenmengen_5_Raketen",
		type: KitemType.Rockets,
		topic: "zahlenmengen",
		setId: "zahlenmengen",
		targetId: "5v5",
	},
	{
		id: "Zahlenmengen_6v10",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-6v10.svg').default,
			alt: "Eine Menge von 6 Punkten",
		},
		answers: [
			{
				synthText: "6",
				correct: true,
			},
			{
				synthText: "7",
				correct: false,
			},
			{
				synthText: "8",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_7v10",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-7v10.svg').default,
			alt: "Eine Menge von 7 Punkten",
		},
		answers: [
			{
				synthText: "7",
				correct: true,
			},
			{
				synthText: "6",
				correct: false,
			},
			{
				synthText: "8",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_8v10",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-8v10.svg').default,
			alt: "Eine Menge von 8 Punkten",
		},
		answers: [
			{
				synthText: "8",
				correct: true,
			},
			{
				synthText: "9",
				correct: false,
			},
			{
				synthText: "6",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_9v10",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-9v10.svg').default,
			alt: "Eine Menge von 9 Punkten",
		},
		answers: [
			{
				synthText: "9",
				correct: true,
			},
			{
				synthText: "7",
				correct: false,
			},
			{
				synthText: "8",
				correct: false,
			},
		],
	},
	{
		id: "Zahlenmengen_10v10",
		type: KitemType.SMC,
		topic: "zahlenmengen",
		question: {
			imageUrl: require('../assets/zahlen/set-10v10.svg').default,
			alt: "Eine Menge von 10 Punkten",
		},
		answers: [
			{
				synthText: "10",
				correct: true,
			},
			{
				synthText: "9",
				correct: false,
			},
			{
				synthText: "11",
				correct: false,
			},
		],
	},
];