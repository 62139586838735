import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";



export const ZahlenKitems2x: SMCKitem[] = [
	{
		id: "Zahlen_20",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "20"
		},
		answers: [
			{
				synthText: "2",
				correct: false,
			},
			{
				synthText: "200",
				correct: false,
			},
			{
				synthText: "10",
				correct: false,
			},
			{
				synthText: "20",
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_21",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "21"
		},
		answers: [
			{
				synthText: "21",
				correct: true,
			},
			{
				synthText: "12",
				correct: false,
			},
			{
				synthText: "11",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_22",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "22"
		},
		answers: [
			{
				synthText: "22",
				correct: true,
			},
			{
				synthText: "12",
				correct: false,
			},
			{
				synthText: "2",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_23",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "23"
		},
		answers: [
			{
				synthText: "23",
				correct: true,
			},
			{
				synthText: "13",
				correct: false,
			},
			{
				synthText: "22",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_24",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "24"
		},
		answers: [
			{
				synthText: "24",
				correct: true,
			},
			{
				synthText: "14",
				correct: false,
			},
			{
				synthText: "21",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_25",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "25"
		},
		answers: [
			{
				synthText: "25",
				correct: true,
			},
			{
				synthText: "15",
				correct: false,
			},
			{
				synthText: "35",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
];