import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, AlertTitle, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, DialogActions, IconButton, Stack, Typography, useTheme } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import RecorderCard from './RecorderCard';
import AnnouncementIcon from '@mui/icons-material/Announcement';

interface SMCEditDialogProps {
	question: ReactNode,
	rightAnswer: ReactNode,
	wrongAnswers: ReactNode[],
	onAdd(): void,
	onCancel(): void,
	onSave(): void,
	saveable: boolean,
}

export default function SMCEditDialog(props: SMCEditDialogProps) {
	const theme = useTheme();

	return <>
			<Dialog open={true}>
				<DialogTitle>Neues Wissen</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						{props.question}
						{props.rightAnswer}
						{props.wrongAnswers}
						{/* <RecorderCard variant="rightAnswer"/>
						<RecorderCard variant="wrongAnswer" deleteable />
						<RecorderCard variant="wrongAnswer" deleteable /> */}
					</Stack>
					<Box textAlign="center" marginTop={1}>
						<IconButton onClick={props.onAdd}><AddIcon/></IconButton>
						{/* <Button>Falsche Antwort hinzufügen</Button> */}
						{/* <Button variant="outlined">+</Button> */}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="inherit" onClick={props.onCancel}>Abbrechen</Button>
					<Button autoFocus onClick={props.onSave} disabled={!props.saveable}>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
	</>;
}


/**
 * Former designs that are not in use
 * @returns 
 */
function OldCards() {
	const theme = useTheme(); 
	return <>


<Alert severity="info" sx={{ marginBottom: 2 }}>
					<AlertTitle sx={{marginRight: 0}}>Frage</AlertTitle>
					<IconButton size='large' sx={{
						// marginLeft: -2,
						// border: "1px solid red"
						}}>
						<MicIcon fontSize='large' />
					</IconButton>
					<IconButton size='large' disabled={true}>
						<PlayCircleFilledWhiteOutlinedIcon fontSize='large' />
					</IconButton>
					<br/>
					{/* <Typography variant="caption">In welcher Stadt wohnst Du und wie heißt die Straße?</Typography> */}
				</Alert>


					<Card sx={{ marginBottom: 2 }}>
						<CardContent>
							<Stack direction="row" alignItems="center">
								<Avatar
									
							 		// color="primary"
							 		// sx={{ bgcolor: red[500] }}
							 		aria-label="question"
							 		>
							 		F
							 	</Avatar> 
								<Typography variant="body1">Frage</Typography>
								<IconButton size='large'>
									<MicIcon fontSize='large' />
								</IconButton>
								<IconButton size='large' disabled={true}>
									<PlayCircleFilledWhiteOutlinedIcon fontSize='large' />
								</IconButton>

							</Stack>
							{/* <Typography variant="body2" color="text.secondary">
							This impressive paella is a perfect party dish and a fun meal to cook
							together with your guests. Add 1 cup of frozen peas along with the mussels,
							if you like.
							</Typography> */}
						</CardContent>
					</Card>

					<Card sx={{ marginBottom: 2 }}>
						<CardHeader
							// avatar={
							// 	<Avatar
							// 		// color="primary"
							// 		// sx={{ bgcolor: red[500] }}
							// 		aria-label="question"
							// 		>
							// 		F
							// 	</Avatar>
							// }
							// action={
							//   <IconButton aria-label="settings">
							//     <MoreVertIcon />
							//   </IconButton>
							// }
							title="Frage"
						/>
						<CardContent>
							<IconButton size='large'>
								<MicIcon fontSize='large' />
							</IconButton>
							<IconButton size='large' disabled={true}>
								<PlayCircleFilledWhiteOutlinedIcon fontSize='large' />
							</IconButton>
							{/* <Typography variant="body2" color="text.secondary">
							This impressive paella is a perfect party dish and a fun meal to cook
							together with your guests. Add 1 cup of frozen peas along with the mussels,
							if you like.
							</Typography> */}
						</CardContent>
						{/* <CardActions disableSpacing>
							<IconButton aria-label="add to favorites">
							<FavoriteIcon />
							</IconButton>
							<IconButton aria-label="share">
							<ShareIcon />
							</IconButton>
							<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
							>
							<ExpandMoreIcon />
							</ExpandMore>
						</CardActions> */}
				</Card>
					<Card sx={{ maxWidth: 345 }}>
						<CardHeader
							avatar={
								<Avatar
									// color="primary"
									sx={{ bgcolor: theme.palette.success.main }}
									// aria-label="question"
									>
									A
								</Avatar>
							}
							// action={
							//   <IconButton aria-label="settings">
							//     <MoreVertIcon />
							//   </IconButton>
							// }
							title="Richtige Antwort"
						/>
						<CardContent>
							<IconButton size='large'>
								<MicIcon fontSize='large' />
							</IconButton>
							<IconButton size='large' disabled={true}>
								<PlayCircleFilledWhiteOutlinedIcon fontSize='large' />
							</IconButton>
							{/* <Typography variant="body2" color="text.secondary">
							This impressive paella is a perfect party dish and a fun meal to cook
							together with your guests. Add 1 cup of frozen peas along with the mussels,
							if you like.
							</Typography> */}
						</CardContent>
						{/* <CardActions disableSpacing>
							<IconButton aria-label="add to favorites">
							<FavoriteIcon />
							</IconButton>
							<IconButton aria-label="share">
							<ShareIcon />
							</IconButton>
							<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
							>
							<ExpandMoreIcon />
							</ExpandMore>
						</CardActions> */}
				</Card>

	</>;
}