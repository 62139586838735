import Typography from '@mui/material/Typography';
import "./LoadingView.css";
import { Badge, Box, Card, CardContent, LinearProgress, useTheme } from '@mui/material';


export interface TopicCardProps {
	title?: string,
	onClick(): void,
	disabled: boolean,
	size: number,
	exposure: number,
	known: number,
}

function TopicCardParents(props: TopicCardProps) {
	const theme = useTheme();

	
	return <>
		<Card sx={{
			position: "relative", 
			marginBottom: theme.spacing(4), 
			textAlign: "center", 
			cursor: "pointer", 
			marginLeft: 2,
			marginRight: 2,
			}} onClick={props.onClick}>
			<CardContent>
				<Box sx={{textAlign: "center"}}>
					<Typography variant="caption" color={theme.palette.text.disabled}>{props.size} Einheiten</Typography>
				</Box>
				<Typography variant='h2' 
					// color="inactive"
					sx={props.disabled ? {color: theme.palette.text.disabled} : undefined}
					>{props.title}</Typography>
			</CardContent>
			<LinearProgress variant="determinate" value={(props.known / props.exposure) * 100} sx={{width: props.exposure + "%"}} />
		</Card>
	</>;
}

export default TopicCardParents;
