import { Box, Stack, Grid, Dialog, Button, IconButton, Paper, useTheme } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import CloudLayer from '../components/CloudLayer';
import { Kitem, KitemType } from '../contexts/LearningContext';
import { playSound } from '../services/sound-player';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import AudioButton from '../components/AudioButton';
import assert from 'assert';

const cloudLayerImage = require('../assets/cloud-layer.png');

export type RocketKitem = Kitem & {
	type: KitemType.Rockets,
	topic: string,
	setId: string,
	targetId: string,
};

export function isRocketKitem(item: Kitem): item is RocketKitem {
	return (
		(item as RocketKitem).type !== undefined
		&& (item as RocketKitem).type === KitemType.Rockets
		&& (item as RocketKitem).setId !== undefined
		&& (item as RocketKitem).targetId !== undefined
	);
}

// type Payload = {id: string} & ({speechSynth: string} | {audioUrl: string});
interface Payload {
	id: string,
	audioUrl?: string,
	speechSynth?: string,
	imageUrl?: string,
};

const samplepayload: Payload = {id: "A", audioUrl: require("../assets/lesen/a.m4a")};
console.log(samplepayload.audioUrl);

type PayloadSets = {
	[key: string]: Payload[];
};

const payloadSets: PayloadSets = {
	"10-13": [
		{id: "10", audioUrl: require("../assets/zahlen/10.m4a")},
		{id: "11", audioUrl: require("../assets/zahlen/11.m4a")},
		{id: "12", audioUrl: require("../assets/zahlen/12.m4a")},
		{id: "13", audioUrl: require("../assets/zahlen/13.m4a")},
	],
	"buchstaben_gross": [
		{id: "A", audioUrl: require("../assets/lesen/a.m4a")},
		{id: "B", audioUrl: require("../assets/lesen/b.m4a")},
		{id: "D", audioUrl: require("../assets/lesen/d.m4a")},
		{id: "E", audioUrl: require("../assets/lesen/e.m4a")},
		{id: "F", audioUrl: require("../assets/lesen/f.m4a")},
		{id: "G", audioUrl: require("../assets/lesen/g.m4a")},
		{id: "H", audioUrl: require("../assets/lesen/h.m4a")},
		{id: "I", audioUrl: require("../assets/lesen/i.m4a")},
		{id: "J", audioUrl: require("../assets/lesen/j.m4a")},
		{id: "K", audioUrl: require("../assets/lesen/k.m4a")},
		{id: "L", audioUrl: require("../assets/lesen/l.m4a")},
		{id: "M", audioUrl: require("../assets/lesen/m.m4a")},
		{id: "N", audioUrl: require("../assets/lesen/n.m4a")},
		{id: "O", audioUrl: require("../assets/lesen/o.m4a")},
		{id: "P", audioUrl: require("../assets/lesen/p.m4a")},
		{id: "R", audioUrl: require("../assets/lesen/r.m4a")},
		{id: "S", audioUrl: require("../assets/lesen/s.m4a")},
		{id: "T", audioUrl: require("../assets/lesen/t.m4a")},
		{id: "U", audioUrl: require("../assets/lesen/u.m4a")},
		{id: "W", audioUrl: require("../assets/lesen/w.m4a")},
		{id: "X", audioUrl: require("../assets/lesen/x.m4a")},
		{id: "Z", audioUrl: require("../assets/lesen/z.m4a")},
	],
	"zahlenmengen": [
		{id: "1v5", audioUrl: require("../assets/zahlen/1-punkt.m4a"), imageUrl: require("../assets/zahlen/set-1v5.svg").default},
		{id: "2v5", audioUrl: require("../assets/zahlen/2-punkte.m4a"), imageUrl: require("../assets/zahlen/set-2v5.svg").default},
		{id: "3v5", audioUrl: require("../assets/zahlen/3-punkte.m4a"), imageUrl: require("../assets/zahlen/set-3v5.svg").default},
		{id: "4v5", audioUrl: require("../assets/zahlen/4-punkte.m4a"), imageUrl: require("../assets/zahlen/set-4v5.svg").default},
		{id: "5v5", audioUrl: require("../assets/zahlen/5-punkte.m4a"), imageUrl: require("../assets/zahlen/set-5v5.svg").default},
		// {id: "10v10", audioUrl: require("../assets/zahlen/5-punkte.m4a"), imageUrl: require("../assets/zahlen/set-10v10.svg").default},
	],
};

interface Rocket {
	id: number;
	payload: Payload;
	positionX: number;
	positionY: number;
	success: boolean | undefined;
}

const Rocket = (props: {payload: Payload, crashed: boolean}) => {
	return <>
		<Stack alignItems="center">
			{!props.payload.imageUrl && <Box fontSize={100} fontWeight="bold" marginBottom={-4}>{props.payload.id}</Box>}
			{props.payload.imageUrl && <Box marginBottom={0}><img src={props.payload.imageUrl} style={{width: 100}}/></Box>}
			<Box sx={{rotate: "315deg"}} fontSize={40}>{!props.crashed ? "🚀" : "💥"}</Box>
		</Stack>
	</>;
}

interface RocketGameProps {
	maxSeconds: number,
	maxErrors: number,
	requiredSuccesses: number,
	setId: string,
	targetId: string,
	speed: number,
	onSuccess(): void,
	onFail(): void,
	onNext(): void,
}

const RocketGameController = (props: RocketGameProps) => {
	const [rockets, setRockets] = useState<Rocket[]>([]);
	const [score, setScore] = useState<number>(0);
	const [errors, setErrors] = useState<number>(0);
	const [status, setStatus] = useState<"playing"|"success"|"failed">("playing");
	const rocketsSinceLastCorrectRocket = useRef(0);
	const theme = useTheme();

	const payloads = payloadSets[props.setId];
	assert(payloads);
	const targetPayload = payloads.find(p => p.id === props.targetId);
	assert(targetPayload, "target payload not found");
	const wrongPayloads = payloads.filter(p => p.id !== props.targetId);

	useEffect(() => {
		playSound({audioUrl: require('../assets/tippe-auf-die-raketen.m4a')}, true, () => {
			assert(targetPayload.audioUrl || targetPayload.speechSynth);
			playSound(targetPayload.audioUrl ? {audioUrl: targetPayload.audioUrl} : {synthText: (targetPayload.speechSynth || "")}, true);
		});
	}, []);

	const rocketSpeed = props.speed; // Geschwindigkeit der Raketenbewegung in Prozent pro Millisekunde

	// Diese Funktion erstellt zufällige Raketen mit zufälligen Objekten und Startpositionen
	const createRandomRocket = (): Rocket => {
		let payload = null;
		// In ~30% of cases (no less than every 5th), use the correct payload. Otherwise choose a random one from the wrong payloads.
		if (Math.random() < 0.333 || rocketsSinceLastCorrectRocket.current >= 5) {
			payload = targetPayload;
			rocketsSinceLastCorrectRocket.current = 0;
		} else {
			payload = wrongPayloads[Math.floor(Math.random() * wrongPayloads.length)];
			rocketsSinceLastCorrectRocket.current++;
		}

		const randomPositionX = (Math.random() * 80)+10; // Zufällige X-Position
		const startPositionY = 100; // Die Raketen starten unten außerhalb des Bildschirms

		return {
			id: Date.now(), // Eindeutige ID für jede Rakete
			payload: payload,
			positionX: randomPositionX,
			positionY: startPositionY,
			success: undefined,
		};
	};

	// Diese Funktion prüft, ob der Spieler das richtige Objekt getippt hat
	const handleRocketClick = (rocket: Rocket) => {
		playSound(rocket.payload.audioUrl ? {audioUrl: rocket.payload.audioUrl} : {synthText: (rocket.payload.speechSynth || "")});
		if (rocket.success === undefined) {
			const success = rocket.payload.id === props.targetId;
			if (success) {
				const newScore = score + 1;
				setScore(newScore);
				if (newScore >= props.requiredSuccesses) {
					setStatus("success");
					props.onSuccess();
				}
			} else {
				const newErrors = errors + 1;
				setErrors(newErrors);
				if (newErrors >= props.maxErrors) {
					setStatus("failed");
					props.onFail();
				}
			}

			// Markiere die Rakete als getappt und entferne sie nach kurzer Zeit
			setRockets((prevRockets) =>
				prevRockets.map((r) =>
					r.id === rocket.id ? { ...r, success: success } : r
				)
			);

			if (!success) {
				setTimeout(() => {
					setRockets((prevRockets) =>
					prevRockets.filter((r) => r.id !== rocket.id)
					);
				}, 1000);
			}
		}
	};

	// Diese Funktion aktualisiert die Position der Raketen
	const updateRocketPositions = () => {
		setRockets((prevRockets) =>
			prevRockets.map((rocket) => ({
				...rocket,
				positionY: rocket.positionY - (rocket.success === true ? rocketSpeed * 5.0 : rocket.success === undefined ? rocketSpeed : 0),
			}))
		);
	};

	// Hier erstellen wir regelmäßig neue Raketen
	useEffect(() => {
		const rocketInterval = setInterval(() => {
			if (status === "playing") {
				const newRocket = createRandomRocket();
				setRockets((prevRockets) => [...prevRockets, newRocket]);
			}
		}, 2000); // Neue Raketen alle 2 Sekunden

		const cleanup = () => clearInterval(rocketInterval);
		return cleanup;
	}, [status]);

	// Hier überwachen wir die Raketen und aktualisieren ihre Positionen
	useEffect(() => {
		const updateInterval = setInterval(() => {
			if (status === "playing") {
				updateRocketPositions();
			}
		}, 16); // Aktualisierung etwa alle 16 Millisekunden (ca. 60 FPS)

		const cleanup = () => clearInterval(updateInterval);
		return cleanup;
	}, [status]);

	return <>
		{/* <div> */}
			{/* <div>Score: {score}</div>
			<div>Errors: {errors}</div> */}
			<div style={{ position: 'relative', height: '100vh', overflow: "clip" }}>
				{/* <img src={cloudLayerImage} style={{zIndex: 99, position: "absolute", height: 600}}/> */}
				{rockets.map((rocket, index) => (
					<div
						key={index}
						style={{
							position: 'absolute',
							top: `${rocket.positionY}%`, // Ändert die Position auf "top" statt "bottom"
							left: `${rocket.positionX}%`, // Zufällige X-Position
							transform: 'translate(-50%, -50%)', // Zentriert das Raketenobjekt
							cursor: 'pointer',
							padding: "0 25px 50px",
							zIndex: 9,
						}}
						onTouchStart={() => handleRocketClick(rocket)}
						onMouseDown={() => handleRocketClick(rocket)}
						// onClick={() => handleRocketClick(rocket)}
					>
						<Rocket payload={rocket.payload} crashed={rocket.success === false}/>
					</div>
				))}
			</div>
		{/* </div> */}

		<Dialog open={status === "success"}>
			<Paper elevation={8} sx={{
				padding: 4,
				// backgroundColor: "#EF5350",
				backgroundColor: "#94D196",
				boxShadow: "0px 5px 5px -3px rgba(27,94,32,0.2), 0px 8px 10px 1px rgba(27,94,32,0.14), 0px 3px 14px 2px rgba(27,94,32,0.12)",
				// borderStyle: "solid",
				borderColor: theme.palette.success.main,
				width: 256,
				}}
				>
				<Grid container
					direction="column"
					justifyContent="space-around"
					alignItems="center"
					sx={{height: "100%", minHeight: "50vh"}}
					>
					<Grid item marginBottom={4}>
						<AudioButton audio={{audioUrl: require('../assets/jaaa.m4a')}} autoPlay size="xxl"></AudioButton>
					</Grid>
					<Grid item><Box 
					// marginTop="25vh"
					>
						{/* <Button
							onClick={props.onClickTrue}
							css={css`
								margin-right: 24px;
							`}
						>
						<Typography variant="h2">✔</Typography></Button>
						<Button css={css`
							margin-left: 24px;
						`}
						onClick={props.onClickFalse}>
						<Typography variant="h2">✖</Typography></Button> */}
						<Button variant="contained" size="large" sx={{fontSize: 20}} color="success" onClick={props.onNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
					</Box>
					</Grid>
				</Grid>
			</Paper>
		</Dialog>
		<Dialog open={status === "failed"}>
			<Paper elevation={8} sx={{
				padding: 4,
				// backgroundColor: "#EF5350",
				backgroundColor: "hsl(1 83% 93% / 1)",
				boxShadow: "0px 5px 5px -3px rgba(198,40,40,0.2), 0px 8px 10px 1px rgba(198,40,40,0.14), 0px 3px 14px 2px rgba(198,40,40,0.12)",
				// borderStyle: "solid",
				borderColor: theme.palette.error.main,
				width: 256,
				}}
				>
				<Grid container
					direction="column"
					justifyContent="space-around"
					alignItems="center"
					sx={{height: "100%", minHeight: "50vh"}}
					>
					<Grid item marginBottom={4}>
						<AudioButton audio={{audioUrl: require('../assets/hoppala-das-ueben-wir-bald-nochmal.m4a')}} autoPlay size="xxl"></AudioButton>
					</Grid>
					<Grid item><Box 
					// marginTop="25vh"
					>
						{/* <Button
							onClick={props.onClickTrue}
							css={css`
								margin-right: 24px;
							`}
						>
						<Typography variant="h2">✔</Typography></Button>
						<Button css={css`
							margin-left: 24px;
						`}
						onClick={props.onClickFalse}>
						<Typography variant="h2">✖</Typography></Button> */}
						<Button variant="contained" size="large" sx={{fontSize: 20}} color="error" onClick={props.onNext}>⟫&nbsp;&nbsp;⟫&nbsp;&nbsp;⟫</Button>
					</Box>
					</Grid>
				</Grid>
			</Paper>
		</Dialog>
	</>;
};

export default RocketGameController;
