import React from 'react';
import "./LoadingView.css";
import { Box, Container, FormControlLabel, IconButton, Stack, Switch, useTheme } from '@mui/material';
import { Topic } from '../contexts/LearningContext';
import TopicCard from './TopicCard';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import HowToRegIcon from '@mui/icons-material/HowToReg';

export interface TopicState {
	size: number,
	exposure: number,
	known: number,
	done: boolean,
	remainingNew: number,
	remainingLearning: number,
	remainingRetaining: number,
}
export interface DashboardViewProps {
	onTopicSelected(topicId: string | null): void,
	onParentClick(): void,
	topics: (Topic & TopicState)[],
	allTopicsState: TopicState,
	selfEvalMode: boolean,
	onSelfEvalModeChange(): void,
}

function DashboardView(props: DashboardViewProps) {
	// const classes = useStyles();
	const theme = useTheme();


	
	return <>

		{/* <Box textAlign="center" marginTop={2}><ModelTrainingIcon color="secondary" fontSize="large"/></Box> */}

		<Container maxWidth="sm" sx={{marginTop: theme.spacing(2)}}>


			
			{/* {topics.map((topic, index) => <>
				<Box textAlign="center">
						<Button startIcon={index === 3 ? <AbcIcon/> : <NumbersIcon/>} sx={{width: "100%", height: 80, fontSize: 30, marginBottom: theme.spacing(2)}} size='large' variant="contained" color="inherit">{topic}</Button>
				</Box>
			</>)}
			<hr/>
			{topics.map(topic => <>
			<Box textAlign="center">
						<Button sx={{width: "100%", height: 80, fontSize: 30, marginBottom: theme.spacing(2)}} size='large' variant="contained" color="inherit">{topic}</Button>
						</Box>
			</>)}
			<hr/> */}

			<TopicCard 
				icon={true}
				disabled={props.allTopicsState.done}
				onClick={() => {props.onTopicSelected(null)}}
				exposure={props.allTopicsState.exposure}
				known={props.allTopicsState.known}
				remainingRetaining={props.allTopicsState.remainingRetaining}
				remainingLearning={props.allTopicsState.remainingLearning}
				remainingNew={props.allTopicsState.remainingNew}
			/>

			{/* <Divider sx={{
				marginBottom: 3,
				marginTop: -1,
			}}/> */}

			{props.topics.map(topic => <>
			{/* <Badge badgeContent="8"> */}
				<TopicCard
					key={topic.id}
					title={topic.title}
					disabled={topic.done}
					onClick={() => props.onTopicSelected(topic.id)}
					exposure={topic.exposure}
					known={topic.known}
					remainingRetaining={topic.remainingRetaining}
					remainingLearning={topic.remainingLearning}
					remainingNew={topic.remainingNew}
				/>
				{/* </Badge> */}
			</>)}

			<Stack direction="row" justifyContent="space-between">
				<FormControlLabel sx={{marginLeft: 1}} control={<Switch checked={props.selfEvalMode} onChange={props.onSelfEvalModeChange}/>} label={<><HowToRegIcon sx={{marginTop: 0.5, color: props.selfEvalMode ? theme.palette.primary.main : "rgba(0, 0, 0, 0.54)"}}/></>} />

				<Box textAlign="right" marginTop={0}>
					<IconButton size="large" onClick={props.onParentClick}><SupervisorAccountIcon/></IconButton>
				</Box>
			</Stack>

		</Container>
	</>;
}

export default DashboardView;
