import assert from "assert";
import { KitemRep } from "../contexts/LearningContext";
import logger from "./logger";
import { getServerReps as getServerReps_reimported } from './repSyncer';
import { parseISO } from "date-fns";

// import * as Sentry from "@sentry/react";

export async function fullSync(profileId: string, localReps: KitemRep[], addLocalRep: (newRep: KitemRep) => void, addServerRep: (newRep: KitemRep, profileId: string) => void) {
	const serverReps = await getServerReps_reimported(profileId); // call reimported version to allow mocking from outside

	const localIds = new Set(localReps.map(rep => rep.id));
	const serverIds = new Set(serverReps.map(rep => rep.id));

	// call addLocalRep for all reps that are in serverReps but not in localReps
	for (const serverRep of serverReps) {
		if (!localIds.has(serverRep.id)) {
		  addLocalRep(serverRep);
		}
	}

	// Call addServerRep for all reps that are in localReps but not in serverReps
	for (const localRep of localReps) {
		if (!serverIds.has(localRep.id)) {
			addServerRep(localRep, profileId);
		}
	}
}

const serverSyncQueue: (KitemRep & {profileId: string})[] = [];
let serverSyncTimer: ReturnType<typeof setTimeout> | null = null;

export function queueServerRep(rep: KitemRep, profileId: string) {
	const qr = Object.assign({}, rep, {profileId: profileId});
	serverSyncQueue.push(qr);

	// Start the queue processing if it's not already running
	if (serverSyncTimer === null) {
		processServerQueue();
	}
}

function processServerQueue() {
	// if (serverSyncQueue.length === 0) {
	// 	serverSyncTimer = null; // Nothing to process, stop the timer
	// 	return;
	// }
	if (processServerQueueItem())
		serverSyncTimer = setTimeout(processServerQueue, 1000); // wait 1 second before processing next item
	else
		serverSyncTimer = null;
}

function processServerQueueItem() {
	const itemToSync = serverSyncQueue.shift(); // removes the first element from the queue
  
	if (itemToSync) {
		logger.debug("Synching", itemToSync);
		postServerRep(itemToSync, itemToSync.profileId)
			// .then(() => {
			// 	// Process next item after a delay
			// 	serverSyncTimer = setTimeout(processServerQueue, 1000); // wait 1 second before processing next item
			// })
			.catch((error) => {
				// Handle failure: might re-queue or stop processing based on your requirements
				logger.error("Error processing queue:", error);
				// serverSyncTimer = setTimeout(processServerQueue, 1000); // try again after 1 second
			});
		return true;
	} else {
		return false;
	}
}



async function postServerRep(rep: KitemRep, profileId: string) {
	assert(process.env.REACT_APP_HYDROGEN_ENDPOINT_REPS_CREATE, "REACT_APP_HYDROGEN_ENDPOINT_REPS_CREATE not set");
	const response = await fetch(
		process.env.REACT_APP_HYDROGEN_ENDPOINT_REPS_CREATE,
		{
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(rep),
		}
	)
	// You might want to check the response, e.g.,
	if (!response.ok) {
		throw new Error(`Server responded with status: ${response.status}`);
	}
}

export async function getServerReps(profileId: string): Promise<KitemRep[]> {
	// try {
		assert(process.env.REACT_APP_HYDROGEN_ENDPOINT_REPS_BYPROFILE, "REACT_APP_HYDROGEN_ENDPOINT_REPS_BYPROFILE not set");
		const response = await fetch(process.env.REACT_APP_HYDROGEN_ENDPOINT_REPS_BYPROFILE+profileId);
		if (!response.ok) {
			throw new Error('Failed to fetch data from the server');
		}
		const data = await response.json();
		assert(Array.isArray(data), "Server did not return array");
		const reps: KitemRep[] = [];
		data.forEach(item => {
			if (typeof item?.date === "string") {
				item.date = parseISO(item.date);
			}
			if (!isValidKitemRep(item, ["profileId", "updated_at", "created_at"])) {
				logger.error('Invalid KitemRep object received:', item);
			} else {
				const newRep: KitemRep = {
					id: item.id,
					date: item.date,
					success: item.success,
					kitemId: item.kitemId,
				};
				assert(isValidKitemRep(newRep), "New kitem rep is invalid despite checking previously");
				reps.push(newRep);
			}
		});
		return reps;
	// } catch (error) {
	// 	logger.error('Error fetching server reps:', error);
	// }
}

export function isValidKitemRep(item: any, toleratedFields: string[] = []): boolean {
	// Check if the item has all required properties and their types are correct
	if (typeof item !== 'object')
		return false;
	// Check if there are no unexpected fields
	const allowedFields = ["id", "date", "success", "kitemId", ...toleratedFields];
	if (Object.keys(item).some(field => !allowedFields.includes(field)))
		return false;
	return (
	  typeof item === 'object' &&
	  typeof item.id === 'string' &&
	  typeof item.date === 'object' &&
	  item.date instanceof Date &&
	  !isNaN(item.date.getTime()) &&
	  typeof item.success === 'boolean' &&
	  typeof item.kitemId === 'string'
	);
}

// function _getServerReps(): Promise<any> {
// 	fetch(
// 		//@ts-ignore
// 		"http://127.0.0.1:8000/api/reps/profileId/foo",
// 		{
// 			method: 'GET', // *GET, POST, PUT, DELETE, etc.
// 			// mode: 'cors', // no-cors, *cors, same-origin
// 			headers: {
// 				'Content-Type': 'application/json',
// 			},
// 			// body: JSON.stringify(request),
// 		}
// 	)
// 	.then((response: Response) => {
// 		if (!response.ok) {
// 			throw new Error('Network response was not OK. Status code '+response.status+' / '+response.statusText);
// 		}
// 		return response.json()
// 	})
// 	.then((jsonResponse: unknown) => {
// 		logger.debug("Server reps response", jsonResponse);
// 		const reps: KitemRep[] = [];
// 		assert(Array.isArray(jsonResponse), "Server reps response was not an array as expected");
// 		const errors = [];
// 		const addError = (message: string, item: any) => errors.push({message: message, item: any});
// 		jsonResponse.forEach((item: unknown) => {
// 			if (typeof item !== "object") {
// 				addError("Item is not an object", item);
// 				return;
// 			}
// 			if (typeof item.id !== "string")
// 			const newRep = {

// 			}
// 			if (typeof item["id"])
// 		});
// 		return jsonResponse;
// 	})
// 	.catch((e: any) => {
// 		const message = ""+e;
// 		logger.error(message);
// 		Sentry.captureException(e);
// 	});
// }
