import { KitemRep } from "../contexts/LearningContext";
import { addMinutes, addDays, addMilliseconds} from 'date-fns';

export const schedulerV2 = (reps: KitemRep[]): Date => {
	if (reps.length === 0)
		return new Date();
	
	let longestInterval = null;
	let streak = null;

	for (let i = reps.length - 1; i >= 1 && reps[i].success && reps[i-1].success; i--) {
		const cur = reps[i].date.getTime();
		const prev = reps[i-1].date.getTime();
		const interval = cur - prev;
		if (!longestInterval || interval > longestInterval)
			longestInterval = interval;

		streak = reps[reps.length-1].date.getTime() - reps[i-1].date.getTime();
	}

	if (!longestInterval || !streak) {
		if (reps[reps.length-1].success) {
			// item just learned or relearned successfully. Check again shortly.
			return addMinutes(reps[reps.length-1].date, 10);
		}
		return new Date();
	} else {
		const lastRepDate = reps[reps.length - 1].date;
		if (longestInterval < 600000) {
			//Falls longestInterval kleiner als 10 Minuten, 10 Minuten zurückgeben
			return addMinutes(lastRepDate, 10);
		} else if (longestInterval < 86400000) {
			//Falls longestInterval kleiner als ein Tag immer ein Tag nach jetzt zurückgeben
			return addDays(lastRepDate, 1);
		} else {
			const maxInterval = 86400000 * 365 * 10;
			// const interval = longestInterval * Math.exp((streak / 86400000) * 0.06);
			// const interval = streak * 1.05;
			const interval = longestInterval * 2;
			// return addMilliseconds(lastRepDate, streak * 1.05);
			return addMilliseconds(lastRepDate, interval < maxInterval ? interval : maxInterval);
		}
	}
}