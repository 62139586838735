import { Button } from '@mui/material';
import React, { useState } from 'react';
import SMCEditDialog from '../components/SMCEditDialog';
import blob2DataURL from '../services/blob2dataurl';
import logger from '../services/logger';
import RecorderCardController from './RecorderCardController';


interface SMCEditorControllerProps {
	question: string|null,
	rightAnswer: string|null,
	wrongAnswers: string[],
	onCancel(): void,
	onSave(question: string, rightAnswer: string, wrongAnswers: string[]): void,
}

export default function SMCEditorController(props: SMCEditorControllerProps) {
	const [qRecUrl, setQRecUrl] = useState<string | null>(props.question);
	const [rightAnswerUrl, setRightAnswerUrl] = useState<string | null>(props.rightAnswer);
	const [wrongAnswerUrls, setWrongAnswerUrls] = useState<(string|null)[]>(props.wrongAnswers.length > 0 ? props.wrongAnswers : [null]);



	const questionCard = <RecorderCardController variant="question" recordingUrl={qRecUrl} onNewRecording={(blob) => {
		logger.debug("New recording", blob);
		blob2DataURL(blob).then(dataurl => setQRecUrl(dataurl));
	}}
		// moreMenu={[
		// 	["In Text ändern", () => {
		// 		logger.debug("In Text ändern");
		// 	}]
		// ]}
	/>;
	const rightAnswerCard = <RecorderCardController variant="rightAnswer" recordingUrl={rightAnswerUrl} onNewRecording={(blob) => {
		logger.debug("New recording", blob);
		blob2DataURL(blob).then(dataurl => setRightAnswerUrl(dataurl));
	}}/>;
	const wrongAnswerCards = wrongAnswerUrls.map((a, idx) => <RecorderCardController deleteable={true} variant="wrongAnswer" recordingUrl={a} onNewRecording={(blob) => {
		logger.debug("New recording", blob);
		blob2DataURL(blob).then(dataurl => {
			setWrongAnswerUrls(wrongAnswerUrls.map((oldAnswer, oldIdx) => oldIdx === idx ? dataurl : oldAnswer));
		});
		}}
		moreMenu={wrongAnswerUrls.length > 1 ?
			[
				["Löschen", () => {
					logger.debug("Löschen");
					setWrongAnswerUrls(old => old.filter(o => o !== a));
				}]
			]
			: undefined
		}
	/>);

	const handleSave = () => {
		function isString(item: string | null): item is string {
			return typeof item === "string";
		}
		if (qRecUrl && rightAnswerUrl)
			props.onSave(qRecUrl, rightAnswerUrl, wrongAnswerUrls.filter(isString));
		else
			throw new Error("Could not save, possibly incomplete data?");
	}

	const handleAdd = () => {
		setWrongAnswerUrls([...wrongAnswerUrls, null]);
	}

	const saveable = qRecUrl !== null && rightAnswerUrl !== null && wrongAnswerUrls.length > 0 && wrongAnswerUrls[0] !== null;

	return <>
		{/* <Button href={qRecUrl || undefined} sx={{position: "absolute", zIndex: 9999}}>Download</Button> */}
		<SMCEditDialog question={questionCard} rightAnswer={rightAnswerCard} wrongAnswers={wrongAnswerCards} onCancel={props.onCancel} onSave={handleSave} saveable={saveable} onAdd={handleAdd}/>
	</>;
}