import { KitemType } from "../contexts/LearningContext";
import { WrittenAnswerInputType, WrittenAnswerKitem } from "../controller/WrittenAnswerController";



export const ZahlenTippenKitems: WrittenAnswerKitem[] = [
	{
		id: "Zahlen_tippen_1",
		type: KitemType.WrittenAnswer,
		topic: "zahlen_1-10",
		question: {
			synthText: "10",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"10",
		],
	},
	{
		id: "Zahlen_tippen_11",
		type: KitemType.WrittenAnswer,
		topic: "zahlen_1-10",
		question: {
			audioUrl: require('../assets/zahlen/11.m4a'),
			transcript: "11",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"11",
		],
	},
	{
		id: "Zahlen_tippen_12",
		type: KitemType.WrittenAnswer,
		topic: "zahlen_1-10",
		question: {
			audioUrl: require('../assets/zahlen/12.m4a'),
			transcript: "12",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"12",
		],
	},
	{
		id: "Zahlen_tippen_13",
		type: KitemType.WrittenAnswer,
		topic: "zahlen_1-10",
		question: {
			audioUrl: require('../assets/zahlen/13.m4a'),
			transcript: "13",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"13",
		],
	},
	{
		id: "Zahlen_tippen_14",
		type: KitemType.WrittenAnswer,
		topic: "zahlen_1-10",
		question: {
			synthText: "14",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"14",
		],
	},
	{
		id: "Zahlen_tippen_15",
		type: KitemType.WrittenAnswer,
		topic: "zahlen_1-10",
		question: {
			synthText: "15",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"15",
		],
	},
	{
		id: "Zahlen_tippen_16",
		type: KitemType.WrittenAnswer,
		topic: "zahlen_1-10",
		question: {
			synthText: "16",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"16",
		],
	},
];