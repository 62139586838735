import React, { useRef, useState } from 'react';
import DialogsContext, { AlertProps, PromptOptions } from './DialogsContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import logger from '../services/logger';
import TextField from '@mui/material/TextField';
import { DialogContentText } from '@mui/material';

interface Alert {
	message: string | React.ReactNode,
	dialog: React.ReactNode,
}

interface Props {
	children: React.ReactNode,
}
export default function DialogsProvider(props: Props) {
	const [showPrompt, setShowPrompt] = useState<string|null>(null);
	const [promptOptions, setPromptOptions] = useState<PromptOptions>({});
	const [alerts, setAlerts] = useState<Alert[]>([]);
	const promRes = useRef<((value: string|null) => void)|null>(null);
	const promptValue = useRef("");

	const handleCancel = () => {
		if (promRes.current)
			promRes.current(null);
		setShowPrompt(null);
	}

	const handleOk = () => {
		if (promRes.current)
			promRes.current(promptValue.current);
		setShowPrompt(null);
	}

	const alertCreate = (props: AlertProps) => {
		logger.info("Displaying alert: "+props.message);
		const handleClose = () => {
			const newAlerts = alerts.filter(a => a.message !== props.message);
			setAlerts(newAlerts);
		}
		const newAlert: Alert = {
			message: props.message,
			dialog: <>
				<Dialog open={true} onClose={handleClose} fullScreen={props.fullScreen}>
					{props.title && <DialogTitle>{props.title}</DialogTitle>}
					<DialogContent>
						{typeof props.message === "string" &&
							<DialogContentText sx={{whiteSpace: "pre-line"}}>{props.message}</DialogContentText>
						}
						{typeof props.message !== "string" &&
							<>{props.message}</>
						}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</>,
		};
		const newAlerts = [...alerts, newAlert];
		setAlerts(newAlerts);
	}

	const contextValue = {
		prompt: (message: string, options?: PromptOptions) => {
			logger.debug("Generating prompt");
			return new Promise<string|null>((resolve, reject) => {
				promRes.current = resolve;
				promptValue.current = "";
				setShowPrompt(message);
				setPromptOptions(options || {})
			})
		},
		alert: alertCreate,
	}

	return (
		<DialogsContext.Provider value={contextValue}>
			{showPrompt !== null && <>
				<Dialog open={true}>
					<DialogContent>
						<TextField id="standard-basic" autoFocus label={showPrompt} variant="standard"
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								promptValue.current = event.target.value;
							}}
							onKeyDown={(ev) => {
								if (ev.key === 'Enter') {
									handleOk();
									ev.preventDefault();
								}
							}}
							type={promptOptions.type}
							/>
					</DialogContent>
					<DialogActions>
						<Button color="inherit" onClick={handleCancel}>Abbrechen</Button>
						<Button onClick={handleOk}>
							Ok
						</Button>
					</DialogActions>
			</Dialog>
			</>}
			{alerts.map(alert => alert.dialog)}
			{props.children}
		</DialogsContext.Provider>
	);
}
