import { AnySpecificKitem } from "../AnySpecificKitem";
import { KitemType } from "../contexts/LearningContext";
import { WrittenAnswerInputType } from "../controller/WrittenAnswerController";



export const ZahlenZiffern10_19Kitems: (AnySpecificKitem)[] = [
	{
		id: "Zahlen_10",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "10"
		},
		answers: [
			{
				synthText: "1",
				correct: false,
			},
			{
				synthText: "100",
				correct: false,
			},
			{
				synthText: "70",
				correct: false,
			},
			{
				synthText: "10",
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_11",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "11"
		},
		answers: [
			{
				synthText: "11",
				correct: true,
			},
			{
				synthText: "10",
				correct: false,
			},
			{
				synthText: "12",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_11_raketen",
		type: KitemType.Rockets,
		topic: "zahlen",
		setId: "10-13",
		targetId: "11",
	},
	{
		id: "Zahlen_12",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "12"
		},
		answers: [
			{
				synthText: "12",
				correct: true,
			},
			{
				synthText: "10",
				correct: false,
			},
			{
				synthText: "14",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_12_raketen",
		type: KitemType.Rockets,
		topic: "zahlen",
		setId: "10-13",
		targetId: "12",
	},
	{
		id: "Zahlen_13",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "13"
		},
		answers: [
			{
				synthText: "13",
				correct: true,
			},
			{
				synthText: "12",
				correct: false,
			},
			{
				synthText: "17",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_14",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "14"
		},
		answers: [
			{
				synthText: "14",
				correct: true,
			},
			{
				synthText: "12",
				correct: false,
			},
			{
				synthText: "17",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_15",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "15"
		},
		answers: [
			{
				synthText: "15",
				correct: true,
			},
			{
				synthText: "50",
				correct: false,
			},
			{
				synthText: "18",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_16",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "16"
		},
		answers: [
			{
				synthText: "16",
				correct: true,
			},
			{
				synthText: "60",
				correct: false,
			},
			{
				synthText: "18",
				correct: false,
			},
		],
		hideAnswerText: true,
	},

	{
		id: "Zahlen_17",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "17"
		},
		answers: [
			{
				synthText: "17",
				correct: true,
			},
			{
				synthText: "70",
				correct: false,
			},
			{
				synthText: "11",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_17_tippen",
		type: KitemType.WrittenAnswer,
		topic: "zahlen",
		question: {
			imageUrl: require('../assets/zahlen/set-17v20.svg').default,
			alt: "Eine Menge von 17 Punkten",
		},
		inputType: WrittenAnswerInputType.number,
		answers: [
			"17",
		],
	},
	{
		id: "Zahlen_18",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "18"
		},
		answers: [
			{
				synthText: "18",
				correct: true,
			},
			{
				synthText: "80",
				correct: false,
			},
			{
				synthText: "16",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_19",
		type: KitemType.SMC,
		topic: "zahlen",
		question: {
			displayText: "19"
		},
		answers: [
			{
				synthText: "19",
				correct: true,
			},
			{
				synthText: "90",
				correct: false,
			},
			{
				synthText: "16",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
];