import { MarkdownStyle } from "../components/MarkdownQuestion";
import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";


export const JanoschKitems: SMCKitem[] = [
	{
		id: "stadt",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			synthText: "In welcher Stadt wohnst Du?"
		},
		answers: [
			{
				synthText: "Freiburg",
				correct: true,
			},
			{
				synthText: "Staufen",
				correct: false,
			},
			{
				synthText: "Eichstetten",
				correct: false,
			},
		],
	},
	{
		id: "strasse",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			synthText: "In welcher Straße wohnst Du?"
		},
		answers: [
			{
				synthText: "Im Grün",
				correct: true,
			},
			{
				synthText: "Hauptstraße",
				correct: false,
			},
			{
				synthText: "Schwimmbadstraße",
				correct: false,
			},
		],
	},
	{
		id: "strasse_kartaeuser",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			synthText: "In welcher Straße wohnst Du?"
		},
		answers: [
			{
				synthText: "Kartäuserstraße",
				correct: true,
			},
			{
				synthText: "Im Grün",
				correct: false,
			},
			{
				synthText: "Schwimmbadstraße",
				correct: false,
			},
		],
	},
	{
		id: "hausnummer",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			synthText: "Welche Nummer hat das Haus in dem Du wohnst?"
		},
		answers: [
			{
				synthText: "10",
				correct: true,
			},
			{
				synthText: "12",
				correct: false,
			},
			{
				synthText: "11",
				correct: false,
			},
		],
	},
	{
		id: "hausnummer_kartaeuser",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			synthText: "Welche Nummer hat das Haus in dem Du wohnst?"
		},
		answers: [
			{
				synthText: "118a",
				correct: true,
			},
			{
				synthText: "10",
				correct: false,
			},
			{
				synthText: "11",
				correct: false,
			},
		],
	},
	{
		id: "papas_telefonnummer_0162",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind die ersten vier Zahlen von Papas Telefonnummer?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "0162",
				correct: true,
			},
			{
				synthText: "0163",
				correct: false,
			},
			{
				synthText: "1162",
				correct: false,
			},
		],
	},
	{
		id: "papas_telefonnummer_773",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind die drei Zahlen von Papas Telefonnummer nach 0162?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "773",
				correct: true,
			},
			{
				synthText: "772",
				correct: false,
			},
			{
				synthText: "737",
				correct: false,
			},
		],
	},
	{
		id: "papas_telefonnummer_73",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind die zwei Zahlen von Papas Telefonnummer nach 0162 773?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "73",
				correct: true,
			},
			{
				synthText: "72",
				correct: false,
			},
			{
				synthText: "19",
				correct: false,
			},
		],
	},
	{
		id: "papas_telefonnummer_19",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind die zwei Zahlen von Papas Telefonnummer nach 0162 773 73?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "19",
				correct: true,
			},
			{
				synthText: "72",
				correct: false,
			},
			{
				synthText: "19",
				correct: false,
			},
		],
	},
	{
		id: "papas_telefonnummer",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was ist Papas vollständige Telefonnummer?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "0162 773 73 19",
				correct: true,
			},
			{
				synthText: "0162 772 71 39",
				correct: false,
			},
			{
				synthText: "0162 773 77 19",
				correct: false,
			},
		],
	},
	{
		id: "mamas_telefonnummer_0162",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind bei Mamas Telefonnummer die ersten vier Zahlen?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "0162",
				correct: true,
			},
			{
				synthText: "0163",
				correct: false,
			},
			{
				synthText: "0172",
				correct: false,
			},
		],
	},
	{
		id: "mamas_telefonnummer_13",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind bei Mamas Telefonnummer die zwei Zahlen nach 0162?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "0162 13",
				correct: true,
			},
			{
				synthText: "0162 14",
				correct: false,
			},
			{
				synthText: "0162 31",
				correct: false,
			},
		],
	},
	{
		id: "mamas_telefonnummer_58",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind bei Mamas Telefonnummer die zwei Zahlen nach 0162 13?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "0162 13 58",
				correct: true,
			},
			{
				synthText: "0162 13 45",
				correct: false,
			},
			{
				synthText: "0162 13 14",
				correct: false,
			},
		],
	},
	{
		id: "mamas_telefonnummer_148",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was sind bei Mamas Telefonnummer die drei Zahlen nach 0162 13 58?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "0162 13 58 148",
				correct: true,
			},
			{
				synthText: "0162 13 58 158",
				correct: false,
			},
			{
				synthText: "0162 13 58 481",
				correct: false,
			},
		],
	},
	{
		id: "mamas_telefonnummer",
		type: KitemType.SMC,
		topic: "janosch",
		question: {
			displayText: "Was ist Mamas vollständige Telefonnummer?",
			markdownStyle: MarkdownStyle.Normal,
		},
		answers: [
			{
				synthText: "0162 13 58 148",
				correct: true,
			},
			{
				synthText: "0162 14 58 148",
				correct: false,
			},
			{
				synthText: "0162 148 13 58",
				correct: false,
			},
		],
	},
];