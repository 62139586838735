import { DisplayText, DisplayImage, isDisplayImage, isDisplayText } from "../App";
import { isSpeechSynth } from "../components/SpeechSynth";
import { AudioUrl, isAudioUrl, SpeechSynth } from "./sound-player";

export function getText(content: SpeechSynth | AudioUrl | DisplayText | DisplayImage): string | undefined {
	if (isSpeechSynth(content))
		return content.synthText;
	else if (isDisplayText(content))
		return content.displayText.replace(/\*/g, ''); // remove *, because it is used for things like MA*MA*
	else if (isAudioUrl(content) && content.transcript)
		return content.transcript;
	else if (isDisplayImage(content) && content.alt)
		return content.alt;
	else
		return undefined;
}