import { Box, useTheme } from '@mui/material';
import React from 'react';
import AudioButton from './AudioButton';
import { ImageSetItem } from '../controller/ImageChooserController';

interface ImageQuadViewProps {
  images: ImageSetItem[];
  onImageClick: (index: number) => void;
  focus: number | null;
  audioUrl: string;
}

function ImageChooserQuadView(props: ImageQuadViewProps) {
  const theme = useTheme();

  const defaultStyle = (index: number) => ({
	position: "absolute",
	width: "50%",
	height: "50%",
	transition: 'opacity 0.5s, height 0.5s, width 0.5s, transform 0.5s, top 0.5s, left 0.5s',
	top: `${index >= 2 ? '50%' : '0%'}`,
	left: `${index % 2 === 0 ? '0%' : '50%'}`,
	backgroundImage: `url(${props.images[index].imageUrl})`,
	backgroundSize: props.images[index].imageContain === true ? "contain" : "cover",
	backgroundPosition: "center",
	backgroundRepeat: 'no-repeat',
	zIndex: 0,
	opacity: props.focus !== null && props.focus !== index ? 0 : 1,
  });

  const focusedStyle = (index: number) => ({
	position: "absolute",
	width: "100%",
	height: "100%",
	transition: 'opacity 0.5s, height 0.5s, width 0.5s, transform 0.5s, top 0.5s, left 0.5s',
	top: 0,
	left: 0,
	backgroundImage: `url(${props.images[index].imageUrl})`,
	backgroundSize: props.images[index].imageContain === true ? "contain" : "cover",
	backgroundPosition: "center",
	backgroundRepeat: 'no-repeat',
	zIndex: 0,
	opacity: props.focus !== null && props.focus !== index ? 0 : 1,
  });

  return (
	<>
	  {props.images.map((_, index) => (
		<Box
		  key={index}
		  sx={props.focus === index ? focusedStyle(index) : defaultStyle(index)}
		  onClick={() => props.onImageClick(index)}
		></Box>
	  ))}
	  <Box
		sx={{
		  position: "absolute",
		  top: '50%',
		  left: '50%',
		  transform: 'translate(-50%, -50%)',
		  zIndex: 2,
		  opacity: props.focus === null ? 1 : 0,
		  transition: "opacity 0.2s",
		  padding: 6,
		}}
	  >
		<AudioButton audio={{audioUrl: props.audioUrl}} size='xxl'/>
	  </Box>
	</>
  );
}

export default ImageChooserQuadView;