import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";


export const ZahlenZiffern1_9Kitems: SMCKitem[] = [
	{
		id: "Zahlen_1",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "1"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/7.m4a'),
				transcript: "7",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/9.m4a'),
				transcript: "9",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/1.m4a'),
				transcript: "1",
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_2",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "2"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/2.m4a'),
				transcript: "2",
				correct: true,
			},
			{
				audioUrl: require('../assets/zahlen/6.m4a'),
				transcript: "6",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/8.m4a'),
				transcript: "8",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_3",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "3"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/2.m4a'),
				transcript: "2",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/3.m4a'),
				transcript: "3",
				correct: true,
			},
			{
				audioUrl: require('../assets/zahlen/8.m4a'),
				transcript: "8",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_4",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "4"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/1.m4a'),
				transcript: "1",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/5.m4a'),
				transcript: "5",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/4.m4a'),
				transcript: "4",
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_5",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "5"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/1.m4a'),
				transcript: "1",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/5.m4a'),
				transcript: "5",
				correct: true,
			},
			{
				audioUrl: require('../assets/zahlen/4.m4a'),
				transcript: "4",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_6",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "6"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/1.m4a'),
				transcript: "1",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/9.m4a'),
				transcript: "9",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/5.m4a'),
				transcript: "5",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/6.m4a'),
				transcript: "6",
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_7",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "7"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/7.m4a'),
				transcript: "7",
				correct: true,
			},
			{
				audioUrl: require('../assets/zahlen/9.m4a'),
				transcript: "9",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/1.m4a'),
				transcript: "1",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_8",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "8"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/6.m4a'),
				transcript: "6",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/8.m4a'),
				transcript: "8",
				correct: true,
			},
			{
				audioUrl: require('../assets/zahlen/9.m4a'),
				transcript: "9",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "Zahlen_9",
		type: KitemType.SMC,
		topic: "zahlen_1-10",
		question: {
			displayText: "9"
		},
		answers: [
			{
				audioUrl: require('../assets/zahlen/6.m4a'),
				transcript: "6",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/8.m4a'),
				transcript: "8",
				correct: false,
			},
			{
				audioUrl: require('../assets/zahlen/9.m4a'),
				transcript: "9",
				correct: true,
			},
		],
		hideAnswerText: true,
	},
];