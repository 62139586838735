import React from 'react';


export interface KitemSettings {
	isPaused(kitemId: string): boolean,
	setPaused(kitemId: string, paused: boolean): void,
}


const KitemSettingsContext = React.createContext<KitemSettings>({
	isPaused: (kitemId: string) => {throw new Error("missing KitemSettingsProvider")},
	setPaused: (kitemId: string, paused: boolean) => {throw new Error("missing KitemSettingsProvider")},
});

export default KitemSettingsContext;
