import React, { useState, useContext } from 'react';
import SettingsView from '../components/SettingsView';
import DialogsContext from '../contexts/DialogsContext';
import LearningContext, { KitemRep } from '../contexts/LearningContext';
import logger from '../services/logger';
import UserManagerContext from '../contexts/UserManagerContext';
import { fullSync, queueServerRep } from '../services/repSyncer';
import { ProfileInformation, profileList } from '../services/profiles';


interface Props {
}

export default function SettingsViewController(props: Props) {
	const [newLimitError, setNewLimitError] = useState<null|string>(null);
	const [hardLimitError, setHardLimitError] = useState<null|string>(null);
	const [totalLimitError, setTotalLimitError] = useState<null|string>(null);
	const learning = useContext(LearningContext);
	const dialogs = useContext(DialogsContext);
	const userManager = useContext(UserManagerContext);

	const handleExport = async () => {
		const currentDate = new Date().toISOString().slice(0, 10);
		const exportData = {
			reps: await learning.getReps(),
			kitems: await learning.getKitems("misc"),
		};
		const jsonBlob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
		const downloadUrl = URL.createObjectURL(jsonBlob);
		const a = document.createElement('a');
		a.href = downloadUrl;
		a.download = `repty-export-${currentDate}.json`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	const handleDeletePlan = () => {
		learning.deleteTodaysLearningPackages();
		dialogs.alert({message: "Gelöscht."});
	}

	const validateLimit = (limit: number) => {
		if (typeof limit !== "number")
			return "Unexpected format: "+typeof limit;
		if (isNaN(limit))
			return "Must not be NaN"
		if (limit < 1)
			return "Must be more than 0";
		return null;
	}

	const handleMaxHardItemsChange = (limit: number) => {
		logger.info("Update max hard items", limit, typeof limit);
		const error = validateLimit(limit);
		if (error) {
			logger.info("Invalid user input: "+error);
			setHardLimitError(error);
		} else {
			learning.setLearningPackageMaxHardItems(limit);
			setHardLimitError(null);
		}
	}
	const handleMaxTotalItemsChange = (limit: number) => {
		logger.info("Update max total items", limit, typeof limit);
		const error = validateLimit(limit);
		if (error) {
			logger.info("Invalid user input: "+error);
			setTotalLimitError(error);
		} else {
			learning.setLearningPackageMaxTotalItems(limit);
			setTotalLimitError(null);
		}
	}
	const handleMaxNewItemsChange = (limit: number) => {
		logger.info("Update max new items", limit, typeof limit);
		const error = validateLimit(limit);
		if (error) {
			logger.info("Invalid user input: "+error);
			setNewLimitError(error);
		} else {
			learning.setLearningPackageMaxNewItems(limit);
			setNewLimitError(null);
		}
	}

	const handleSyncClick = async () => {
		// const mockAddLocal = (rep: KitemRep) => {
		// 	logger.debug("Mock adding rep "+rep.id, rep);
		// }
		const localReps: KitemRep[] = await learning.getReps();
		logger.info("Synching reps.", localReps);
		fullSync(userManager.currentUserId, localReps, learning.addRepObject, queueServerRep)
	}

	let choosableProfiles: ProfileInformation[] = [];
	const currentProfile = profileList.find(p => p.id === userManager.currentUserId);
	if (currentProfile !== undefined) {
		choosableProfiles = [...profileList];
	}
	// if (["9ebd7aa3-b9ab-49f2-b4f9-64ecf9d79e92", "46434d5c-345f-443d-bc1a-187d53ee4f04", "8531dd2e-6ed1-45df-960f-1d5b80980025"].includes(userManager.currentUserId)) {
	// 	choosableProfiles = profileList.filter(p => ["Janosch", "Paul"].includes(p.name));
	// }

	const handleChangeProfile = (profileId: string) => {
		learning.deleteAllLocalReps();
		userManager.setUserId(profileId);
		// window.location.reload();
	}

	return (
		<React.Fragment>
			<SettingsView
				learningPackageMaxHardItems={learning.learningPackageMaxHardItems}
				learningPackageMaxHardItemsError={hardLimitError}
				onLearningPackageMaxHardItemsChange={handleMaxHardItemsChange}
				learningPackageMaxNewItems={learning.learningPackageMaxNewItems}
				learningPackageMaxNewItemsError={newLimitError}
				onLearningPackageMaxNewItemsChange={handleMaxNewItemsChange}
				learningPackageMaxTotalItems={learning.learningPackageMaxTotalItems}
				onLearningPackageMaxTotalItemsChange={handleMaxTotalItemsChange}
				onClickExport={handleExport}
				onClickDeleteTodaysLearningPackage={handleDeletePlan}
				learningPackageMaxTotalItemsError={totalLimitError}
				currentUserId={userManager.currentUserId}
				currentProfileName={currentProfile?.name}
				onClickSync={handleSyncClick}
				choosableProfiles={choosableProfiles}
				onChangeProfile={handleChangeProfile}
			/>
		</React.Fragment>
	);
}
