import { MarkdownStyle } from "../components/MarkdownQuestion";
import { KitemType } from "../contexts/LearningContext";
import { RocketKitem } from "../controller/RocketGameController";
import { SMCKitem } from "../controller/SMCController";



export const BuchstabenGrossKitems: (SMCKitem|RocketKitem)[] = [
	// {
	// 	id: "buchstaben_S_r",
	// 	type: KitemType.SMC,
	// 	topic: "buchstaben_gross",
	// 	question: {
	// 		audioUrl: require('../assets/lesen/a-apfel.mp3'),
	// 		imageUrl: require('../assets/lesen/seehund.jpg'),
	// 		transcript: "Mit welchem Buchstaben beginnt das Wort Seehund?",
	// 	},
	// 	answers: [
	// 		{
	// 			displayText: "S",
	// 			correct: true,
	// 		},
	// 		{
	// 			displayText: "E",
	// 			correct: false,
	// 		},
	// 		{
	// 			displayText: "B",
	// 			correct: false,
	// 		},
	// 	],
	// 	hideAnswerText: true,
	// 	hideQuestionText: true,
	// },

	{
		id: "buchstaben_A",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "A"
		},
		answers: [
			{
				synthText: "A wie Auto",
				imageUrl: require('../assets/lesen/auto.jpg'),
				correct: true,
			},
			{
				synthText: "E wie Erdbeere",
				imageUrl: require('../assets/lesen/erdbeere.jpg'),
				correct: false,
			},
			{
				synthText: "I wie Igel",
				imageUrl: require('../assets/lesen/igel.jpg'),
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_A_raketen",
		type: KitemType.Rockets,
		topic: "buchstaben_gross",
		setId: "buchstaben_gross",
		targetId: "A",
	},
	{
		id: "buchstaben_A_r",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			audioUrl: require('../assets/lesen/a-apfel.mp3'),
			transcript: "A wie Apfel",
		},
		answers: [
			{
				displayText: "A",
				correct: true,
			},
			{
				displayText: "E",
				correct: false,
			},
			{
				displayText: "B",
				correct: false,
			},
		],
		hideAnswerText: true,
		hideQuestionText: true,
		noSelfEvalMode: true,
	},
	{
		id: "buchstaben_E",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "E"
		},
		answers: [
			{
				synthText: "A wie Auto",
				imageUrl: require('../assets/lesen/auto.jpg'),
				correct: false,
			},
			{
				synthText: "E wie Erdbeere",
				imageUrl: require('../assets/lesen/erdbeere.jpg'),
				correct: true,
			},
			{
				synthText: "I wie Igel",
				imageUrl: require('../assets/lesen/igel.jpg'),
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_E_raketen",
		type: KitemType.Rockets,
		topic: "buchstaben_gross",
		setId: "buchstaben_gross",
		targetId: "E",
	},
	{
		id: "buchstaben_I",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "I"
		},
		answers: [
			{
				synthText: "A wie Auto",
				imageUrl: require('../assets/lesen/auto.jpg'),
				correct: false,
			},
			{
				synthText: "E wie Erdbeere",
				imageUrl: require('../assets/lesen/erdbeere.jpg'),
				correct: false,
			},
			{
				synthText: "I wie Igel",
				imageUrl: require('../assets/lesen/igel.jpg'),
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_O",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "O"
		},
		answers: [
			{
				synthText: "O wie Osterhase",
				imageUrl: require('../assets/lesen/osterhase.jpg'),
				correct: true,
			},
			{
				synthText: "E wie Erdbeere",
				imageUrl: require('../assets/lesen/erdbeere.jpg'),
				correct: false,
			},
			{
				synthText: "I wie Igel",
				imageUrl: require('../assets/lesen/igel.jpg'),
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_U",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "U"
		},
		answers: [
			{
				synthText: "O wie Osterhase",
				imageUrl: require('../assets/lesen/osterhase.jpg'),
				correct: false,
			},
			{
				synthText: "E wie Erdbeere",
				imageUrl: require('../assets/lesen/erdbeere.jpg'),
				correct: false,
			},
			{
				synthText: "U wie Uhu",
				imageUrl: require('../assets/lesen/uhu.jpg'),
				correct: true,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_P",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "P"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/p-pirat.mp3'),
				imageUrl: require('../assets/lesen/pirat.jpg'),
				transcript: "P wie Pirat",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/m-maus.mp3'),
				imageUrl: require('../assets/lesen/maus.jpg'),
				transcript: "M wie Maus",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				imageUrl: require('../assets/lesen/drache.jpg'),
				transcript: "D wie Drache",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_M",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "M"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/p-pirat.mp3'),
				imageUrl: require('../assets/lesen/pirat.jpg'),
				transcript: "P wie Pirat",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/m-maus.mp3'),
				imageUrl: require('../assets/lesen/maus.jpg'),
				transcript: "M wie Maus",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				imageUrl: require('../assets/lesen/drache.jpg'),
				transcript: "D wie Drache",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_N",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "N"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/n-nase.mp3'),
				transcript: "N wie Nase",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/m-maus.mp3'),
				transcript: "M wie Maus",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				transcript: "D wie Drache",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_J",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "J"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/j-jacke.mp3'),
				transcript: "J wie Jacke",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/n-nase.mp3'),
				transcript: "N wie Nase",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/i-igel.mp3'),
				transcript: "I wie Igel",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_MA",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "MA"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/ma.m4a'),
				transcript: "ma",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/pa.webm'),
				transcript: "pa",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/mi.webm'),
				transcript: "mo",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_PA",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "PA"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/pa.webm'),
				transcript: "pa",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/ma.m4a'),
				transcript: "ma",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/mi.webm'),
				transcript: "mo",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_MAMA",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "MA*MA*"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/mama.mp3'),
				transcript: "Mama",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/papa.mp3'),
				transcript: "Papa",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/oma.mp3'),
				transcript: "Oma",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_PAPA",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "PA*PA*"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/papa.mp3'),
				transcript: "Papa",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/mama.mp3'),
				transcript: "Mama",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/oma.mp3'),
				transcript: "Oma",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_OMA",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "O*MA*"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/oma.mp3'),
				transcript: "Oma",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/mama.mp3'),
				transcript: "Mama",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/opa.mp3'),
				transcript: "Opa",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_OPA",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "O*PA*"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/opa.mp3'),
				transcript: "Opa",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/mama.mp3'),
				transcript: "Mama",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/oma.mp3'),
				transcript: "Oma",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_MAI",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "MAI"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/mai.webm'),
				transcript: "Mai",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/mama.mp3'),
				transcript: "Mama",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/oma.mp3'),
				transcript: "Oma",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	// {
	// 	id: "lesen_JUNI",
	// 	type: KitemType.SMC,
	// 	topic: "buchstaben_gross",
	// 	question: {
	// 		displayText: "JUNI"
	// 	},
	// 	answers: [
	// 		{
	// 			audioUrl: require('../assets/lesen/juni.webm'),
	// 			transcript: "Juni",
	// 			correct: true,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/lesen/haus.webm'),
	// 			transcript: "Haus",
	// 			correct: false,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/lesen/nase.webm'),
	// 			transcript: "Nase",
	// 			correct: false,
	// 		},
	// 	],
	// },
	{
		id: "buchstaben_S",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "S"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/s-sonne.webm'),
				imageUrl: require('../assets/lesen/sonne.jpg'),
				transcript: "S wie Sonne",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/i-igel.mp3'),
				imageUrl: require('../assets/lesen/igel.jpg'),
				transcript: "I wie Igel",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				imageUrl: require('../assets/lesen/drache.jpg'),
				transcript: "D wie Drache",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_H",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "H"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/h-haus.webm'),
				imageUrl: require('../assets/lesen/haus.jpg'),
				transcript: "H wie Haus",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/a-affe.m4a'),
				imageUrl: require('../assets/lesen/affe.jpg'),
				transcript: "A wie Affe",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				imageUrl: require('../assets/lesen/drache.jpg'),
				transcript: "D wie Drache",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_MAUS",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "MAUS"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/maus.webm'),
				imageUrl: require('../assets/lesen/maus.jpg'),
				transcript: "Maus",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/affe.m4a'),
				imageUrl: require('../assets/lesen/affe.jpg'),
				transcript: "Affe",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/haus.webm'),
				imageUrl: require('../assets/lesen/haus.jpg'),
				transcript: "Haus",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	// {
	// 	id: "lesen_NASE",
	// 	type: KitemType.SMC,
	// 	topic: "buchstaben_gross",
	// 	question: {
	// 		displayText: "NASE"
	// 	},
	// 	answers: [
	// 		{
	// 			audioUrl: require('../assets/lesen/nase.webm'),
	// 			transcript: "Nase",
	// 			correct: true,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/lesen/opa.mp3'),
	// 			transcript: "Opa",
	// 			correct: false,
	// 		},
	// 		{
	// 			audioUrl: require('../assets/lesen/haus.webm'),
	// 			transcript: "Haus",
	// 			correct: false,
	// 		},
	// 	],
	// },
	{
		id: "buchstaben_K",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "K"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/k-katze.m4a'),
				imageUrl: require('../assets/lesen/katze.jpg'),
				transcript: "K wie Katze",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/b-bagger.m4a'),
				imageUrl: require('../assets/lesen/bagger.jpg'),
				transcript: "B wie Bagger",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/p-pirat.mp3'),
				imageUrl: require('../assets/lesen/pirat.jpg'),
				transcript: "P wie Pirat",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_HAUS",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "HAUS"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/haus.webm'),
				imageUrl: require('../assets/lesen/haus.jpg'),
				transcript: "Haus",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/affe.m4a'),
				imageUrl: require('../assets/lesen/affe.jpg'),
				transcript: "Affe",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/maus.webm'),
				imageUrl: require('../assets/lesen/maus.jpg'),
				transcript: "Maus",
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_K_r",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			audioUrl: require('../assets/lesen/mit-welchem-buchstaben-beginnt-katze.m4a'),
			imageUrl: require('../assets/lesen/katze.jpg'),
			transcript: "Mit welchem Buchstaben beginnt das Wort Katze?",
		},
		answers: [
			{
				displayText: "# K  \n\n*KAT*ZE",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# M  \n\n*MAT*ZE",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# T  \n\n*TAT*ZE",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
		hideQuestionText: true,
		noSelfEvalMode: true,
	},
	{
		id: "buchstaben_D",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "D"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				imageUrl: require('../assets/lesen/drache.jpg'),
				transcript: "D wie Drache",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/b-bagger.m4a'),
				imageUrl: require('../assets/lesen/bagger.jpg'),
				transcript: "B wie Bagger",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/p-pirat.mp3'),
				imageUrl: require('../assets/lesen/pirat.jpg'),
				transcript: "P wie Pirat",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_D_r",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			audioUrl: require('../assets/lesen/mit-welchem-buchstaben-beginnt-drache.m4a'),
			imageUrl: require('../assets/lesen/drache.jpg'),
			transcript: "Mit welchem Buchstaben beginnt das Wort Drache?",
		},
		answers: [
			{
				displayText: "# D  \n\n*DRA*CHE",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# G  \n\n*GRA*CHE",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# H  \n\n*HRA*CHE",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
		hideQuestionText: true,
		noSelfEvalMode: true,
	},
	{
		id: "buchstaben_B",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "B"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/b-bagger.m4a'),
				imageUrl: require('../assets/lesen/bagger.jpg'),
				transcript: "B wie Bagger",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/sch-schlange.m4a'),
				imageUrl: require('../assets/lesen/schlange.jpg'),
				transcript: "Sch wie Schlange",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/i-igel.mp3'),
				imageUrl: require('../assets/lesen/igel.jpg'),
				transcript: "I wie Igel",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_B_r",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			audioUrl: require('../assets/lesen/mit-welchem-buchstaben-beginnt-bagger.m4a'),
			imageUrl: require('../assets/lesen/bagger.jpg'),
			transcript: "Mit welchem Buchstaben beginnt das Wort Bagger?",
		},
		answers: [
			{
				displayText: "# B  \n\nBAGGER",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# H  \n\nHAGGER",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# P  \n\nPAGGER",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
		hideQuestionText: true,
		noSelfEvalMode: true,
	},
	{
		id: "buchstaben_SCH",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "SCH"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/sch-schlange.m4a'),
				imageUrl: require('../assets/lesen/schlange.jpg'),
				transcript: "Sch wie Schlange",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/s-sonne.webm'),
				imageUrl: require('../assets/lesen/sonne.jpg'),
				transcript: "S wie Sonne",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/i-igel.mp3'),
				imageUrl: require('../assets/lesen/igel.jpg'),
				transcript: "I wie Igel",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "lesen_JANOSCH",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "JANOSCH",
			markdownStyle: MarkdownStyle.Large,
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/janosch.mp3'),
				transcript: "Janosch",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/oma.mp3'),
				transcript: "Oma",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/maus.webm'),
				transcript: "Maus",
				correct: false,
			},
		],
	},
	{
		id: "lesen_BANANE",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "BA*NA*NE",
			markdownStyle: MarkdownStyle.Large,
		},
		answers: [
			{
				synthText: "Banane",
				correct: true,
			},
			{
				synthText: "Ananas",
				correct: false,
			},
			{
				synthText: "Ball",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_a",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "a",
		},
		answers: [
			{
				displayText: "# A a",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# E a",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# B a",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_n",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "n",
		},
		answers: [
			{
				displayText: "# N n",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# E n",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# M n",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_o",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "o",
		},
		answers: [
			{
				displayText: "# O o",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# E o",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# D o",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_s",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "s",
		},
		answers: [
			{
				displayText: "# S s",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# B s",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# D s",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_e",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "e",
		},
		answers: [
			{
				displayText: "# E e",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# B e",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# D e",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "lesen_Banane",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "Ba*na*ne",
			markdownStyle: MarkdownStyle.Large,
		},
		answers: [
			{
				synthText: "Banane",
				correct: true,
			},
			{
				synthText: "Ananas",
				correct: false,
			},
			{
				synthText: "Ball",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_i",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "i",
		},
		answers: [
			{
				displayText: "# I i",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# K i",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# D i",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_u",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "u",
		},
		answers: [
			{
				displayText: "# U u",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# M u",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# D u",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_p",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "p",
		},
		answers: [
			{
				displayText: "# P p",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# M p",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# D p",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "lesen_Papa",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "Pa*pa*",
			markdownStyle: MarkdownStyle.Large,
		},
		answers: [
			{
				synthText: "Papa",
				correct: true,
			},
			{
				synthText: "Mama",
				correct: false,
			},
			{
				synthText: "Opa",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_m",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "m",
		},
		answers: [
			{
				displayText: "# M m",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# N m",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# U m",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "lesen_Mama",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "Ma*ma*",
			markdownStyle: MarkdownStyle.Large,
		},
		answers: [
			{
				synthText: "Mama",
				correct: true,
			},
			{
				synthText: "Papa",
				correct: false,
			},
			{
				synthText: "Oma",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_h",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "h",
		},
		answers: [
			{
				displayText: "# H h",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# K h",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# U h",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "lesen_Haus",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "Haus",
			markdownStyle: MarkdownStyle.Large,
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/haus.webm'),
				imageUrl: require('../assets/lesen/haus.jpg'),
				transcript: "Haus",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/affe.m4a'),
				imageUrl: require('../assets/lesen/affe.jpg'),
				transcript: "Affe",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/maus.webm'),
				imageUrl: require('../assets/lesen/maus.jpg'),
				transcript: "Maus",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_k",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "k",
		},
		answers: [
			{
				displayText: "# K k",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# H k",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# P k",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_j",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "j",
		},
		answers: [
			{
				displayText: "# J j",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# I j",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# P j",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_d",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "d",
		},
		answers: [
			{
				displayText: "# D d",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# O d",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# P d",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_b",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "b",
		},
		answers: [
			{
				displayText: "# B b",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# D b",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# P b",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_R",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "R"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/r-rakete.m4a'),
				imageUrl: require('../assets/lesen/rakete.jpg'),
				transcript: "R wie Rakete",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/b-bagger.m4a'),
				imageUrl: require('../assets/lesen/bagger.jpg'),
				transcript: "B wie Bagger",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/i-igel.mp3'),
				imageUrl: require('../assets/lesen/igel.jpg'),
				transcript: "I wie Igel",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_r",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "r",
		},
		answers: [
			{
				displayText: "# R r",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# F r",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# P r",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_L",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "L"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/l-loewe.m4a'),
				imageUrl: require('../assets/lesen/loewe.jpg'),
				transcript: "L wie Löwe",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				imageUrl: require('../assets/lesen/drache.jpg'),
				transcript: "D wie Drache",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/i-igel.mp3'),
				imageUrl: require('../assets/lesen/igel.jpg'),
				transcript: "I wie Igel",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_F",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "F"
		},
		answers: [
			{
				audioUrl: require('../assets/lesen/f-frosch.m4a'),
				imageUrl: require('../assets/lesen/frosch-StockCake-Frog-Pond-Reflection_1713083715.jpg'),
				transcript: "F wie Frosch",
				correct: true,
			},
			{
				audioUrl: require('../assets/lesen/d-drache.mp3'),
				imageUrl: require('../assets/lesen/drache.jpg'),
				transcript: "D wie Drache",
				correct: false,
			},
			{
				audioUrl: require('../assets/lesen/i-igel.mp3'),
				imageUrl: require('../assets/lesen/igel.jpg'),
				transcript: "I wie Igel",
				correct: false,
			},
		],
		hideAnswerText: true,
	},
	{
		id: "buchstaben_f",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			displayText: "f",
		},
		answers: [
			{
				displayText: "# F f",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# R f",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# P f",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
	},
	{
		id: "buchstaben_F_r",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			audioUrl: require('../assets/lesen/mit-welchem-buchstaben-beginnt-fuchs.m4a'),
			imageUrl: require('../assets/lesen/fuchs-StockCake-Majestic-Fox-Stance_1713084047.jpg'),
			transcript: "Mit welchem Buchstaben beginnt das Wort Fuchs?",
		},
		answers: [
			{
				displayText: "# F  \n\nFUCHS",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# M  \n\nMUCHS",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# T  \n\nTUCHS",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
		hideQuestionText: true,
		noSelfEvalMode: true,
	},
	{
		id: "buchstaben_F_r-2",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			audioUrl: require('../assets/lesen/mit-welchem-buchstaben-beginnt-fisch.m4a'),
			imageUrl: require('../assets/lesen/fisch-StockCake-Elegant-Goldfish-Swimming_1713895496.jpg'),
			transcript: "Mit welchem Buchstaben beginnt das Wort Fisch?",
		},
		answers: [
			{
				displayText: "# F  \n\nFISCH",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# L  \n\nLISCH",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# T  \n\nTISCH",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
		hideQuestionText: true,
		noSelfEvalMode: true,
	},
	{
		id: "buchstaben_F_r-3",
		type: KitemType.SMC,
		topic: "buchstaben_gross",
		question: {
			audioUrl: require('../assets/lesen/mit-welchem-buchstaben-beginnt-frosch.m4a'),
			imageUrl: require('../assets/lesen/frosch-StockCake-Frog-Pond-Reflection_1713083715.jpg'),
			transcript: "Mit welchem Buchstaben beginnt das Wort Frosch?",
		},
		answers: [
			{
				displayText: "# F  \n\nFROSCH",
				markdownStyle: MarkdownStyle.Large,
				correct: true,
			},
			{
				displayText: "# L  \n\nLROSCH",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
			{
				displayText: "# E  \n\nEROSCH",
				markdownStyle: MarkdownStyle.Large,
				correct: false,
			},
		],
		hideQuestionText: true,
		noSelfEvalMode: true,
	},
];