import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";


export const MonateKitems: SMCKitem[] = [
	{
		id: "monate_erster",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/erster-monat.m4a'),
			transcript: "Was ist der erste Monat des Jahres?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/januar.m4a'),
				transcript: "Januar",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/februar.m4a'),
				transcript: "Februar",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/dezember.m4a'),
				transcript: "Dezember",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_januar",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-januar.m4a'),
			transcript: "Welcher Monat kommt nach Januar?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/februar.m4a'),
				transcript: "Februar",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/maerz.m4a'),
				transcript: "März",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/dezember.m4a'),
				transcript: "Dezember",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_februar",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-februar.m4a'),
			transcript: "Welcher Monat kommt nach Februar?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/maerz.m4a'),
				transcript: "März",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/januar.m4a'),
				transcript: "Januar",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/april.m4a'),
				transcript: "April",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_maerz",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-maerz.m4a'),
			transcript: "Welcher Monat kommt nach März?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/april.m4a'),
				transcript: "April",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/februar.m4a'),
				transcript: "Februar",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/mai.m4a'),
				transcript: "Mai",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_april",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-april.m4a'),
			transcript: "Welcher Monat kommt nach April?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/mai.m4a'),
				transcript: "Mai",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/juni.m4a'),
				transcript: "Juni",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/maerz.m4a'),
				transcript: "März",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_mai",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-mai.m4a'),
			transcript: "Welcher Monat kommt nach Mai?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/juni.m4a'),
				transcript: "Juni",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/juli.m4a'),
				transcript: "Juli",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/april.m4a'),
				transcript: "April",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_juni",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-juni.m4a'),
			transcript: "Welcher Monat kommt nach Juni?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/juli.m4a'),
				transcript: "Juli",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/august.m4a'),
				transcript: "August",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/mai.m4a'),
				transcript: "Mai",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_juli",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-juli.m4a'),
			transcript: "Welcher Monat kommt nach Juli?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/august.m4a'),
				transcript: "August",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/september.m4a'),
				transcript: "September",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/juni.m4a'),
				transcript: "Juni",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_august",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-august.m4a'),
			transcript: "Welcher Monat kommt nach August?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/september.m4a'),
				transcript: "September",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/oktober.m4a'),
				transcript: "Oktober",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/juli.m4a'),
				transcript: "Juli",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_september",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-september.m4a'),
			transcript: "Welcher Monat kommt nach September?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/oktober.m4a'),
				transcript: "Oktober",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/november.m4a'),
				transcript: "November",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/august.m4a'),
				transcript: "August",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_oktober",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-oktober.m4a'),
			transcript: "Welcher Monat kommt nach Oktober?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/november.m4a'),
				transcript: "November",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/dezember.m4a'),
				transcript: "Dezember",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/september.m4a'),
				transcript: "September",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_november",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-november.m4a'),
			transcript: "Welcher Monat kommt nach November?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/dezember.m4a'),
				transcript: "Dezember",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/januar.m4a'),
				transcript: "Januar",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/oktober.m4a'),
				transcript: "Oktober",
				correct: false,
			},
		],
	},
	{
		id: "monat_nach_dezember",
		type: KitemType.SMC,
		topic: "monate",
		question: {
			audioUrl: require('../assets/monate/monat-nach-dezember.m4a'),
			transcript: "Welcher Monat kommt nach Dezember?",
		},
		answers: [
			{
				audioUrl: require('../assets/monate/januar.m4a'),
				transcript: "Januar",
				correct: true,
			},
			{
				audioUrl: require('../assets/monate/november.m4a'),
				transcript: "November",
				correct: false,
			},
			{
				audioUrl: require('../assets/monate/februar.m4a'),
				transcript: "Februar",
				correct: false,
			},
		],
	},
];