/**
 * Takes a blob and turns it into a data URL
 * @param blob 
 */
export default function blob2DataURL(blob: Blob): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		
		reader.onloadend = function () {
			if (reader.result && typeof reader.result === "string") {
				resolve(reader.result);
			} else {
				reject(new Error("Failed to read the blob as a data URL."));
			}
		};
	
		reader.onerror = function () {
			reject(new Error("Error occurred while reading the blob."));
		};
	});
}