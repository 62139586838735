import { Box, useTheme } from '@mui/material';
import useSleepOnMount from '../services/useSleepOnMount';
import AudioButton from './AudioButton';

interface ImageChooserViewProps {
	firstImage: string,
	firstImageContain?: boolean,
	onFirstImageClick(): void,
	secondImage: string,
	secondImageContain?: boolean,
	onSecondImageClick(): void,
	focus: false | "first" | "second",
	audioUrl: string,
}

function ImageChooserView(props: ImageChooserViewProps) {
	const sleepyFirstImageClick = useSleepOnMount(props.onFirstImageClick);
	const sleepySecondImageClick = useSleepOnMount(props.onSecondImageClick);
	const theme = useTheme();

	return <>
		<Box
			sx={{
				position: "absolute",
				height: props.focus === "first" ? "100%" : "50%",
				width: "100%",
				// backgroundColor: "red",
				transition: 'height 0.3s',
				backgroundColor: theme.palette.background.default,
				backgroundImage: "url("+props.firstImage+")",
				backgroundSize: props.firstImageContain === true ? "contain" : "cover",
				backgroundPosition: "center",
				backgroundRepeat: 'no-repeat',
				zIndex: props.focus === "first" ? 1 : 0,
			}}
			onTouchEnd={sleepyFirstImageClick}
			onMouseUp={sleepyFirstImageClick}
			></Box>
		<Box sx={{
			position: "absolute",
			width: "100%",
			height: props.focus === "second" ? "100%" : "50%",
			top: props.focus === "second" ? "0%" : "50%",
			// backgroundColor: "blue",
			transition: 'height 0.3s, top 0.3s',
			backgroundColor: theme.palette.background.default,
			backgroundImage: "url("+props.secondImage+")",
			backgroundSize: props.secondImageContain === true ? "contain" : "cover",
			backgroundPosition: "center",
			backgroundRepeat: 'no-repeat',
			zIndex: props.focus === "second" ? 1 : 0,
			}}
			onTouchEnd={sleepySecondImageClick}
			onMouseUp={sleepySecondImageClick}
		></Box>
		{/* <Box sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100vh',
		}}>
			<AudioButton audio={{audioUrl: props.audioUrl}} size='xxl'/>
		</Box> */}
		<Box
			sx={{
			position: 'absolute',
			top: `calc(50% - 112px / 2)`, // Centered on the border
			width: '100%', // Span the entire width
			// height: "80px", // Height of the safety margin zone
			//   pointerEvents: 'none', // This makes the Box not clickable
			zIndex: 2, // Make sure it's above the images
			// backgroundColor: "red",
			textAlign: "center",
			opacity: !props.focus ? 1 : 0,
			transition: "opacity 0.2s",
			}}
		>
			<AudioButton audio={{audioUrl: props.audioUrl}} size='xxl'/>
		</Box>
		
	</>;
}

export default ImageChooserView;
