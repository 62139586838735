
import { useContext, useState } from 'react';
import ParentsTopicsView from '../components/ParentsTopicsView';
import LearningContext, { Topic } from '../contexts/LearningContext';
import useTopics, { TopicStatus } from '../services/useTopics';

import assert from 'assert';
import TopicSettingsDialog from '../components/TopicSettingsDialog';
import logger from '../services/logger';


export enum TopicWeight {
	low = "0.5",
	normal = "1.0",
	high = "2.0",
}

interface Props {
}

export default function ParentsTopicsViewController(props: Props) {
	// const [values, setValues] = useState({});
	const learning = useContext(LearningContext);
	const { getTopicsWithState, getTopic } = useTopics();
	const [editedTopic, setEditedTopic] = useState<Topic|null>(null);
	const [topicStatus, setTopicStatus] = useState<TopicStatus>("learning");
	const [topicWeight, setTopicWeight] = useState(TopicWeight.normal);

	const topicsEnriched = getTopicsWithState().map(t => Object.assign({}, t, {
		status: learning.getTopicStatus(t.id),
	}));

	const handleTopicClick = (topicId: string) => {
		const topic = getTopic(topicId);
		assert(topic);
		setEditedTopic(topic);
		setTopicStatus(learning.getTopicStatus(topicId));
		const currentWeight = learning.getTopicWeight(topicId);
		logger.debug("Current weight", currentWeight);
		switch (currentWeight) {
			case 0.5:
				setTopicWeight(TopicWeight.low);
				break;
			case 1.0:
				setTopicWeight(TopicWeight.normal);
				break;
			case 2.0:
				setTopicWeight(TopicWeight.high);
				break;
			default:
				logger.error("Unexpected weight: "+ currentWeight);
		}
	}

	const handleClose = () => setEditedTopic(null);

	const handleStatusChange = (newStatus: TopicStatus) => {
		setTopicStatus(newStatus);
		assert(editedTopic);
		learning.setTopicStatus(editedTopic.id, newStatus);
	};

	const handleWeightChange = (newWeight: TopicWeight) => {
		setTopicWeight(newWeight);
		assert(editedTopic);
		switch (newWeight) {
			case TopicWeight.low:
				learning.setTopicWeight(editedTopic.id, 0.5);
				break;
			case TopicWeight.normal:
				learning.setTopicWeight(editedTopic.id, 1.0);
				break;
			case TopicWeight.high:
				learning.setTopicWeight(editedTopic.id, 2.0);
				break;
			default:
				throw new Error("Unexpected weight: "+ newWeight);
		}
	}
	
	return <>
		<TopicSettingsDialog
			open={editedTopic !== null}
			title={editedTopic?.title || "?"}
			status={topicStatus}
			onStatusChange={handleStatusChange}
			onClose={handleClose}
			weight={topicWeight}
			onWeightChange={handleWeightChange}
		/>
		<ParentsTopicsView topics={topicsEnriched} onTopicClicked={handleTopicClick}/>
	</>;
}
