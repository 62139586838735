import { KitemRep } from "../contexts/LearningContext";
import { addMinutes, addDays, addMilliseconds, isSameDay, format, isBefore } from 'date-fns';

export const nextDueDate = (reps: KitemRep[]): Date => {
	if (reps.length === 0)
		return new Date();
	
	let longestInterval = null;

	for (let i = reps.length - 1; i >= 1 && reps[i].success && reps[i-1].success; i--) {
		const cur = reps[i].date.getTime();
		const prev = reps[i-1].date.getTime();
		const interval = cur - prev;
		if (!longestInterval || interval > longestInterval)
			longestInterval = interval;
	}

	if (!longestInterval) {
		if (reps[reps.length-1].success) {
			// item just learned or relearned successfully. Check again shortly.
			return addMinutes(reps[reps.length-1].date, 10);
		}
		return new Date();
	} else {
		const lastRepDate = reps[reps.length - 1].date;
		if (longestInterval < 600000) {
			//Falls longestInterval kleiner als 10 Minuten, 10 Minuten zurückgeben
			return addMinutes(lastRepDate, 10);
		} else if (longestInterval < 86400000) {
			//Falls longestInterval kleiner als ein Tag immer ein Tag nach jetzt zurückgeben
			return addDays(lastRepDate, 1);
		} else {
			return addMilliseconds(lastRepDate, longestInterval * 1.5);
		}
	}
}
