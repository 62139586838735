import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LearningProvider from './contexts/LearningProvider';
import * as Sentry from "@sentry/react";
import DialogsProvider from './contexts/DialogsProvider';
import UserManagerProvider from './contexts/UserManagerProvider';
import KitemSettingsProvider from './contexts/KitemSettingsProvider';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN === "null" ? undefined : process.env.REACT_APP_SENTRY_DSN,
	integrations: [
	  new Sentry.BrowserTracing({
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
	  }),
	  new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<DialogsProvider>
			<UserManagerProvider>
				<KitemSettingsProvider>
					<LearningProvider>
						<App />
					</LearningProvider>
				</KitemSettingsProvider>
			</UserManagerProvider>
		</DialogsProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// window.onbeforeunload = function(e) {
// 	var dialogText = 'Sind Sie sicher, dass Sie Repty verlassen möchten?';
// 	e.returnValue = dialogText;
// 	e.preventDefault(); // Stop the default behavior
// 	return dialogText;
// };

// After the app initially loads, add an entry to the history stack
window.addEventListener('load', () => {
	window.history.pushState({page: 1}, "", '');
});

// Listen for the popstate event, which is fired when the user hits the back button
window.addEventListener('popstate', (event) => {
	if (event.state && event.state.page === 1) {
		// Show your confirmation dialog here
		// If the user confirms they want to leave, you can allow the default back action
		// If the user decides to stay, push another state to the history or handle it appropriately
		if (userWantsToLeave()) {
			window.close(); // This will try to close the standalone window (but might not work on all browsers)
		} else {
			window.history.pushState({page: 1}, "", ''); // Re-add entry to history to trap the back button again
		}
	}
});

function userWantsToLeave() {
	// Show a confirmation dialog and return true if the user confirms, otherwise false
	return window.confirm('Sind Sie sicher, dass Sie Repty verlassen möchten?');
}