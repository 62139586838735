import { AnySpecificKitem } from "../AnySpecificKitem";
import { KitemType } from "../contexts/LearningContext";


export const EnglishKitems: (AnySpecificKitem)[] = [
	"car",
	"bicycle",
	"dog",
	"cat",
	"cow",
	"pig",
	"sheep",
	"chair",
	"dragon",
	"excavator",
	"hedgehog",
	"house",
	"monkey",
	"mouse",
	"pirate",
	"strawberry",
	"sun",
].map(key => (
	{
		id: "english_"+key,
		type: KitemType.ImageChooser,
		topic: "englisch",
		setId: "englisch",
		targetId: key,
	}
));
