import { Box, SxProps, Typography, useTheme } from "@mui/material";
import ReactMarkdown from 'react-markdown';

export enum MarkdownStyle {
	MassiveLetter,
	Large,
	Normal,
}

/**
 * 
 */
function MarkdownQuestion(props: {text: string, sx?: SxProps, markdownStyle?: MarkdownStyle}) {
	const theme = useTheme();
	const sx = props.sx;

	const boxProps = 
		props.markdownStyle === MarkdownStyle.Large ?
			{
				fontSize: "3rem",
				lineHeight: "3rem",
			}
		:
			{};

	return <Box {...boxProps}>
		<ReactMarkdown
			components={
				(props.markdownStyle === MarkdownStyle.Large || props.markdownStyle === MarkdownStyle.Normal) ?
					{
						// p: ({node, ...props}) => <Typography variant="h1" sx={Object.assign({}, sx || {}, {fontWeight: 700})} {...props}/>,
						em: ({node, ...props}) => <span style={{color: theme.palette.secondary.main}} {...props}/>
					}
				:
					{
						p: ({node, ...props}) => <Typography variant="h1" sx={Object.assign({}, sx || {}, {fontWeight: 700})} {...props}/>,
						em: ({node, ...props}) => <span style={{color: theme.palette.secondary.main}} {...props}/>
					}
			}>
				{props.text}
		</ReactMarkdown>
	</Box>;
}

export default MarkdownQuestion;
