import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { Kitem } from '../App';
import logger from '../services/logger';
import LearningContext, { KitemProgress, KitemProgressState, KitemRep } from './LearningContext';
import { useImmerReducer } from "use-immer";
import { v4 as uuidv4 } from 'uuid';
import { isSameDay, format, isBefore } from 'date-fns';
import { openDB, IDBPDatabase, /* DBSchema */ } from 'idb';
import { BuchstabenGrossKitems } from '../kitems/Kitems_Buchstaben_gross';
import { current } from 'immer';
import { JanoschKitems } from '../kitems/Kitems_Janosch';
import LoadingView from '../components/LoadingView';
import sample from 'lodash.sample';
import assert from 'assert';
import { SozialesKitems } from '../kitems/Kitems_Soziales';
import { PlanetenKitems } from '../kitems/Kitems_Planeten';
import { distributeItems, mixPitemsByTopic, pitemTopicIsInactive, pitemTopicIsLearning } from '../services/learningPackages';
import { isTopicStatus, TopicStatus } from '../services/useTopics';
import { ErsteWorteKitems } from '../kitems/Kitems_Erste_Worte';
import { WochentageKitems } from '../kitems/Kitems_Wochentage';
import UserManagerContext from './UserManagerContext';
import KitemSettingsContext from './KitemSettingsContext';
import { GeschwisterKitems } from '../kitems/Kitems_Geschwister';
import { MonateKitems } from '../kitems/Kitems_Monate';
import { ZahlenKitems } from '../kitems/Kitems_Zahlen';
import { EnglishKitems } from '../kitems/Kitems_English';
import { schedulerV2 } from '../services/schedulerV2';

interface Props {
	children: React.ReactNode,
}

enum ProgressActionType {
	sync,
	rep,
	kitemDeleted,
	repDeleteAll,
}
interface ProgressSyncAction {
	type: ProgressActionType.sync,
	kitems: Kitem[],
}
interface ProgressRepAction {
	type: ProgressActionType.rep,
	rep: KitemRep,
}
interface ProgressKitemDeletedAction {
	type: ProgressActionType.kitemDeleted,
	kitemId: string,
}
interface ProgressRepDeleteAllAction {
	type: ProgressActionType.repDeleteAll,
}

type KitemProgressAction = 
	ProgressSyncAction |
	ProgressRepAction |
	ProgressRepDeleteAllAction |
	ProgressKitemDeletedAction;

enum KitemActionType {
	add,
	delete,
	update,
}

interface KitemAddAction {
	type: KitemActionType.add,
	kitem: Kitem,
}

interface KitemUpdateAction {
	type: KitemActionType.update,
	kitem: Kitem,
}

interface KitemDeleteAction {
	type: KitemActionType.delete,
	kitemId: string,
}

type KitemAction = KitemAddAction | KitemDeleteAction | KitemUpdateAction;

export type KitemUnsaved = Omit<Kitem, "id">;

export interface LearningPackage {
	id: string,
	topic: string | null,
	date: string,
	plannedKitemIds: string[],
	remainingKitemIds: string[],
	// plannedPitems: KitemProgress[],
	// remainingPitems: KitemProgress[],
}

// interface HydrogenDB extends DBSchema {
// 	'reps': {
// 		key: string;
// 		value: KitemRep;
// 	};
// }


/**
 * Calculates an easiness score based on past repetitions.
 * Score is the number of successful reps since last fail (or beginning if there were no fails)
 * @param reps repetitions for a kitem
 */
const easiness = (reps: KitemRep[]): number => {
	// const scoringReps = [...reps];
	// ignore first reps until two successive reps have been successful
	// while (scoringReps.length > 1 && !(scoringReps[0].success && scoringReps[1].success))
	// 	scoringReps.shift();
	// if (scoringReps.length < 2)
	// 	return 0;
	let score = 0;
	for (let i = reps.length - 1; i > 0; i--) {
		if (!reps[i].success)
			break;
		else
			score++;
	}
	return score;
}

/**
 * Can this item be considered settled knowledge or might it still be a challenge?
 * @param p 
 * @returns boolean
 */
export const settledKnowledge = (p: KitemProgress) => {
	if (p.state === KitemProgressState.fail)
		return false; // currently failing
	if (p.reps.length === 0)
		return false; // no reps no knowledge
	if (!p.reps.some(r => !r.success))
		return true; // never failed, was probably already known from the start
	let successDays = 0;
	let lastSuccessDay: null | Date = null;
	for (let i = p.reps.length; i--; i > 0) {
		const rep = p.reps[i];
		if (rep.success) {
			if (!lastSuccessDay) {
				lastSuccessDay = rep.date;
				successDays++;
			} else {
				if (!isSameDay(rep.date, lastSuccessDay)) {
					successDays++;
					lastSuccessDay = rep.date;

					if (successDays >= 2) {
						return true;
					}
				}
			}
		} else {
			// Never learned or recently forgotten. Either way, no settled knowledge.
			return false;
		}
	}
	// No enough reps => no settled knowledge
	return false;
}

export const isLearning = (item: KitemProgress) => (item.state === KitemProgressState.fail || (item.state !== KitemProgressState.new && !settledKnowledge(item)));

const filterAuthorizedKitems = (kitems: Kitem[], currentUserId: string) => {
	const sanderSommerIds = [
		"9ebd7aa3-b9ab-49f2-b4f9-64ecf9d79e92",
		"266156b1-208f-49ba-b601-21c1a60c74ca", // Pixel 4a
		"46434d5c-345f-443d-bc1a-187d53ee4f04", // Pixel 4a
		"e25afe3d-c89c-4b19-8eab-37ac45f6626d", // Pixel 2
	];
	const sanderSommerKitems = [
		"ersteworte_mama",
		"ersteworte_papa",
		"ersteworte_janosch",
		"stadt",
		"strasse",
		"hausnummer",
	];
	return kitems.filter(k => !sanderSommerKitems.includes(k.id) || sanderSommerIds.includes(currentUserId));
}

export default function LearningProvider(props: Props) {
	const userManager = useContext(UserManagerContext);
	// const kitems = [ZahlenKitems[0], ZahlenKitems[1]];
	// const kitems = [...ZahlenKitems, ...ZahlenKitemsX0, ...ZahlenKitems1x, ...BuchstabenGrossKitems, ...JanoschKitems];
	const [kitems, dispatchKitems] = useImmerReducer<Kitem[], KitemAction>((kitemsDraft, action) => {
		switch (action.type) {
			case KitemActionType.add:
				kitemsDraft.push(action.kitem);
				break;
			case KitemActionType.delete: {
				// logger.debug("Kitem length before delete: "+kitemsDraft.length);
				const index = kitemsDraft.findIndex(k => k.id === action.kitemId)
				if (index !== -1) 
					kitemsDraft.splice(index, 1);
				// kitemsDraft = kitemsDraft.filter(k => k.id !== action.kitemId);
				// logger.debug("Kitem length after delete: "+kitemsDraft.length);
				break;
			}
			case KitemActionType.update: {
				const index = kitemsDraft.findIndex(k => k.id === action.kitem.id);
				if (index !== -1) kitemsDraft[index] = action.kitem;
				break;
			}
			default:
				throw new Error("Unsupported action");
		}
	}, 
	filterAuthorizedKitems([
		...PlanetenKitems,
		...ZahlenKitems,
		...BuchstabenGrossKitems,
		...SozialesKitems,
		...JanoschKitems,
		...ErsteWorteKitems,
		...WochentageKitems,
		...MonateKitems,
		...GeschwisterKitems,
		...EnglishKitems,
	], userManager.currentUserId)
	);
	const dbRef = useRef<IDBPDatabase<unknown>|null>(null);
	const [learningPackageMaxTotalItems, setLearningPackageMaxTotalItems] = useState(6);
	const [learningPackageMaxHardItems, setLearningPackageMaxHardItems] = useState(3);
	const [learningPackageMaxNewItems, setLearningPackageMaxNewItems] = useState(2);
	const dbContentLoaded = useRef(false);
	const [progress, dispatchProgress] = useImmerReducer<KitemProgress[], KitemProgressAction>((pDraft, action: KitemProgressAction) => {
			switch (action.type) {
				case ProgressActionType.sync:
					kitems.forEach(k => {
						const kitemProgress = pDraft.find(p => p.kitemId === k.id);
						if (!kitemProgress) {
							const newProgress: KitemProgress = {
								id: uuidv4(),
								kitemId: k.id,
								reps: [],
								easiness: 0,
								state: KitemProgressState.new,
								due: new Date(),
							}
							pDraft.push(newProgress);
						}
					});
				break;
				case ProgressActionType.rep:
					const pitem = pDraft.find(p => p.kitemId === action.rep.kitemId);
					if (!pitem)
						throw new Error("Did not find pitem matching to rep "+action.rep);

					pitem.reps.push(action.rep);
					pitem.reps.sort((a, b) => (a.date.getTime()-b.date.getTime()));
					pitem.state = pitem.reps[pitem.reps.length-1].success ? KitemProgressState.success : KitemProgressState.fail;
					pitem.due = schedulerV2(pitem.reps);
					pitem.easiness = easiness(pitem.reps);
					if (!(pitem.due instanceof Date && !isNaN(pitem.due.getTime())))
						logger.error("Invalid due date for pitem", current(pitem));
					// logger.debug("Next due:", pitem.due);
				break;
				case ProgressActionType.kitemDeleted:
					const index = pDraft.findIndex(p => p.kitemId === action.kitemId)
					if (index !== -1) 
						pDraft.splice(index, 1);
					// pDraft = pDraft.filter(p => p.kitemId !== action.kitemId);
				break;
				case ProgressActionType.repDeleteAll:
					for (let i = 0; i < pDraft.length; i++) {
						pDraft[i].reps = [];
					}
					break;
				default:
					throw new Error("Unsupported action");
			}
		}
		,
		[
		]
	);
	// const repLoadingBuffer = useRef<KitemRep[]>([]);
	const [repLoadingBuffer, setRepLoadingBuffer] = useState<KitemRep[]>([]);
	// const [learningPackages, updateLearningPackages] = useImmer<{ready: boolean, pkgs: LearningPackage[]}>({ready: false, pkgs: []});
	const [learningPackages, setLearningPackages] = useState<{ready: boolean, pkgs: LearningPackage[]}>({ready: false, pkgs: []});
	const learningPackagesAddQueue = useRef<LearningPackage[]>([]);
	const [loadedKitems, setLoadedKitems] = useState(false);
	const repsReadFromDB = useRef(false);
	const [loadedReps, setLoadedReps] = useState(false);
	const [waited, setWaited] = useState(false);
	const [topicWeights, setTopicWeights] = useState<Record<string,number>>({});
	const [topicWeightsLoaded, setTopicWeightsLoaded] = useState(false);
	const [topicStatusStore, setTopicStatusStore] = useState<Record<string,TopicStatus>>({});
	const [topicStatusLoaded, setTopicStatusLoaded] = useState(false);
	const kitemSettings = useContext(KitemSettingsContext);
	// const { mixPitemsByTopic } = useLearningPackages();

	useEffect(() => {
		setTimeout(() => {setWaited(true)}, 500);
	})

	useEffect(() => {
		if (!loadedReps) {
			const timerId = setTimeout(() => {
				if (!loadedReps) {
					logger.error("Timeout while loading reps. Marking as loaded anyway.");
					setLoadedReps(true);
				}
			}, 1500);
			return () => clearTimeout(timerId);
		}
	}, [loadedReps]);

	const dbLoadingInitiated = useRef(false);
	useEffect(() => {
		if (!dbLoadingInitiated.current) {
			logger.info("Loading data from indexedDB");
			dbLoadingInitiated.current = true;
			// dbRef.current = 
			openDB("hydrogen", 4, {
				upgrade(db, oldVer, newVer) {
	
					if (oldVer < 1) {
						// Create a store of objects
						db.createObjectStore('reps', {
							// The 'id' property of the object will be the key.
							keyPath: 'id',
						});
					}
	
					if (oldVer < 2) {
						// Create a store of objects
						db.createObjectStore('kitems', {
							// The 'id' property of the object will be the key.
							keyPath: 'id',
						});
					}
	
					if (oldVer < 3) {
						// Create a store of objects
						db.createObjectStore('learningPackages', {
							// The 'id' property of the object will be the key.
							keyPath: 'id',
						});
					}
					
					if (oldVer < 4) {
						db.createObjectStore('settings');
					}
	
				}
			}).then(db => {
				dbRef.current = db;
				if (!dbContentLoaded.current) {
					dbContentLoaded.current = true; // mark as loaded to prevent multiple loadings (e.g. because of react calling the effect twice in development). Must be before db interaction because effect might be already triggered again while waiting for async db response.
					logger.info("Loading stored kitems from indexedDB");
					dbRef.current.getAll("kitems").then(kitems => {
						logger.debug("Kitems in store:", kitems);
						kitems.forEach(kitem => {
							dispatchKitems({
								type: KitemActionType.add,
								kitem: kitem,
							})
						});
						setLoadedKitems(true);
					})
					// Can't load reps directly into progress, because progress for matching kitem might not be synched yet
					logger.info("Loading stored reps from indexedDB");
					dbRef.current.getAll("reps").then(reps => {
						logger.debug("Reps in store:", reps);
						// setRepLoadingBuffer((old) => [...old, ...reps]);
						setRepLoadingBuffer(reps);
						repsReadFromDB.current = true;
						// repLoadingBuffer.current.push(...reps);
						// processRepLoadingBuffer();
					});
					logger.info("Loading stored learning packages from indexedDB");
					dbRef.current.getAll("learningPackages").then((pkgs: LearningPackage[]) => {
						logger.debug("Packages in store:", pkgs);
						setLearningPackages(old => {
							const updated = Object.assign({}, old, {
								ready: true,
								pkgs: [...old.pkgs, ...pkgs],
							});
							return updated;
						});
					});
					logger.info("Loading settings");
					dbRef.current.get("settings", "learningPackages.maxTotalItems").then(val => {
						if (val) {
							const iVal = parseInt(val);
							if (iVal > 0)
								setLearningPackageMaxTotalItems(iVal)
						}
					});
					dbRef.current.get("settings", "learningPackages.maxHardItems").then(val => {
						if (val) {
							const iVal = parseInt(val);
							if (iVal > 0)
								setLearningPackageMaxHardItems(iVal)
						}
					});
					loadTopicStatusFromDB();
					loadTopicWeightsFromDB();
				}
			})
		} else {
			logger.error("Tried to initiate loading data from db, but was already initiated.");
		}
	}, [dispatchProgress, dispatchKitems])

	const refreshPackages = () => {
		logger.debug("Checking if new items became due in learning packages...");
		if (!loadedReps) {
			logger.debug("Reps not ready, skipping");
			return;
		}
		let changed = false;
		const updatedPackages = Object.assign({}, learningPackages, {
			pkgs: learningPackages.pkgs.map((pkg: LearningPackage) => {
				const newPkg = Object.assign({}, pkg, {
					remainingKitemIds: [...pkg.remainingKitemIds],
				});
				pkg.plannedKitemIds.forEach(kid => {
					if (!pkg.remainingKitemIds.includes(kid)) {
						const pitem = getPitemByKitemId(kid);
						if (pitem && isDueNow(pitem)) {
							logger.debug("Kitem "+kid+" is due ("+pitem.due+"), re-adding to package");
							logger.debug("Rep state", loadedReps, JSON.stringify(pitem.reps));
							newPkg.remainingKitemIds.push(kid);
							changed = true;
						}
					}
				})
				return newPkg;
			})
		});
		if (changed) {
			logger.debug("... new items due, updating learning packages state.", learningPackages, updatedPackages);
			setLearningPackages(updatedPackages);
		} else {
			logger.debug("... no update needed.");
		}
	};

	/**
	 * Frequently check if already solved items became due again.
	 * Disabled for now. It feels wrong to "re-open" an already completed session.
	 */
	// useEffect(() => {
	// 	refreshPackages();
	// 	const timerId = setInterval(() => {
	// 		refreshPackages();
	// 	}, 60*1000);
	
	// 	return () => {
	// 		clearInterval(timerId);
	// 	};
	// }, [learningPackages, refreshPackages]);

	const processRepLoadingBuffer = useCallback(() => {
		logger.debug("Processing rep loading buffer of "+repLoadingBuffer.length);
		
		const reps = repLoadingBuffer;
		const newBuffer: KitemRep[] = [];
		// repLoadingBuffer.current = [];
		reps.forEach(rep => {
			if (progress.some(p => p.kitemId === rep.kitemId)) {
				dispatchProgress({
					type: ProgressActionType.rep,
					rep: rep,
				})
			} else {
				newBuffer.push(rep);
			}
		});
		// logger.debug("Remaining: "+repLoadingBuffer.current.length);
		if (repLoadingBuffer.length !== newBuffer.length)
			setRepLoadingBuffer(newBuffer);
		if (newBuffer.length === 0 && repsReadFromDB.current) {
			setLoadedReps(true);
			logger.debug("Rep loading complete.");
		}
		logger.debug("Remaining: "+newBuffer.length);
	}, [dispatchProgress, progress, repLoadingBuffer]);

	useEffect(() => {
		processRepLoadingBuffer();
	}, [progress, repLoadingBuffer, processRepLoadingBuffer]);
	

	useEffect(() => {
		logger.debug("Synching progress...");
		dispatchProgress({
			type: ProgressActionType.sync,
			kitems: kitems,
		})
	}, [kitems, dispatchProgress]);

	const getProgressByTopic = (topic: string) => progress.filter(p => kitems.find(k => k.id === p.kitemId && k.topic === topic));

	const addRep = (kitemId: string, success: boolean) => {
		logger.info("Adding rep");
		const newRep: KitemRep = {
			id: uuidv4(),
			success: success,
			date: new Date(),
			kitemId: kitemId,
		};
		// Store to indexedDb
		if (!dbRef.current)
			throw new Error("Could not save repetition. Database not ready.");
		dbRef.current.add("reps", newRep);
		dispatchProgress({
			type: ProgressActionType.rep,
			rep: newRep,
		})
	};

	const addRepObject = (rep: KitemRep) => {
		logger.info("Adding Rep Object: "+JSON.stringify(rep));
		const newRep: KitemRep = Object.assign({}, rep);
		// Store to indexedDb
		if (!dbRef.current)
			throw new Error("Could not save repetition. Database not ready.");
		dbRef.current.get("reps", newRep.id).then(result => {
			if (result !== undefined) {
				throw new Error("Trying to add KitemRep which already exists");
			}
			assert(dbRef.current, "Database not ready.");
			dbRef.current.add("reps", newRep);
			// If we have a pitem for the kitem, add it.
			// If not, we'll only store it in the database. Maybe the missing
			// kitem will be added at a later time. We don't want to risk
			// loosing reps.
			if (progress.some(p => p.kitemId === newRep.kitemId)) {
				dispatchProgress({
					type: ProgressActionType.rep,
					rep: newRep,
				});
			}
		});
	}

	const deleteAllLocalReps = () => {
		logger.info("Deleting all local reps!");
		assert(dbRef.current, "Idb not available");
		dbRef.current.clear("reps");
		dispatchProgress({
			type: ProgressActionType.repDeleteAll,
		})
	}

	const getKitem = (kitemId: string) => {
		return kitems.find(k => k.id === kitemId);
	}

	const getPitemByKitemId = useCallback((kitemId: string) => {
		return progress.find(p => p.kitemId === kitemId);
	}, [progress]);

	// const now = new Date();

	const isNew = (p: KitemProgress): boolean => {
	  // are there no reps recorded?
	  return p.reps.length === 0;
	}

	const isDueToday = useCallback((p: KitemProgress): boolean => {
		const now = new Date();
		return (isSameDay(p.due, now) || isBefore(p.due, now));
	}, []);

	const isDueNow = useCallback((p: KitemProgress): boolean => {
		const now = new Date();
		return isBefore(p.due, now);
	}, []);

	/**
	 * Is this today the day that we learned the item for the first time?
	 * Needs to be successfully learnt to count.
	 * @param p KitemProgress
	 * @returns boolean
	 */
	// const newlyLearnedToday = (p: KitemProgress): boolean => {
	// 	const now = new Date();

	// 	// was the first rep recorded today?
	// 	if (p.reps.length === 0) {
	// 		return false;
	// 	}
		
	// 	const firstRepDate = p.reps[0].date;

	// 	if (!isSameDay(firstRepDate, now)) {
	// 		return false;
	// 	}

	// 	// First repetition was today. Only counts if it was also successfully learned (vs. aborted).
	// 	if (p.reps[p.reps.length-1].success) {
	// 		return true;
	// 	}	else {
	// 		return false;
	// 	}
	// }
	
	// was there either a failed repetition today or was the last repetition before today a failure?
	// only count today's fails if it has since been successfully learned again
	// const wasInFailedStateToday = (p: KitemProgress): boolean => {
	// 	const now = new Date();
	// 	// logger.debug("wasInFailedStateToday for kitem" + p.kitemId);
	// 	if (p.reps.length === 0) {
	// 	  return false;
	// 	}

	// 	// if there was no rep at all today, there cannot be a failed rep today
	// 	if (!isSameDay(p.reps[p.reps.length-1].date, now))
	// 		return false;

	// 	// Check if there was a failed rep today
	// 	const hasFailedToday = p.reps.some((rep) => {
	// 		return isSameDay(rep.date, now) && !rep.success;
	// 	});

	// 	if (hasFailedToday) {
	// 		// only count if it currently known again.
	// 		if (p.state === KitemProgressState.success)
	// 			return false;
	// 		else
	// 			return true;
	// 	}

	// 	// Find the last rep before today
	// 	let lastRepBeforeToday: KitemRep | null = null;
	// 	for (let i = p.reps.length - 1; i >= 0; i--) {
	// 		if (!isSameDay(p.reps[i].date, now)) {
	// 			lastRepBeforeToday = p.reps[i];
	// 			break;
	// 		}
	// 	}

	// 	if (!lastRepBeforeToday)
	// 		return false;
		
	// 	return !lastRepBeforeToday.success;
	// }

	

	/**
	 * Calculates the length of the current learning interval if a KitemProgress item in milliseconds.
	 * Current learning interval means the time between the last rep and the time when the item should next be reviewed. 
	 * @param pitem KitemProgress item to calculate the interval for
	 * @returns Interval length in milliseconds
	 */
	const currentInterval = (pitem: KitemProgress) => {
		// if there are no past reps, the item is due immediately, this makes the interval 0
		if (pitem.reps.length === 0)
			return 0;
		const due = pitem.due;
		const lastRepTime = pitem.reps[pitem.reps.length-1].date;
		return due.getTime() - pitem.reps[pitem.reps.length-1].date.getTime() - lastRepTime.getTime();
	}

	const generateLearningPackageContent = (topic: string | null, startEasy?: boolean): KitemProgress[] => {
		/* Create a set of items to learn.
		* They should be due.
		* No more than 2 items that are new or where the last rep failed.
		*/
		// const limitHardItems = topic ? 2 : 2*5;
		const limitHardItems = learningPackageMaxHardItems;

		const topicProgress = [...(topic ? getProgressByTopic(topic) : progress)];
		topicProgress.sort((a, b) => {
			// logger.debug("Sort a,b", a, b);
			return (a.due.getTime()-b.due.getTime())
		});

		// Collect items that are due for today
		const dueItems = topicProgress
			.filter(p => isDueToday(p)) // remove those that are not due
			.filter(p => !pitemTopicIsInactive(p, getKitem, getTopicStatus)) // remove those that belong to an inactive topic
			.filter(p => !kitemSettings.isPaused(p.kitemId))
		;

		logger.debug("generateLearningPackageContent: Due items ", dueItems);

		if (dueItems.length === 0)
			return [];

		// Limit number of "hard" (new or failed) items

		// how many limited items did we already have today?
		// (no longer relevant since we have one learning package per day)
		// const usedLimit = topicProgress.filter(p => 
		// 	newlyLearnedToday(p)
		// 	|| wasInFailedStateToday(p)
		// 	).length;
		// logger.debug("generateLearningPackageContent: Used limit ", usedLimit);
		
		// Filter out items that are new or failed
		let newItems = mixPitemsByTopic(dueItems.filter(p => isNew(p)), getKitem);
		const learningItems = dueItems.filter(p => isLearning(p));
		
		// Include all other due items
		let remainingItems = dueItems.filter(p => !newItems.includes(p) && !learningItems.includes(p));
		
		// Remove new items for topics that are not currently in learning mode
		// Att: This needs to happen afert creating remainingItems, or these pitems will advertently be added there
		newItems = newItems.filter(p => pitemTopicIsLearning(p, getKitem, getTopicStatus));
		
		// Sort by length of current learning interval. Shortest first.
		remainingItems.sort((a, b) => {
			const intervalLengthA = currentInterval(a);
			const intervalLengthB = currentInterval(b);
			return intervalLengthA - intervalLengthB;
		});

		// Make sure that an easy item is at the front of the pile
		if (startEasy === undefined || startEasy === true) {
			remainingItems = easyItemFirst(remainingItems);
		}

		// Make sure that remaining + limited items is not more that max packet size. Prioritize learning forgotten items,
		// then normal items. Only include new items if enough space is available. So: First drop newItems as necessary.
		// Then drop remainingItems as necessary. Drop failed items last
		let overShoot = (remainingItems.length + newItems.length + learningItems.length) - learningPackageMaxTotalItems;

		logger.debug("Culling items. overShoot: "+overShoot, remainingItems, learningItems, newItems);

		// drop new items as long as we are overshooting overall or having too many hard items
		while ((overShoot > 0 || ((newItems.length + learningItems.length) > limitHardItems)) && newItems.length > 0) {
			newItems.pop();
			overShoot--;
		}
		while (newItems.length > 0 && newItems.length > learningPackageMaxNewItems) {
			newItems.pop();
			overShoot--;
		}
		// Then drop failed items as long as we still have too many hard items. Don't care about overshoot here.
		while (((newItems.length + learningItems.length) > limitHardItems) && learningItems.length > 0) {
			learningItems.pop();
			overShoot--;
		}
		// Drop normal items if package is still too large
		while (overShoot > 0 && remainingItems.length > 0) {
			remainingItems.pop();
			overShoot--;
		}
		// Only drop failed items if we have more failed items than package size
		while (overShoot > 0 && learningItems.length > 0) {
			learningItems.pop();
			overShoot--;
		}

		const learningPackage = distributeItems(remainingItems, learningItems, newItems);

		logger.debug("generateLearningPackageContent: Compiled learning package content for "+topic, learningPackage);
		return learningPackage;
	};

	const easyItemFirst = (remainingItems: KitemProgress[]) => {
		// First, define easy: Within the top third of easiness items. So let's find the top third.
		const easinessItems = [...remainingItems.filter(p => p.state === KitemProgressState.success)].sort((a, b) => b.easiness - a.easiness);
		logger.debug("Easiness distribution", easinessItems);
		const easiestItems = easinessItems.splice(0, Math.ceil(easinessItems.length / 3));
		// The easiness of the hardest item in the easiest segment is our bar to pass for easiness
		const easinessBar = easiestItems.length > 0 ? easiestItems[easiestItems.length-1].easiness : 0;
		logger.debug("Easiness bar: "+easinessBar);
		// Next, find the easiest item in the current pile
		let easiestItem: KitemProgress | null = null;
		for (let i = 0; i < remainingItems.length; i++) {
			if (!easiestItem)
				easiestItem = remainingItems[i];
			else if (remainingItems[i].easiness > easiestItem.easiness)
				easiestItem = remainingItems[i];
		}
		// logger.debug("Easiest item in pile", easiestItem);
		// const remainingItemsByEasiness = [...remainingItems].sort((a, b) => b.easiness - a.easiness);
		if (easiestItem && easiestItem.easiness >= easinessBar) {
			// our easiest item is easy enough. Move to front.
			logger.debug("Moving easiest item to front", easiestItem);
			const index = remainingItems.findIndex(p => p.id === easiestItem!.id);
			remainingItems.splice(index, 1);
			remainingItems.unshift(easiestItem);
		} else {
			// take a random item from the easiest items and place it at the front
			const easyItem = sample(easiestItems);
			logger.debug("Adding random easy item to front", easyItem);
			if (easyItem) {
				remainingItems.unshift(easyItem);
			}
		}
		return remainingItems;
	}

	useEffect(() => {
		// logger.debug("Clearning learningPackagesAddQueue", JSON.stringify(learningPackagesAddQueue.current));
		learningPackagesAddQueue.current = learningPackagesAddQueue.current.filter(pkg => !learningPackages.pkgs.some(p => p.id === pkg.id));
		// logger.debug("Clearning learningPackagesAddQueue (after)", JSON.stringify(learningPackagesAddQueue.current));
	}, [learningPackages]);

	/**
	 * Get today's learning package for a topic.
	 * @param topic 
	 * @returns 
	 */
	const getLearningPackage = (): LearningPackage => {

		if (!learningPackages.ready)
			throw new Error("Learning package store not ready yet");


		const today = new Date();
		const formattedDate = format(today, 'yyyy-MM-dd');

		const pkg = learningPackages.pkgs.find(pkg => pkg.topic === null && pkg.date === formattedDate);
		if (pkg) {
			return pkg;
		}

		// Check if pkg is in adding queue. Might happen due to some weird race condition where a new package is created before the state change has been commided
		const queuedPkg = learningPackagesAddQueue.current.find(pkg => pkg.topic === null && pkg.date === formattedDate);
		if (queuedPkg) {
			logger.error("getLearningPackage: Package not found in state but in add queue. Returning the one from queue. State:", JSON.stringify(learningPackages));
			return queuedPkg;
		}

		// Not in state and not in queue? Create new one.

		let newPkg: LearningPackage;

		const content = generateLearningPackageContent(null);

		newPkg = {
			id: uuidv4(),
			topic: null,
			date: formattedDate,
			plannedKitemIds: content.map(c => c.kitemId),
			remainingKitemIds: content.map(c => c.kitemId), // careful: Make sure this is a separat array even if it has the same content, or both will be modified on changes
		}

		logger.info("getLearningPackage: Created new learning package", newPkg);

		if (!dbRef.current)
			throw new Error("Could not save learning package. Database not ready.");

			
		dbRef.current.get("learningPackages", newPkg.id).then(value => {if (value) throw new Error("Duplicate learning package id in db")});
			
		learningPackagesAddQueue.current.push(newPkg);

		dbRef.current.add("learningPackages", newPkg);

		setLearningPackages(old => {
			if (old.pkgs.some(p => p.id === newPkg.id))
				throw new Error("Duplicate learning package id");
			const newPkgs = Object.assign({}, old, {
				pkgs: [...old.pkgs, newPkg],
			});
			return newPkgs;
		});

		// updateLearningPackages(draft => {
		// 	if (learningPackages.pkgs.some(p => p.id === newPkg.id))
		// 		throw new Error("Duplicate learning package id");

		// 	draft.pkgs.push(newPkg);
		// });

		return newPkg;
	}

	const deleteTodaysLearningPackages = () => {
		const today = new Date();
		const formattedDate = format(today, 'yyyy-MM-dd');
		const todaysPkgs = learningPackages.pkgs.filter(pkg => pkg.date === formattedDate);
		todaysPkgs.forEach(pkg => {
			assert(dbRef.current);
			dbRef.current.delete("learningPackages", pkg.id);
		});
		const updatedPkgs = Object.assign({}, learningPackages, {
			pkgs: learningPackages.pkgs.filter(p => !todaysPkgs.includes(p)),
		});
		setLearningPackages(updatedPkgs);
	}

	const recordItemResultInAnyPackage = (date: string, kitemId: string, success: boolean) => {
		logger.debug("recordItemResultInPackage", date, kitemId, success);
		addRep(kitemId, success);
		learningPackages.pkgs.filter(pkg => pkg.date === date).forEach(pkg => {
			if (pkg.remainingKitemIds.some(k => k === kitemId))
				recordItemResultInPackage(pkg.id, kitemId, success);
		});
	}

	/**
	 * Record that an item was failed. Reorder the remaining items in the learning package
	 * to repeat the failed item.
	 * The failed item is repeated after the next 2 items.
	 */
	const recordItemResultInPackage = (packageId: string, kitemId: string, success: boolean) => {
		logger.debug("recordItemResultInPackage", packageId, kitemId, success);
		// updateLearningPackages(pkgsDraft => {
		// 	const today = new Date();
		// 	const date = format(today, 'yyyy-MM-dd');

		// 	const pkg = pkgsDraft.pkgs.find(pkg => pkg.topic === topic && pkg.date === date);
		// 	if (!pkg)
		// 		throw new Error("Could not find learning package "+topic+"/"+date);
		// 	const pitem = progress.find(p => p.id === pitemId);
		// 	if (!pitem)
		// 		throw new Error("Could not find progress item");
		// 	addRep(pitem.kitemId, success);
	
		// 	const itemIndex = pkg.remainingPitems.findIndex(p => p.id === pitemId);
		// 	if (itemIndex === -1)
		// 		throw new Error("Did not find item in learning package");
		// 	const removedItem = pkg.remainingPitems.splice(itemIndex, 1)[0];
	
		// 	// If the item either failed or was learned for the first time, schedule it again after two other items.
		// 	if (!success || pitem.state === KitemProgressState.new) {
		// 		pkg.remainingPitems.splice(3, 0, removedItem);
		// 	}
		// 	logger.debug("recordItemResultInPackage end");
		// });
		const pitem = progress.find(p => p.kitemId === kitemId);
		if (!pitem) {
			logger.debug("Progress", progress);
			throw new Error("Could not find progress item");
		}

		const pkg = learningPackages.pkgs.find(pkg => pkg.id === packageId);
		if (!pkg)
			throw new Error("Could not find learning package "+packageId);
		logger.debug("Updating learningpackage", JSON.stringify(pkg));
		
		const itemIndex = pkg.remainingKitemIds.findIndex(k => k === kitemId);
		if (itemIndex === -1)
			throw new Error("Did not find item in learning package");
		const removedItem = pkg.remainingKitemIds.splice(itemIndex, 1)[0];

		// If the item either failed or was learned for the first time, schedule it again after two other items.
		if (!success || pitem.state === KitemProgressState.new) {
			pkg.remainingKitemIds.splice(3, 0, removedItem);
		}

		updateLearningPackageInState(pkg);
		updateLearningPackageInDatabase(pkg);
		// addRep(pitem.kitemId, success);


		// old.map(op => op.date === date && op.topic === topic ? Object.assign({}, op, {})))
	}

	const updateLearningPackageInState = (pkg: LearningPackage) => {
		setLearningPackages(old => {
			const newLps = Object.assign({}, old, {
				pkgs: [...old.pkgs.filter(p => p.id !== pkg.id), pkg],
			})
			return newLps;
		});
	}

	const updateLearningPackageInDatabase = (pkg: LearningPackage) => {
		// Update package in idb
		if (!dbRef.current)
			throw new Error("IDB not ready");
		dbRef.current.put("learningPackages", pkg);
	}

	const extendLearningPackage = (packageId: string, topicId: string | null) => {
		const pkg = learningPackages.pkgs.find(pkg => pkg.id === packageId);
		if (!pkg)
			throw new Error("Could not find learning package "+packageId);
		const newContent = getContentForLearningPackageExtension(pkg, topicId);
		
		// Append kitemId of each item in newContent to pkg.remainingKitemIds
		const newKitemIds = newContent.map(item => item.kitemId);
		pkg.plannedKitemIds = pkg.plannedKitemIds.concat(newKitemIds);
		pkg.remainingKitemIds = pkg.remainingKitemIds.concat(newKitemIds);

		updateLearningPackageInState(pkg);
		updateLearningPackageInDatabase(pkg);
		return pkg;
	}

	const getContentForLearningPackageExtension = (pkg: LearningPackage, topicId: string|null, ) => {
		const newContent = generateLearningPackageContent(topicId, false)
			.filter(item => !pkg.remainingKitemIds.includes(item.kitemId));
		return newContent;
	}
	
	const learningPackageIsExtendable = (packageId: string, topicId: string | null) => {
		const pkg = learningPackages.pkgs.find(pkg => pkg.id === packageId);
		if (!pkg)
			throw new Error("Could not find learning package "+packageId);
		const newContent = getContentForLearningPackageExtension(pkg, topicId);
		return (newContent.length > 0);
	}

	const filterKitemIdsByTopic = (kitemIds: string[], topic: string) => {
		const kitems = kitemIds.map(id => {
			const kitem = getKitem(id);
			assert(kitem, "filterKitemIdsByTopic: Did not find kitem matching kitemId "+id);
			return kitem;
		}).filter(kitem => kitem.topic === topic);
		return kitems.map(k => k.id);
	}

	// const succeedItem = () => {
	// 	const newPosition = Math.max(0, itemIndex - 2);
	// 	const [removedItem] = pkg.remainingPitems.splice(itemIndex, 1);
	// 	pkg.remainingPitems.splice(newPosition, 0, removedItem);
	// 	// TODO: move item in pkg.remainingPitems two positions back
	// }

	const getReps = async () => {
		logger.info("Getting stored reps from indexedDB");
		assert(dbRef.current);
		const reps = await dbRef.current.getAll("reps");
		logger.debug("Reps", reps);
		return reps;
	}

	const handleSetLearningPackageMaxTotalItems = (limit: number) => {
		assert(dbRef.current);
		dbRef.current.put("settings", limit, "learningPackages.maxTotalItems");
		setLearningPackageMaxTotalItems(limit);
	}
	const handleSetLearningPackageMaxHardItems = (limit: number) => {
		assert(dbRef.current);
		dbRef.current.put("settings", limit, "learningPackages.maxHardItems");
		setLearningPackageMaxHardItems(limit);
	}
	const handleSetLearningPackageMaxNewItems = (limit: number) => {
		assert(dbRef.current);
		dbRef.current.put("settings", limit, "learningPackages.maxNewItems");
		setLearningPackageMaxNewItems(limit);
	}

	const setTopicWeight = (topicId: string, weight: number) => {
		setTopicWeights(old => Object.assign({}, old, {
			[topicId]: weight,
		}));
		assert(dbRef.current);
		dbRef.current.put("settings", weight, "topicWeight."+topicId);
	}

	const getTopicWeight = (topicId: string) => {
		return topicWeights[topicId] || 1;
	}

	const getTopicStatus = (topicId: string): TopicStatus => {
		const result = topicStatusStore[topicId] || "inactive";
		logger.debug("getting topic status for topic "+topicId, topicStatusStore[topicId], result, topicStatusStore);
		return result;
	}

	const setTopicStatus = (topicId: string, status: TopicStatus) => {
		setTopicStatusStore(old => Object.assign({}, old, {
			[topicId]: status,
		}));
		assert(dbRef.current);
		dbRef.current.put("settings", status, "topicStatus."+topicId);
	}

	const loadTopicStatusFromDB = async () => {
		await loadTopicSettingsFromDB("topicStatus.", (topicId: string, value: unknown) => {
			if (isTopicStatus(value)) {
				logger.debug("Setting status to "+value);
				setTopicStatusStore(old => Object.assign({}, old, {
					[topicId]: value,
				}));
			} else {
				logger.error("No valid topic status value for topic "+topicId, value);
			}
		});
		setTopicStatusLoaded(true);
	}

	const loadTopicWeightsFromDB = async () => {
		await loadTopicSettingsFromDB("topicWeight.", (topicId: string, value: unknown) => {
			if (typeof (value) === "number") {
				logger.debug("Setting topic weight to "+value);
				setTopicWeights(old => Object.assign({}, old, {
					[topicId]: value,
				}));
			} else {
				logger.error("No valid topic weight value for topic "+topicId, value);
			}
		});
		setTopicWeightsLoaded(true);
	}

	const loadTopicSettingsFromDB = async (keyStart: string, callback: (topicId: string, value: unknown) => void) => {
		assert(dbRef.current);
		const settingsKeys = await dbRef.current.getAllKeys("settings");
		logger.debug("Setting Keys in store:", settingsKeys);
		const promises: Promise<any>[] = [];
		settingsKeys.forEach(key => {
			logger.debug("Key", key, typeof key);
			if (typeof key === "string" && key.startsWith(keyStart)) {
				// key format: topicStatus.[topicId]
				const topicId = key.substring(keyStart.length);
				logger.debug("Loading topic status for topic "+topicId);
				assert(dbRef.current);
				const promise = dbRef.current.get("settings", key).then((value: unknown) => {
					if (value)
						callback(topicId, value);
				});
				promises.push(promise);
			}
		});
		await Promise.all(promises);
	}

	const contextValue = {
		getKitems: (topic?: string) => topic ? kitems.filter(k => k.topic === topic) : kitems,
		getKitem: getKitem,
		addKitem: (kitemUnsaved: KitemUnsaved ) => {
			logger.info("Adding kitem", kitemUnsaved);
			const kitem = Object.assign({}, kitemUnsaved, {
				id: uuidv4(),
			});
			logger.debug("Id of new kitem: ", kitem.id);
			// Store to indexedDb
			if (!dbRef.current)
				throw new Error("Could not save repetition. Database not ready.");
			dbRef.current.add("kitems", kitem);
			dispatchKitems({
				type: KitemActionType.add,
				kitem: kitem,
			})
		},
		updateKitem: (kitem: Kitem) => {
			logger.info("Updating kitem", kitem);
			if (!dbRef.current)
				throw new Error("Could not save repetition. Database not ready.");
			dbRef.current.put("kitems", kitem);
			dispatchKitems({
				type: KitemActionType.update,
				kitem: kitem,
			});
		},
		deleteKitem: (kitemId: string) => {
			logger.info("Deleting kitem "+kitemId);
			if (!kitems.some(k => k.id === kitemId))
				throw new Error("Kitem could not be deleted - not found");
			dbRef.current?.delete("kitems", kitemId);
			dbRef.current?.getAll("reps").then((reps: KitemRep[]) => {
				reps.forEach(rep => {
					if (rep.kitemId === kitemId) {
						dbRef.current?.delete("reps", rep.id);
					}
				})
			});
			dispatchKitems({
				type: KitemActionType.delete,
				kitemId: kitemId,
			});
			dispatchProgress({
				type: ProgressActionType.kitemDeleted,
				kitemId: kitemId,
			});
		},
		getCurriculum: (topic?: string) => {
			const cprogress = topic ? getProgressByTopic(topic) : progress;
			logger.debug("cprogress", cprogress);
			const cSorted = [...cprogress];
			cSorted.sort((a, b) => (a.due.getTime()-b.due.getTime()));
			return cSorted;
		},
		filterKitemIdsByTopic: filterKitemIdsByTopic,
		getLearningPackage: getLearningPackage,
		extendLearningPackage: extendLearningPackage,
		learningPackageIsExtendable: learningPackageIsExtendable,
		deleteTodaysLearningPackages: deleteTodaysLearningPackages,
		recordItemResultInPackage: recordItemResultInPackage,
		recordItemResultInAnyPackage: recordItemResultInAnyPackage,
		addRep: addRep,
		addRepObject: addRepObject,
		getReps: getReps,
		deleteAllLocalReps: deleteAllLocalReps,
		learningPackageMaxTotalItems: learningPackageMaxTotalItems,
		setLearningPackageMaxTotalItems: handleSetLearningPackageMaxTotalItems,
		learningPackageMaxHardItems: learningPackageMaxHardItems,
		setLearningPackageMaxHardItems: handleSetLearningPackageMaxHardItems,
		learningPackageMaxNewItems: learningPackageMaxNewItems,
		setLearningPackageMaxNewItems: handleSetLearningPackageMaxNewItems,
		getProgress: (kitemId: string) => {
			return progress.find(p => p.kitemId === kitemId);
		},
		getAllProgress: () => [...progress],
		getTopicWeight: getTopicWeight,
		setTopicWeight: setTopicWeight,
		getTopicStatus: getTopicStatus,
		setTopicStatus: setTopicStatus,
	}

	logger.debug("Updated LearningProvider");
	// logger.debug("RepLoadingBuffer", repLoadingBuffer);
	// logger.debug("Kitems: "+kitems.length);
	logger.debug("Learning packages add queue:", learningPackagesAddQueue.current);
	logger.debug("Learning packages:", learningPackages);

	if (!loadedKitems)
		return <LoadingView what="kitems"/>
	if (!loadedReps)
		return <LoadingView what="reps"/>
	if (!waited)
		return <LoadingView what="sync buffer"/>
	if (!topicStatusLoaded)
		return <LoadingView what="topic status"/>
	if (!topicWeightsLoaded)
		return <LoadingView what="topic weights"/>

	return (
		<LearningContext.Provider value={contextValue}>
			{props.children}
			{/* {repLoadingBuffer.length > 0 && <div onClick={() => processRepLoadingBuffer()} style={{textAlign: "right", marginRight: 32}}>{repLoadingBuffer.length}</div>} */}
		</LearningContext.Provider>
	);
}
