import React, { useRef, useState, useEffect } from 'react';
import logger from '../services/logger';
import KitemSettingsContext, { KitemSettings } from './KitemSettingsContext';
import { IDBPDatabase, openDB } from 'idb';

interface KitemSetting {
	kitemId: string,
	isPaused: boolean,
}

interface SettingsStore {
	[key: string]: KitemSetting
}

interface Props {
	children: React.ReactNode,
}
export default function KitemSettingsProvider(props: Props) {

	const dbRef = useRef<IDBPDatabase<unknown>|null>(null);
	const [settings, setSettings] = useState<SettingsStore>({});

	const dbLoadingInitiated = useRef(false);
	useEffect(() => {
		if (!dbLoadingInitiated.current) {
			logger.info("Loading data from indexedDB");
			dbLoadingInitiated.current = true;
			openDB("hydrogen-kitem-settings", 1, {
				upgrade(db, oldVer, newVer) {
	
					if (oldVer < 1) {
						db.createObjectStore('settings', {keyPath: "kitemId"});
					}
	
				}
			}).then(db => {
				dbRef.current = db;
				db.getAll("settings").then(settings => {
					const loadedSettings: SettingsStore = {};
					settings.forEach((setting: KitemSetting) => {
						if (typeof setting.kitemId === "string" && typeof setting.isPaused === "boolean")
							loadedSettings[setting.kitemId] = setting;
						else
							logger.error("KitemSettingsProvider: Error loading settings, unexpected setting format: "+setting);
					});
					setSettings(loadedSettings);
				});
			})
		} else {
			logger.error("Tried to initiate loading data from db, but was already initiated.");
		}
	}, []);

	const createSetting = (kitemId: string): KitemSetting => ({
		kitemId: kitemId,
		isPaused: false,
	});

	const setPaused = (kitemId: string, paused: boolean) => {
		const setting = settings[kitemId];
		if (!setting) {
			const newSetting = createSetting(kitemId);
			newSetting.isPaused = paused;
			setSettings(old => Object.assign({}, old, {
				[kitemId]: newSetting,
			}));
			dbRef.current?.add("settings", newSetting);
		} else {
			setting.isPaused = paused;
			setSettings(old => Object.assign({}, old, {
				[kitemId]: setting,
			}));
			dbRef.current?.put("settings", setting);
		}
	}

	const isPaused = (kitemId: string) => {
		const setting = settings[kitemId];
		return setting?.isPaused;
	}

	const contextValue: KitemSettings = {
		setPaused: setPaused,
		isPaused: isPaused,
	}

	return (
		<KitemSettingsContext.Provider value={contextValue}>
			{props.children}
		</KitemSettingsContext.Provider>
	);
}
