import { KitemType } from "../contexts/LearningContext";
import { ImageChooserKitem } from "../controller/ImageChooserController";


export const ErsteWorteKitems: (ImageChooserKitem)[] = [
	{
		id: "ersteworte_mama",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "mama",
		variant: "first-words",
	},
	{
		id: "ersteworte_papa",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "papa",
		variant: "first-words",
	},
	{
		id: "ersteworte_janosch",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "personen",
		targetId: "janosch",
		variant: "first-words",
	},
	{
		id: "ersteworte_hund",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "hund",
		variant: "first-words",
	},
	{
		id: "ersteworte_katze",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "katze",
		variant: "first-words",
	},
	{
		id: "ersteworte_schaf",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "schaf",
		variant: "first-words",
	},
	{
		id: "ersteworte_pferd",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "pferd",
		variant: "first-words",
	},
	{
		id: "ersteworte_maus",
		type: KitemType.ImageChooser,
		topic: "ersteworte",
		setId: "tiere",
		targetId: "maus",
		variant: "first-words",
	},
];