import { KitemType } from "../contexts/LearningContext";
import { SMCKitem } from "../controller/SMCController";



export const SozialesKitems: SMCKitem[] = [
	{
		id: "soziales_hallo_vorname",
		type: KitemType.SMC,
		topic: "soziales",
		question: {
			audioUrl: require('../assets/soziales/hallo_vorname.question.m4a'),
			transcript: "Angenommen, Du willst Romina begrüßen. Sagst Du einfach nur \"hallo\", oder \"hallo Romina\"?",
		},
		answers: [
			{
				audioUrl: require('../assets/soziales/hallo_vorname.answer-0.webm'),
				transcript: "Hallo Romina",
				correct: true,
			},
			{
				audioUrl: require('../assets/soziales/hallo_vorname.answer-1.webm'),
				transcript: "Hallo",
				correct: false,
			},
		],
	},
	{
		id: "soziales_tschuess_vorname",
		type: KitemType.SMC,
		topic: "soziales",
		question: {
		  audioUrl: require('../assets/soziales/tschuess_vorname.question.m4a'),
		  transcript: "Franzis ist dabei zu gehen. Was sagst Du? Einfach nur \"tschüss\" oder \"tschüss Franzis\"?",
		},
		answers: [
		  {
			audioUrl: require('../assets/soziales/tschuess_vorname.answer-0.webm'),
			transcript: "Tschüss Franzis",
			correct: true,
		  },
		  {
			audioUrl: require('../assets/soziales/tschuess_vorname.answer-1.webm'),
			transcript: "Tschüss.",
			correct: false,
		  }
		],
	},
	{
		id: "soziales_hallo_vorname2",
		type: KitemType.SMC,
		topic: "soziales",
		question: {
			audioUrl: require('../assets/soziales/hallo_vorname2.question.m4a'),
			transcript: "Du kommst gerade im Kindergarten an. Emma kommt Dir entgegen. Was sagst Du?",
		},
		answers: [
			{
				audioUrl: require('../assets/soziales/hallo-emma.m4a'),
				transcript: "Hallo Emma",
				correct: true,
			},
			{
				audioUrl: require('../assets/soziales/hallo_vorname.answer-1.webm'),
				transcript: "Hallo",
				correct: false,
			},
		],
	},
	{
		id: "soziales_spieltunnel",
		type: KitemType.SMC,
		topic: "soziales",
		question: {
		  audioUrl: require('../assets/soziales/spieltunnel.question.m4a'),
		  transcript: "Du bist gerade in ein Spiel vertieft. Ein anderes Kind kommt dazu. Kannst Du es einfach ignorieren und Dich weiter auf Dein Spiel konzentrieren?",
		},
		answers: [
		  {
			audioUrl: require('../assets/soziales/spieltunnel.answer-0.m4a'),
			transcript: "Nein, Du sagst zumindest kurz hallo.",
			correct: true,
		  },
		  {
			audioUrl: require('../assets/soziales/spieltunnel.answer-1.m4a'),
			transcript: "Ja",
			correct: false,
		  }
		],
	},
	{
		id: "soziales_mitspielangebot",
		type: KitemType.SMC,
		topic: "soziales",
		question: {
		  audioUrl: require('../assets/soziales/mitspielangebot.question.m4a'),
		  transcript: "Du spielst gerade etwas und ein Freund oder eine Freundin kommt dazu. Was ist noch besser als nur hallo zu sagen?",
		},
		answers: [
		  {
			audioUrl: require('../assets/soziales/mitspielangebot.answer-0.m4a'),
			transcript: "Du kannst fragen, ob das Kind mitspielen möchte.",
			correct: true,
		  },
		  {
			audioUrl: require('../assets/soziales/mitspielangebot.answer-1.m4a'),
			transcript: "Du kannst die Spielfiguren vor dem Kind in Sicherheit bringen.",
			correct: false,
		  },
		],
	},
	{
		id: "soziales_helfen",
		type: KitemType.SMC,
		topic: "soziales",
		question: {
		  audioUrl: require('../assets/soziales/helfen.question.m4a'),
		  transcript: "Einem Kind fällt der Karton von einem Brettspiel herunter. Nun sind alle Figuren und Karten auf dem Boden verteilt. Was machst Du?",
		},
		answers: [
		  {
			audioUrl: require('../assets/soziales/helfen.answer-0.m4a'),
			transcript: "Dem Kind helfen, die Teile wieder einzusammeln.",
			correct: true,
		  },
		  {
			audioUrl: require('../assets/soziales/helfen.answer-1.m4a'),
			transcript: "Es nicht weiter beachten.",
			correct: false,
		  },
		  {
			audioUrl: require('../assets/soziales/helfen.answer-2.m4a'),
			transcript: "Laut lachen.",
			correct: false,
		  },
		],
	},

];